import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss']
})
export class UnsavedChangesComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public sysText: any) {}

  ngOnInit() {}
}
