<div class="dialog--title">
    <h6 class="grey1">{{ data.sysText.editEmailContent_header }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
        <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
    </ng-container>
</div>
  
<mat-dialog-content class="mat-dialog-content">
<ng-container>
    <form [formGroup]="form">
        <div>
            <div class="editor-container">
                <mat-icon class="section-label">title</mat-icon>
                <p class="grey1">{{data.sysText.emailSubjectLine}}</p>
            </div>
            <div class="tiny-editor">
                <app-email-subject-editor
                    [control]="form.controls.subject"
                    [placeholderText]="data.sysText.emailSubjectLine"
                    [placeHolders]="data.placeholderOptions"
                ></app-email-subject-editor>
            </div>
        </div>
        <span class="spacer"></span>
        <div>
            <div class="editor-container">
                <mat-icon class="section-label">subject</mat-icon>
                <p class="grey1">{{data.sysText.emailBody}}</p>
            </div>
            <div class="tiny-editor">
                <app-email-body-editor
                    [control]="form.controls.body"
                    [placeholderText]="data.sysText.emailBody"
                    [placeHolders]="data.placeholderOptions"
                ></app-email-body-editor>
            </div>
        </div>
    </form>
</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async" *ngIf="!isHandheld">
    {{ data.sysText.cancel }}
  </button>
    <button mat-stroked-button class="button" color="accent" (click)="save()">
        {{ data.sysText.save }}
    </button>
</mat-dialog-actions>
<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </button>
    </mat-dialog-actions>
</ng-template>
  
