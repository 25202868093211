<div class="drawer-container">
	<div class="button-container">
		<button
			mat-icon-button
			aria-label="remove button"
			[disableRipple]="true"
			(click)="onCloseCaseCom()"
		>
			<mat-icon class="accent" id="close-icon">close</mat-icon>
		</button>
	</div>
	<div class="top-section">
		<div class="header-container">
			<p class="overline grey1 semibold section-title">{{sysText.caseCommunicationDrawer_title | uppercase}}</p>
			<mat-divider class="section-header-underline"></mat-divider>
		</div>
		<button
			mat-stroked-button
			class="button button-height"
			color="accent"
			(click)="onSendEmail()"
			[disabled]="isLoadingSendEmailDialog$ | async"
		>
			<mat-icon *ngIf="!(isLoadingSendEmailDialog$ | async)">email</mat-icon>
			<mat-icon *ngIf="isLoadingSendEmailDialog$ | async"><mat-spinner class="download-spinner" [diameter]="18" color="accent"></mat-spinner></mat-icon>
			<span> {{ sysText.caseCommunicationDrawer_sendNewEmail }} </span>
		</button>
	</div>
	<ng-container *ngIf="(caseComData.caseEmailSummaries?.length > 0); else noHistory">
		<div *ngFor="let email of caseComData.caseEmailSummaries">
			<ng-container *ngIf="!((loadingViewEmailDialogId$ | async) == email.caseEmailId); else loading">
				<div class="email-container" (click)="onEmailClick(email.caseEmailId)">
					<div class="text-section">
						<p class="body2 grey1 ellipsis">
							<span 
								*ngFor="let rec of email.recipients; index as i"
								[matTooltip]="rec.name ? rec.email : null" 
								[matTooltipShowDelay]="500" 
								matTooltipPosition="above" 
								class="contact-chip"
							>
								{{rec.name ?? rec.email}}
							</span>
						</p>
						<p class="body2 grey6 semibold ellipsis emailTextPadding">{{email.subject}}</p>
						<p class="body2 grey3 ellipsis emailTextPadding">{{email.bodyText}}</p>
					</div>
					<div class="sender-section">
						<span *ngIf="email.sender">
							<img 
								*ngIf="email.sender?.image" 
								class="user-img"  
								[src]="email.sender?.image" 
								[matTooltip]="sysText.caseCommunication_sentBy + ' ' + email.sender?.description" 
								[matTooltipShowDelay]="500" 
								matTooltipPosition="left" 
								alt="user"
							>
							<div 
								*ngIf="!email.sender?.image" 
								class="circle" 
								[matTooltip]="sysText.caseCommunication_sentBy + ' ' + email.sender?.description" 
								[matTooltipShowDelay]="500" 
								matTooltipPosition="left"
							>
								{{ getInitials(email.sender?.description) }}
							</div>
						</span>
						<p class="body2">{{email.sendDateTime | date: 'MMM d, y, h:mm a'}}</p>
					</div>
				</div>
			</ng-container>
			<ng-template #loading>
				<div class="spinner-container">
					<mat-spinner [diameter]="40" color="accent"></mat-spinner>
				</div>
			</ng-template>
			<mat-divider class="section-underline"></mat-divider>
		</div>
	</ng-container>
	<ng-template #noHistory>
		<p class=" body2 grey1 no-history">{{sysText.caseCommunication_noHistory | capitalizefirst}}</p>
	</ng-template>
</div>
