import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-page-alert',
  templateUrl: './login-page-alert.component.html',
  styleUrls: ['./login-page-alert.component.scss']
})
export class LoginPageAlertComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

}
