import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeaveHours, StiiraError } from '@core/models';
import { EmployeeLeaveHours } from '@core/models/leave-admin/employees/employee-record.model';
import { LeaveHoursForm, LeaveHoursFormPost } from '@core/models/leave-admin/employees/leave-hours-form.model';
import { ErrorService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { CalendarUpdateConfirmationComponent } from '../calendar-update-confirmation/calendar-update-confirmation.component';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { EmployeeCalendarRequiredValues, showEmployeeCalendarWarning } from '@shared/helpers/calendar-warn.helpers';
import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';

@Component({
  selector: 'app-edit-leave-hours',
  templateUrl: './edit-leave-hours.component.html',
  styleUrls: ['./edit-leave-hours.component.scss']
})
export class EditLeaveHoursComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<LeaveHoursForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  public isAnonymous: boolean = false;

  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      eeId: number,
      caseId: number,
      caseLeaveHours: LeaveHours,
      employeeLeaveHours: EmployeeLeaveHours; 
      sysText: any; 
      unsavedChangesSysText: any,
      calendarUpdateConfSysText: any
      showFmlaHours: boolean,
      showStateHours: boolean,
      showPloHours: boolean,
    },
    private leaveHoursDialogRef: MatDialogRef<EditLeaveHoursComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private manageEmployeesService: ManageEmployeesService,
    private leaveAdminService: LeaveAdminService,
    private errorService: ErrorService,
    private employeeStoreService: EmployeeRecordStoreService,
    private leaveAdminStoreService: LeaveAdminStoreService,
  ) { 
    this.isSaving$ = new Subject();
    this.form = this.fb.group<LeaveHoursForm>({
      fmlaHours: this.fb.control(null),
      stateHours: this.fb.control(null),
      ploHours: this.fb.control(null),
    })
  }

  ngOnInit(): void {
    if (this.data.employeeLeaveHours) {
      this.form.patchValue({
        fmlaHours: this.data.employeeLeaveHours.fmlaTotalHours,
        stateHours: this.data.employeeLeaveHours.stateTotalHours,
        ploHours: this.data.employeeLeaveHours.ploTotalHours,
      });
    } else if (this.data.caseLeaveHours) {
      this.isAnonymous = this.leaveAdminStoreService.caseDetails.employeeInformation.isAnonymous;

      this.form.patchValue({
        fmlaHours: this.data.caseLeaveHours.employeeFmlaTotalHours,
        stateHours: this.data.caseLeaveHours.employeeStateTotalHours,
        ploHours: this.data.caseLeaveHours.employeePloTotalHours,
      });
    }
    
    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
    }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save(): void {
    const eeReqVals: EmployeeCalendarRequiredValues = {
      newLeaveYearFixedStart: !this.data.caseId ? this.employeeStoreService.employeeRecord.fixedLeaveYearStart 
        : this.leaveAdminStoreService.caseDetails.leaveInformation.employeeFixedStart,
      prevLeaveYearFixedStart: !this.data.caseId ? this.employeeStoreService.employeeRecord.fixedLeaveYearStart 
        : this.leaveAdminStoreService.caseDetails.leaveInformation.employeeFixedStart,

      newHireDate: !this.data.caseId ? this.employeeStoreService.employeeRecord.hireDate 
        : this.leaveAdminStoreService.caseDetails.employeeInformation.employeeHireDate,
      prevHireDate: !this.data.caseId ? this.employeeStoreService.employeeRecord.hireDate 
        : this.leaveAdminStoreService.caseDetails.employeeInformation.employeeHireDate,
      newHoursPerWeek: !this.data.caseId ? this.employeeStoreService.employeeRecord.hoursPerWeek 
        : this.leaveAdminStoreService.caseDetails.employeeInformation.employeeHoursPerWeek,
      prevHoursPerWeek: !this.data.caseId ? this.employeeStoreService.employeeRecord.hoursPerWeek 
        : this.leaveAdminStoreService.caseDetails.employeeInformation.employeeHoursPerWeek,
      newWorkdays: JSON.stringify(!this.data.caseId ? this.employeeStoreService.employeeRecord.workdays 
        : this.leaveAdminStoreService.caseDetails.leaveCalendar.workdays),
      prevWorkdays: JSON.stringify(!this.data.caseId ? this.employeeStoreService.employeeRecord.workdays 
        : this.leaveAdminStoreService.caseDetails.leaveCalendar.workdays),

      newFmlaTotalHours: this.form.controls.fmlaHours.value,
      prevFmlaTotalHours: this.formInitValues.fmlaHours,

      newStateTotalHours: this.form.controls.stateHours.value,
      prevStateTotalHours: this.formInitValues.stateHours,

      newPloTotalHours: this.form.controls.ploHours.value,
      prevPloTotalHours: this.formInitValues.ploHours,
    };

    const showCalendarWarningMessage: CalendarWarnMessages[] = showEmployeeCalendarWarning(eeReqVals, this.leaveAdminStoreService.caseDetails?.leaveCalendar?.employeeLeaveHoursExtended);

    if (showCalendarWarningMessage) {
      const dialogConfig: MatDialogConfig = {
        disableClose: false,
        closeOnNavigation: true,
        data: {
          sysText: this.data.calendarUpdateConfSysText, 
          warnMessages: showCalendarWarningMessage
        }
      };

      this.dialog.open(CalendarUpdateConfirmationComponent, dialogConfig)
        .beforeClosed().subscribe((res: boolean) => {
          if (res) {
            this.postSaveInfo();
          }
        });
    }
    else {
      this.postSaveInfo();
    }
  }
  
  public cancel(): void {
    if (this.noChanges) {
      this.leaveHoursDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }
  
  private postSaveInfo(): void {
    this.leaveHoursDialogRef.disableClose = true;

    const dto: LeaveHoursFormPost = {
      employeeId: this.data.eeId,
      caseId: this.data.caseId,
      fmlaHours: this.form.controls.fmlaHours.value,
      stateHours: this.form.controls.stateHours.value,
      ploHours: this.form.controls.ploHours.value,
    };

    if (this.data.employeeLeaveHours) {
      this.manageEmployeesService.
        postLeaveHours(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.leaveHoursDialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.leaveHoursDialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
    else if (this.data.caseLeaveHours) {
      this.leaveAdminService.
        postLeaveHours(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.leaveHoursDialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.leaveHoursDialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.leaveHoursDialogRef.close();
        }
      });
  }
}