<div class="dialog--title">
    <h6>
      {{ data.sysText.employeeDetails | titlecase }}
    </h6>
  </div>
  <mat-dialog-content>
    <p class="body1 semibold">{{data.ee.name}}</p>
    <p class="caption">{{data.ee.email}}</p>
    <p *ngIf="data.showEmployer" class="caption">{{data.ee.company}}</p>
    <p class="overline grey1 semibold section-title">{{ data.sysText.roles }}</p>
    <mat-divider class="section-header-underline"></mat-divider>
    <div *ngFor="let role of data.ee.roles">
    <p class="body2 semibold">{{role.name}}</p>
    <p class="caption description">{{role.description}}</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      class="button"
      mat-dialog-close
      color="accent"
    >
      {{ data.sysText.close }}
    </button>
  </mat-dialog-actions>
  