<div class="dialog--title">
  <h6 >
    {{(!data.showSubmitTime ? data.sysText.submitIntermittentTime_title : data.sysText.submitTime_title) | titlecase}}
  </h6>
  <ng-container *ngIf="isHandheld"> 
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="data.submitIntermittentTimeDialog.showLeavePickList"class="field__container">
      <mat-icon>person</mat-icon>
      <mat-form-field color="accent">
        <mat-icon *ngIf="!(isLoadingEmployee$ | async)" matSuffix class="suffix-icon">search</mat-icon>
        <mat-spinner *ngIf="(isLoadingEmployee$ | async)" matSuffix class="suffix-icon" [diameter]="21" color="accent"></mat-spinner>
        <input 
          matInput aria-label="Select an option"
          [matAutocomplete]="auto"
          [formControl]="employee"
          input="matInput"
          placeholder="Employee"
          formControlName="employee"/>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              <div class="flex">
                <img [src]="option.employee.image || 'assets/images/missing_avatar.svg'" alt="user-image" class="profile-image"/>
                {{ option.employee.description }} 
              </div>
              <div class="leave-option-info">
                <span *ngIf="option.employee.companyName" class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{option.employee.companyName}}
                </span>
                <span *ngIf="option.employee.workEmail" class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{option.employee.workEmail}}
                </span>
                <span *ngIf="option.employee.personalEmail" class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{option.employee.personalEmail}}
                </span>
                <span class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{(data.sysText.case | capitalizefirst) + '# ' + option.caseId}}
                </span>
                <span class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{(data.sysText.availableFmlaStateHours) + ': ' + (option.remainingFmlaLeave ?? '--') + ' / ' + (option.remainingStateLeave ?? '--')}}
                </span>
                <span class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{(data.sysText.startDate | capitalizefirst) + ': ' + (option.startDate | date)}}
                </span>
                <span class="caption option-extras">
                  <span class="bullet-spacing">&bull;</span>
                  {{(data.sysText.endDate | capitalizefirst) + ': ' + (option.endDate | date) }}
                </span>
              </div>
            </mat-option>
        </mat-autocomplete>
        <!-- mat hint for spacing -->
        <mat-hint></mat-hint>
        <mat-error *ngIf="employee.errors?.msError">{{ employee.errors?.msError }}</mat-error>
        <mat-error *ngIf="employee.errors?.required">{{ data.sysText.required | capitalizefirst }}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <div class="row__container" formArrayName="timeSubmittals" *ngFor="let timeSubmittal of timeSubmittals.controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="field__container wrap">
            <div class="container date-field">
              <mat-icon [ngClass]="{ focus: input.focused }">calendar_today</mat-icon>
              <mat-form-field color="accent">
                <mat-label>{{ (!data.showSubmitTime ? data.sysText.dateFieldLabelA : data.sysText.dateFieldLabelB) | titlecase }}</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="timeSubmittal.controls.submittedDate" #input="matInput" (paste)="onPasteIntoField($event, i)"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [touchUi]="isHandheld"></mat-datepicker>
                <mat-hint class="hint-blue">
                  <p *ngIf="timeSubmittal.controls.timeSubmittalWarn.value" class="mat-hint-combined">
                    {{ timeSubmittal.controls.timeSubmittalWarn.value + ' ' + 
                    data.sysText.timeSubmittalWarn1 + ' ' + 
                    (timeSubmittal.controls.submittedDate.value | date) + '. ' + 
                    data.sysText.timeSubmittalWarn2 }}
                  </p>
                  <p *ngIf="timeSubmittal.controls.sameDateWarn.value" class="mat-hint-combined">
                    {{ data.sysText.sameDateWarn1 + ' ' + 
                    (timeSubmittal.controls.sameDateWarn.value | date) + ' ' + 
                    data.sysText.sameDateWarn2 }}
                  </p>
                  <p *ngIf="timeSubmittal.controls.otherLeavesWarn.value" class="mat-hint-combined">
                    {{ data.sysText.otherLeavesWarn }}
                  </p>
                </mat-hint>
                <mat-error *ngIf="timeSubmittal.controls.submittedDate.errors?.msError">{{ timeSubmittal.controls.submittedDate.errors?.msError }}</mat-error>
                <mat-error *ngIf="timeSubmittal.controls.submittedDate.errors?.matDatepickerParse">{{ data.sysText.invalidDateError }}</mat-error>
                <mat-error *ngIf="timeSubmittal.controls.submittedDate.errors?.dateRangeInvalid">{{ data.sysText.dateRangeInvalid1 + ' ' + (employee.value.startDate | date)  + ' ' + data.sysText.dateRangeInvalid2 + ' ' + (employee.value.endDate | date) }}.</mat-error>
              </mat-form-field>
            </div>
            <div class="container hours-taken">
              <mat-form-field color="accent">
                <mat-label>{{ data.sysText.hoursFieldLabel | titlecase }}</mat-label>
                <input matInput #input="matInput" type="number" [formControl]="timeSubmittal.controls.totalTimeSubmitted" (paste)="onPasteIntoField($event, i)"/>
                <!-- mat hint for spacing -->
                <mat-hint></mat-hint>
                <mat-error *ngIf="timeSubmittal.controls.totalTimeSubmitted.errors?.msError">{{ timeSubmittal.controls.totalTimeSubmitted.errors?.msError }}</mat-error>
                <mat-error *ngIf="timeSubmittal.controls.totalTimeSubmitted.errors?.pattern">{{ data.sysText.invalidHoursError | capitalizefirst }}</mat-error>
                <mat-error *ngIf="timeSubmittal.controls.totalTimeSubmitted.errors?.hoursGreaterThan24">{{ data.sysText.hoursGreaterThan24Error | capitalizefirst }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <button
              mat-icon-button
              aria-label="remove button"
              [disableRipple]="true"
              (click)="onRemoveTimeSubmittal(i)"
              *ngIf="timeSubmittals.controls.length > 1">
              <mat-icon class="no-margin" [ngClass]="{'accent': true}">close</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
  <div class="bottom-section">
    <mat-error *ngIf="timeSubmittals.errors?.msError" class="mse-form-error caption bottom-hint">{{ timeSubmittals.errors?.msError }}</mat-error>
    <div class="button-container">
      <button
        (click)="onAddTimeSubmittal()"
        mat-flat-button
        color="accent"
        class="button">
        {{data.sysText.addAdditionalTime | uppercase}}
      </button>
      <button
        (click)="onAddFromClipboard()"
        mat-flat-button
        color="accent"
        class="button"
        [disabled]="disableClipboardButton"
        [matTooltip]="disableClipboardButton ? data.sysText.addFromClipboardMessage : null" 
        [matTooltipShowDelay]="500" 
        matTooltipPosition="above">
        {{data.sysText.addFromClipboard | uppercase}}
      </button>
    </div>
  </div>
  <mat-hint *ngIf="hoursTakenWarning" class="mat-form-field hint-red bottom-hint">
    {{ (data.sysText.hoursTakenWarning1 | capitalizefirst) + ' ' + employee.value.remainingFmlaLeave + ' ' + data.sysText.hoursTakenWarning2 + ' ' + hoursTakenWarning + ', ' + data.sysText.hoursTakenWarning3 + '.' }}
  </mat-hint>
</mat-dialog-content>

<mat-dialog-actions class="actions" *ngIf="!(isSubmitting$ | async); else submitting">
    <div>
      <button mat-stroked-button class="button" color="accent" (click)="close(false)">
        {{data.sysText.cancel}}
      </button>
      <button mat-stroked-button class="button" color="accent" [disabled]="isSubmitting$ | async" (click)="onSubmit()">
        {{data.sysText.submit}}
      </button>
    </div>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
</ng-template>