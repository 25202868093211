import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseDetails, EmployeeInfoForm, EmployeeSelectionDetails, MissingDetails, NewEmployeeGroupForm, NewEmployeeWithoutContactInfoForm, SelectionOption, StiiraError } from '@core/models';
import { AnonymousEmployee } from '@core/models/leave-admin/anonymous-request/anonymous-employee.model';
import { EmployeeInfoPost, NewEmployee, NewEmployeeWithoutContactInfo } from '@core/models/leave-admin/employee-info-post.model';
import { EmployeePost } from '@core/models/leave-admin/employee-post.model';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { Workdays } from '@core/models/leave-admin/leave-calendar/work-days.model';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { indicate, nameof, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CalendarUpdateConfirmationComponent } from '../calendar-update-confirmation/calendar-update-confirmation.component';
import { DuplicateDetectedDialogComponent } from '../duplicate-detected-dialog/duplicate-detected-dialog.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { EmployeeCalendarRequiredValues, showEmployeeCalendarWarning } from '@shared/helpers/calendar-warn.helpers';
import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';
import { StateAbbreviation } from '@core/models/leave-admin/employers/state-abbreviation.model';

@Component({
  selector: 'app-leave-employee-info',
  templateUrl: './leave-employee-info.component.html',
  styleUrls: ['./leave-employee-info.component.scss'],
})
export class LeaveEmployeeInfoComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public showNotifyToggle$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup<EmployeeInfoForm>;
  public isNewSupervisor: boolean[] = [];
  public isNewHrManager: boolean = false;
  public isNewEmployee: boolean = false;
  public anonEmployee: AnonymousEmployee;
  public emitInputChange: string;
  public workdaySelectionOptions: string[] = [];
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  
  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private updateEERecord: EmployeeSelectionDetails;
  private destroy$: Subject<void> = new Subject<void>();
  
  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get newEmployeeSupervisor(): FormGroup {
    return this.form.controls.newEmployeeSupervisor as FormGroup;
  }

  get supervisorsArray(): FormArray {
    return this.form.controls.supervisors as FormArray;
  } 

  get newEmployeeHrManager(): FormGroup {
    return this.form.controls.newEmployeeHrManager as FormGroup;
  }

  get newEmployee(): FormGroup {
    return this.form.controls.newEmployee as FormGroup;
  }

  get employeeField(): FormControl {
    return this.form.controls.employee as FormControl;
  }

  get employeeWorkEmailField(): FormControl {
    return this.form.controls.employeeWorkEmail as FormControl;
  }

  get employeePersonalEmailField(): FormControl {
    return this.form.controls.employeePersonalEmail as FormControl;
  }

  get newEmployeeHrMgrPersonalEmailField(): FormControl {
    return this.newEmployeeHrManager.controls.personalEmail as FormControl;
  }

  get newEmployeeHrMgrWorkEmailField(): FormControl {
    return this.newEmployeeHrManager.controls.workEmail as FormControl;
  }

  get employeeNameField(): FormControl {
    return this.form.controls.employeeName as FormControl;
  }

  get companyField(): FormControl {
    return this.form.controls.company as FormControl;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get workdays(): FormControl {
    return this.form.controls.employeeWorkdays as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      caseDetails: CaseDetails; 
      sysText: any; 
      employeeOptions: SelectionOption[];
      supervisorOptions: SelectionOption[];
      hrManagerOptions: SelectionOption[];
      stateAbbreviations: StateAbbreviation[];
      stateOptions: SelectionOption[];
      jobTitles: string[];
      fieldNotifications: { [key: string]: string };
      canChangeEmployee: boolean;
      duplicateDetectedSysText: any;
    },
    private fb: FormBuilder,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private eeInfoDialogRef: MatDialogRef<LeaveEmployeeInfoComponent>,
    private service: LeaveAdminService,
    private store: LeaveAdminStoreService,
    private layoutService: LayoutService
  ) { 
    this.anonEmployee = data.caseDetails.employeeInformation.anonymousEmployee;
  }

  ngOnInit(): void {
    this.setupForm();

    if (!this.data.canChangeEmployee) {
      this.employeeField.disable();
    }
    else {
      this.form.controls.employee.setValidators(Validators.required);
    }
    
    this.companyField.disable();
    this.employeeNameField.disable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.employeeField.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (!this.isNewEmployee) {
          this.patchFormFields(val);
        }

        this.handleNotifyToggle();
      });
  }

  public setupForm() {
    this.form = this.fb.group<EmployeeInfoForm>({
      employee: this.fb.control(null),
      employeeName: this.fb.control(null),
      employeeWorkEmail: this.fb.control(null),
      employeeWorkEmailChecked: this.fb.control(null),
      employeePersonalEmail: this.fb.control(null),
      employeePersonalEmailChecked: this.fb.control(null),
      company: this.fb.control(null), 
      employeeWorkPhone: this.fb.control(null),
      employeePersonalPhone: this.fb.control(null),
      employeeJobTitle: this.fb.control(null),
      supervisors: this.fb.array([this.initNewEEGroup()]),
      employeeHrManagerId: this.fb.control(null),
      employeeHireDate: this.fb.control(null), 
      employeeHoursPerWeek: this.fb.control(null),
      employeeWorkdays: this.fb.control(null),
      employeeCity: this.fb.control(null), 
      employeeStateId: this.fb.control(null),
      newEmployee: this.initNewEEWithoutContactGroup(),
      newEmployeeSupervisor: this.initNewEEGroup(),
      newEmployeeHrManager: this.initNewEEGroup(),
      fixedLeaveYearStart: this.fb.control(null),
      firstLeaveUsageDate: this.fb.control(null),
      notifyEmployee: this.fb.control(false)
    });

    this.form.patchValue({
      employee: +this.data.caseDetails.employeeInformation?.employee?.id,
      employeeName: this.data.caseDetails.employeeInformation?.employee?.description,
      employeeWorkEmail: this.data.caseDetails.employeeInformation?.employee?.workEmail,
      employeeWorkEmailChecked: this.data.caseDetails.employeeInformation?.employee?.primaryEmailIsPersonal == false,
      employeePersonalEmail: this.data.caseDetails.employeeInformation?.employee?.personalEmail,
      employeePersonalEmailChecked: this.data.caseDetails.employeeInformation?.employee?.primaryEmailIsPersonal == true,
      company: this.data.caseDetails.company.description,
      employeeWorkPhone: this.data.caseDetails.employeeInformation?.employee?.workPhone,
      employeePersonalPhone: this.data.caseDetails.employeeInformation?.employee?.personalPhone,
      employeeJobTitle: this.data.caseDetails.employeeInformation?.employeeJobTitle,
      employeeHrManagerId: +this.data.caseDetails.employeeInformation?.employeeHrManager?.id,
      employeeHireDate: this.data.caseDetails.employeeInformation?.employeeHireDate,
      employeeHoursPerWeek: this.data.caseDetails.employeeInformation?.employeeHoursPerWeek,
      employeeCity: this.data.caseDetails.employeeInformation?.employeeCity,
      employeeStateId: this.data.caseDetails.employeeInformation?.employeeStateId,
      fixedLeaveYearStart: this.data.caseDetails.employeeInformation?.fixedLeaveYearStart,
      firstLeaveUsageDate: this.data.caseDetails.employeeInformation?.firstLeaveUsageDate,
    });

    // handle patching supervisors
    for (let i = 1; i < this.data.caseDetails.employeeInformation?.employeeSupervisors.length; i++) {
      this.addSupervisorFormGroup();
    }

    if (this.data.caseDetails.employeeInformation?.employeeSupervisors.length > 0) {
      this.supervisorsArray.controls.forEach((obj, index) => {
        if (this.data.caseDetails.employeeInformation?.employeeSupervisors[index] != null) {
          obj.patchValue({employeeId: this.data.caseDetails.employeeInformation?.employeeSupervisors[index].id});
        }
      });
    }

    this.buildWorkdayOptions(this.data.caseDetails.leaveCalendar.workdays);
    this.missingDetailsErrorsSetTimeout();
  }

  public initNewEEGroup(): FormGroup<NewEmployeeGroupForm>{
    return this.fb.group<NewEmployeeGroupForm>({
      employeeId: this.fb.control(null),
      firstName: this.fb.control(null),
      middleName: this.fb.control(null),
      lastName: this.fb.control(null),
      suffix: this.fb.control(null),
      preferredName: this.fb.control(null),
      workPhone: this.fb.control(null),
      personalPhone: this.fb.control(null),
      workEmail: this.fb.control(null),
      workEmailChecked: this.fb.control(null),
      personalEmail: this.fb.control(null),
      personalEmailChecked: this.fb.control(null),
      primaryEmailIsPersonal: this.fb.control(null)
    })
  }

  public initNewEEWithoutContactGroup(): FormGroup<NewEmployeeWithoutContactInfoForm>{
    return this.fb.group<NewEmployeeWithoutContactInfoForm>({
      firstName: this.fb.control(''),
      middleName: this.fb.control(''),
      lastName: this.fb.control(''),
      suffix: this.fb.control(''),
      preferredName: this.fb.control('')
    })
  }

  public addSupervisorFormGroup(): void {
    const createContactGroup = this.initNewEEGroup();
    this.supervisorsArray.push(createContactGroup);
    this.isNewSupervisor.push(false);
  }

  public onEEHintClick(): void {
    this.isNewEmployee = !this.isNewEmployee;

    if (!this.data.caseDetails.employeeInformation.isAnonymous) {
      this.form.controls.newEmployee.patchValue({ 
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        preferredName: ''
      });
    }

    if (this.isNewEmployee) {
      if (this.data.caseDetails.employeeInformation.isAnonymous) {
        this.form.controls.newEmployee.patchValue({ 
          firstName: this.anonEmployee.firstName,
          middleName: this.anonEmployee.middleName,
          lastName: this.anonEmployee.lastName,
          suffix: this.anonEmployee.suffix,
          preferredName: ''
        });

        this.form.patchValue({
          employeeWorkEmail:  this.anonEmployee.workEmail,
          employeeWorkEmailChecked: (this.anonEmployee.primaryEmailIsPersonal == false),
          employeePersonalEmail:  this.anonEmployee.personalEmail,
          employeePersonalEmailChecked: (this.anonEmployee.primaryEmailIsPersonal == true),
          employeeWorkPhone: this.anonEmployee.workPhone,
          employeePersonalPhone: this.anonEmployee.personalPhone,
        });
      }
      else {
        this.form.controls.employee.setValidators(null);
        this.newEmployee.controls.firstName.setValidators(Validators.required);
        this.newEmployee.controls.lastName.setValidators(Validators.required);
        this.form.controls.employee.setValue(null);
        this.clearFormFields();
      }
    }
    else {
      this.form.controls.employee.setValidators(Validators.required);
      this.newEmployee.controls.firstName.setValidators(null);
      this.newEmployee.controls.lastName.setValidators(null);

      if (!this.data.caseDetails.employeeInformation.isAnonymous) {
        this.form.patchValue({
          employee: +this.data.caseDetails.employeeInformation?.employee?.id,
          employeeWorkEmailChecked: (this.data.caseDetails.employeeInformation?.employee?.primaryEmailIsPersonal == false),
          employeePersonalEmailChecked: (this.data.caseDetails.employeeInformation?.employee?.primaryEmailIsPersonal == true),
        })
      }

      this.clearFormFields();

      this.form.updateValueAndValidity();
      this.form.markAsUntouched();
    }
  }

  public onAddSupervisorContact(index: number): void {
    this.isNewSupervisor[index] = !this.isNewSupervisor[index];
    const formGroup: FormGroup = this.supervisorsArray.controls[index] as FormGroup;

    formGroup.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      prefferedName: null,
      workPhone: null,
      personalPhone: null,
      workEmail: null,
      workEmailChecked: null,
      personalEmail: null,
      personalEmailChecked: null,
    });

    if (this.isNewSupervisor[index]) {
      formGroup.controls.firstName.setValidators(Validators.required)
      formGroup.controls.lastName.setValidators(Validators.required)
    }
    else {
      formGroup.controls.firstName.setValidators(null);
      formGroup.controls.lastName.setValidators(null);
    }

    formGroup.updateValueAndValidity();
    formGroup.markAsUntouched();
  }

  public onRemoveSupervisorContact(index: number) {
    this.supervisorsArray.removeAt(index);
  }

  public onHrManagerHintClick(): void {
    this.isNewHrManager = !this.isNewHrManager

    this.form.controls.newEmployeeHrManager.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
      preferredName: null,
      workPhone: null,
      personalPhone: null,
      workEmail: null,
      workEmailChecked: null,
      personalEmail: null,
      personalEmailChecked: null,
    });

    if (this.isNewHrManager) {
      this.newEmployeeHrManager.controls.firstName.setValidators(Validators.required);
      this.newEmployeeHrManager.controls.lastName.setValidators(Validators.required);
    }
    else {
      this.newEmployeeHrManager.controls.firstName.setValidators(null);
      this.newEmployeeHrManager.controls.lastName.setValidators(null);
    }

    this.newEmployeeHrManager.updateValueAndValidity();
    this.newEmployeeHrManager.markAsUntouched();
  }

  public onSubmit(overrideDuplicates: boolean) {
    const eeReqVals: EmployeeCalendarRequiredValues = {
      newLeaveYearFixedStart: this.form.controls.fixedLeaveYearStart.value, 
      prevLeaveYearFixedStart: this.data.caseDetails.leaveInformation.employeeFixedStart, 

      newHireDate: this.form.controls.employeeHireDate.value,
      prevHireDate: this.data.caseDetails.employeeInformation.employeeHireDate,

      newHoursPerWeek: this.form.controls.employeeHoursPerWeek.value,
      prevHoursPerWeek: this.data.caseDetails.employeeInformation.employeeHoursPerWeek,

      newWorkdays: this.workdays.value?.toString(),
      prevWorkdays: this.formInitValues.employeeWorkdays.toString(),
      
      newFmlaTotalHours: this.data.caseDetails.leaveHours.employeeFmlaTotalHours,
      prevFmlaTotalHours: this.data.caseDetails.leaveHours.employeeFmlaTotalHours,
      
      newStateTotalHours: this.data.caseDetails.leaveHours.employeeStateTotalHours,
      prevStateTotalHours: this.data.caseDetails.leaveHours.employeeStateTotalHours,
      
      newPloTotalHours: this.data.caseDetails.leaveHours.employeePloTotalHours,
      prevPloTotalHours: this.data.caseDetails.leaveHours.employeePloTotalHours,
    };

    const showCalendarWarningMessage: CalendarWarnMessages[] = showEmployeeCalendarWarning(eeReqVals, this.data.caseDetails.leaveCalendar?.employeeLeaveHoursExtended);

    if (!overrideDuplicates && showCalendarWarningMessage) {
      const dialogConfig: MatDialogConfig = {
        disableClose: false,
        closeOnNavigation: true,
        data: {
          sysText: this.store.sysText.leaveCalendarUpdateConfirmation, 
          warnMessages: showCalendarWarningMessage
        }
      };

      this.dialog.open(CalendarUpdateConfirmationComponent, dialogConfig)
        .beforeClosed().subscribe((res: boolean) => {
          if (res) {
            this.postSaveInfo(overrideDuplicates);
          }
        });
    }
    else {
      this.postSaveInfo(overrideDuplicates);
    }
  }

  private missingDetailsErrorsSetTimeout(missingDetails?: MissingDetails) {
    setTimeout(() => {
      if (missingDetails != null) {
        this.errorService.setFormModelStateErrors(this.form, missingDetails);
      }
      else if (this.data.caseDetails?.missingDetails) {
        this.errorService.setFormModelStateErrors(this.form, this.data.caseDetails.missingDetails);
      }

      this.formInitValues = JSON.parse(JSON.stringify(this.form.value, noChangesReplacer));
      
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }
    
  private postSaveInfo(overrideDuplicates: boolean) {
    this.form.markAsUntouched();
    let cleanSupervisors: NewEmployee[] = [];
    this.supervisorsArray.controls.forEach((formGroup: FormGroup, index) => {
      let isEmpty : boolean;
      Object.keys(formGroup.controls).forEach((control: string) => {
        const val = formGroup.controls[control].value;
        if (val){
          isEmpty = false;
        }
      });

      if (isEmpty == false) {
        const newSup: NewEmployee = {
          employeeId: this.isNewSupervisor[index] ? null : formGroup.controls.employeeId.value,
          firstName: this.isNewSupervisor[index] ? formGroup.controls.firstName.value : null,
          middleName: this.isNewSupervisor[index] ? formGroup.controls.middleName.value : null,
          lastName: this.isNewSupervisor[index] ? formGroup.controls.lastName.value : null,
          suffix: this.isNewSupervisor[index] ? formGroup.controls.suffix.value : null,
          preferredName: this.isNewSupervisor[index] ? formGroup.controls.preferredName.value : null,
          workPhone: this.isNewSupervisor[index] ? formGroup.controls.workPhone.value : null,
          personalPhone: this.isNewSupervisor[index] ? formGroup.controls.personalPhone.value : null,
          workEmail: !this.isNewSupervisor[index] || formGroup.controls.workEmail.value?.trim().length == 0 ? null : formGroup.controls.workEmail.value,
          personalEmail: !this.isNewSupervisor[index] || formGroup.controls.personalEmail.value?.trim().length == 0 ? null : formGroup.controls.personalEmail.value,
          primaryEmailIsPersonal: this.isNewSupervisor[index] 
            ? formGroup.controls.personalEmailChecked.value 
              ? true 
              : formGroup.controls.workEmailChecked.value 
                ? false 
                : null
            : null
        };

        cleanSupervisors.push(newSup);
      }
    });

    const newHrMan: NewEmployee = {
      employeeId: this.isNewHrManager ? null : this.newEmployeeHrManager.controls.employeeId.value,
      firstName: this.newEmployeeHrManager.controls.firstName.value,
      middleName: this.newEmployeeHrManager.controls.middleName.value,
      lastName: this.newEmployeeHrManager.controls.lastName.value,
      suffix: this.newEmployeeHrManager.controls.suffix.value,
      preferredName: this.newEmployeeHrManager.controls.preferredName.value,
      workEmail: this.newEmployeeHrManager.controls.workEmail.value,
      personalEmail: this.newEmployeeHrManager.controls.personalEmail.value,
      primaryEmailIsPersonal: this.newEmployeeHrManager.controls.workEmailChecked.value
        ? false
        : this.newEmployeeHrManager.controls.personalEmailChecked.value
          ? true
          : null,
      workPhone: this.newEmployeeHrManager.controls.workPhone.value,
      personalPhone: this.newEmployeeHrManager.controls.personalPhone.value
    }

    const newEe: NewEmployeeWithoutContactInfo = {
      firstName: this.newEmployee.controls.firstName.value,
      middleName: this.newEmployee.controls.middleName.value,
      lastName: this.newEmployee.controls.lastName.value,
      suffix: this.newEmployee.controls.suffix.value,
      preferredName: this.newEmployee.controls.preferredName.value
    }

    const ep: EmployeePost = {
      employeeId: this.isNewEmployee ? null : this.form.controls.employee.value,
      newEmployee: this.isNewEmployee ? newEe : null
    }

    const workdays: Workdays = {
      sunday: this.workdays.value?.includes(nameof<Workdays>('sunday')),
      monday: this.workdays.value?.includes(nameof<Workdays>('monday')),
      tuesday: this.workdays.value?.includes(nameof<Workdays>('tuesday')),
      wednesday: this.workdays.value?.includes(nameof<Workdays>('wednesday')),
      thursday: this.workdays.value?.includes(nameof<Workdays>('thursday')),
      friday: this.workdays.value?.includes(nameof<Workdays>('friday')),
      saturday: this.workdays.value?.includes(nameof<Workdays>('saturday')),
    }

    const dto: EmployeeInfoPost = {
      overrideDuplicates: overrideDuplicates,
      caseId: this.data.caseDetails.leaveInformation?.caseId,
      employee: ep, 
      employeeWorkEmail: this.form.controls.employeeWorkEmail.value,
      employeeWorkPhone: this.form.controls.employeeWorkPhone.value, 
      employeePersonalEmail: this.form.controls.employeePersonalEmail.value,
      employeePersonalPhone: this.form.controls.employeePersonalPhone.value, 
      employeePrimaryEmailIsPersonal: this.form.controls.employeePersonalEmailChecked.value ? true : this.form.controls.employeeWorkEmailChecked.value ? false : null,
      employeeJobTitle: this.form.controls.employeeJobTitle.value,
      supervisors: cleanSupervisors,
      employeeHrManagerId: this.isNewHrManager ? null : this.form.controls.employeeHrManagerId.value, 
      newEmployeeHrManager: this.isNewHrManager ? newHrMan : null, 
      employeeHireDate: this.form.controls.employeeHireDate.value, 
      employeeHoursPerWeek: this.form.controls.employeeHoursPerWeek.value, 
      employeeWorkdays: workdays,
      employeeCity: this.form.controls.employeeCity.value, 
      employeeStateId: this.form.controls.employeeStateId.value,
      fixedLeaveYearStart: this.form.controls.fixedLeaveYearStart.value,
      firstLeaveUsageDate: this.form.controls.firstLeaveUsageDate.value,
      notifyEmployee: this.form.controls.notifyEmployee.value
    }

    this.service.postEmployeeInfo(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        (res) => {
          if (res.showDuplicateDialog) {
            this.openDuplicateDetectedDialog(res as DuplicateDetectedDialog);
          }
          else {
            this.store.caseDetails = res as CaseDetails;
            this.close(true);
          }
        },
        (err: StiiraError) =>
          this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
      );
  }

  public close(canNavigate: boolean): void {
    if (canNavigate){
      this.eeInfoDialogRef.close();
    }
    else {
      if (this.noChanges) {
        this.eeInfoDialogRef.close();
      }
      else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.unsavedChanges,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.eeInfoDialogRef.close();
        }
      });
  }

  private clearFormFields(): void {
    this.form.patchValue({
      employeeName: null,
      employeeWorkEmail: null,
      employeeWorkEmailChecked: null,
      employeePersonalEmail: null,
      employeePersonalEmailChecked: null,
      employeeWorkPhone: null,
      employeePersonalPhone: null,
      employeeHireDate: null,
      employeeHoursPerWeek: null,
      employeeCity: null,
      employeeStateId: null,
      employeeJobTitle: null,
      employeeHrManagerId: null,
      employeeWorkdays: null,
      fixedLeaveYearStart: null,
      firstLeaveUsageDate: null,
    });

    this.supervisorsArray.clear()
    this.supervisorsArray.push(this.initNewEEGroup());
  }

  private patchFormFields(id: number): void {
    const ee = this.data.employeeOptions.filter(eo => eo.id === id);
  
    if (id != null && ee.length === 1) {
      this.form.controls.employeeWorkdays.disable();
      this.service
        .getEmployeeSelectionDetails(id, this.data.caseDetails.leaveInformation.caseId)
        .pipe(
          indicate(this.isLoading$),
          finalize(()=>{
            this.form.patchValue({
              employeeName: this.updateEERecord.fullname,
              employeeWorkEmail:  this.updateEERecord.workEmail,
              employeeWorkEmailChecked: this.updateEERecord.primaryEmailIsPersonal == false
                ? true
                : this.updateEERecord.primaryEmailIsPersonal == true
                  ? false
                  : null,
              employeeWorkPhone: this.updateEERecord.workPhone,
              employeePersonalEmail:  this.updateEERecord.personalEmail,
              employeePersonalEmailChecked: this.updateEERecord.primaryEmailIsPersonal == true
                ? true
                : this.updateEERecord.primaryEmailIsPersonal == false
                  ? false
                  : null,
              employeePersonalPhone: this.updateEERecord.personalPhone,
              employeeHireDate: this.updateEERecord.hireDate,
              employeeHoursPerWeek: this.updateEERecord.hoursPerWeek,
              employeeCity: this.updateEERecord.city,
              employeeStateId: this.updateEERecord.stateId,
              employeeJobTitle: this.updateEERecord.jobTitle,
              employeeHrManagerId: +this.updateEERecord.hrManager?.id,
              fixedLeaveYearStart: this.updateEERecord.fixedLeaveYearStart,
              firstLeaveUsageDate: this.updateEERecord.firstLeaveUsageDate,
            });

            // handle patching supervisors
            for (let i = 1; i < this.updateEERecord.supervisors?.length; i++) {
              this.addSupervisorFormGroup();
            }

            if (this.updateEERecord.supervisors?.length > 0) {
              this.supervisorsArray.controls.forEach((obj, index) => {
                if (this.updateEERecord.supervisors[index] != null) {
                  obj.patchValue({employeeId: this.updateEERecord.supervisors[index].id});
                }
              });
            }

            this.form.controls.employeeWorkdays.enable();
            this.buildWorkdayOptions(this.updateEERecord.workdays);
            this.missingDetailsErrorsSetTimeout(this.updateEERecord.missingDetails);
          }))
        .subscribe((res)=>{
          this.updateEERecord = res;
        });
    }
    else if (!this.isNewEmployee) {
      this.clearFormFields();
    };
  }

  private openDuplicateDetectedDialog(duplicateDetectedDialog: DuplicateDetectedDialog): void {
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '550px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
      maxHeight: this.layoutService.isHandheld ? '100vh' : '',
      height: this.layoutService.isHandheld ? '100vh' : '',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        sysText: this.data.duplicateDetectedSysText,
        duplicateDetectedDialog: duplicateDetectedDialog,
      }
    };

    this.dialog.open(DuplicateDetectedDialogComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.onSubmit(true);
        }
      });
  }

  private buildWorkdayOptions(workdays: Workdays): void {
    let selectedWorkdays: string[] = [];
    const workdaysObj: Workdays = {
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    };
    
    Object.keys(workdaysObj).forEach(d => {
      this.workdaySelectionOptions.push(d)
      if (workdays && workdays[d]) {
        selectedWorkdays.push(d);
      }
    });
    
    this.workdays.patchValue(selectedWorkdays);
  }

  private handleNotifyToggle() {
    this.showNotifyToggle$.next(this.showNotifyToggle());
  }

  public showNotifyToggle() : boolean {
    if (!this.data.canChangeEmployee)
      return false;

    if (this.isNewEmployee
      || (this.form.controls.employee.value && this.formInitValues 
        && this.form.controls.employee.value != this.formInitValues.employee)) {
      return true;
    }
    else {
      return false;
    }
  }
}