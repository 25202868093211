import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedComponentsSysTextService } from '@core/services/shared-components-sys-text.service';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public group: FormGroup;
  @Input() public hint: string;
  @Input() public icon: string;
  @Input() public inputType: string = 'text';
  @Input() public isHintHighlighted: boolean = true;
  @Input() public isHintWarning: boolean = false;
  @Input() public label: string;
  @Input() public labelPosition: string = 'after';  // options are: 'before' or 'after'
  @Input() public checked: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Output() public change = new EventEmitter();
  public sysText: any;

  // #endregion Properties (12)

  // #region Constructors (1)

  constructor(private text: SharedComponentsSysTextService) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public ngOnInit(): void {
    this.sysText = this.text.sysText[this.text.templates.inputField];
  }

}
