<div class="dialog--title">
	<h6 class="grey1">{{ data.sysText.editRecertTitle | titlecase }}</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
	<form [formGroup]="form">
		<div class="main-container main-container-not-last">
			<div class="column-container">
				<app-input-field 
					[group]="form" 
					[control]="form.controls.name" 
					[icon]="'description'"
					[label]="data.sysText.name">
				</app-input-field>
				<app-date-field 
					class="field" 
					[control]="form.controls.warningDate" 
					[label]="data.sysText.warningDate"
					[group]="form" 
					[icon]="'calendar_today'">
				</app-date-field>
			</div>
			<div class="column-container">
				<app-date-field 
					class="field" 
					[control]="form.controls.dueDate" 
					[label]="data.sysText.dueDate" 
					[group]="form"
					[icon]="'calendar_today'">
				</app-date-field>
				<app-date-field 
					class="field" 
					[control]="form.controls.completedDate" 
					[label]="data.sysText.completedDate"
					[group]="form" 
					[icon]="'calendar_today'">
				</app-date-field>
			</div>
		</div>
		
		<div class="main-container main-container-last">
			<div class="row-container">
				<app-text-area
					[group]="form"
					[control]="form.controls.comments"
					[icon]="'comment'"
					[label]="data.sysText.comments">
				</app-text-area>
			</div>
		</div>

		<mat-expansion-panel class="info-panel" (opened)="uploadDocsOpenState = true" (closed)="uploadDocsOpenState = false"
			[expanded]="uploadDocsOpenState">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<div class="section-header-container">
						<p class="overline grey1 semibold section-title">{{data.sysText.recertDocuments | uppercase}}
						</p>
						<mat-divider class="section-header-underline"></mat-divider>
					</div>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="panel-content">
				<div class="upload-button-container">
					<button
						(click)="uploadDocument()"
						mat-flat-button
						color="accent"
						class="button">
						{{data.sysText.uploadDoc}}
					</button>
				</div>

				<div *ngIf="documents?.length > 0" [ngClass]="{ 'make-scroll': !(isMobile$ | async) }" class="table-container">
					<table mat-table [dataSource]="_docs" id="data-table">

						<!-- Document Title Column -->
						<ng-container matColumnDef="documentTitle">
							<th mat-header-cell *matHeaderCellDef>
								<span>{{ data.sysText.documentTitle | titlecase }}</span>
							</th>
							<td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }">
								<ng-container *ngIf="!doc.document; else newDoc">
									<span>{{ doc.documentTitle }} ({{doc.fileExtension}})</span>
								</ng-container>
								<ng-template #newDoc>
									<span>{{ doc.documentTitle | getdocext: doc.document.name}}</span>
								</ng-template>
							</td>
						</ng-container>
				
						<!-- Document Category Column -->
						<ng-container matColumnDef="documentCategory">
							<th mat-header-cell *matHeaderCellDef>
								<span>{{ data.sysText.documentCategory | titlecase }}</span>
							</th>
							<td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }">
								<span>{{ doc.documentCategoryId | findfromid: data.editRecertDialog.documentCategories }}</span>
							</td>
						</ng-container>

						<!-- Comments Column -->
						<ng-container matColumnDef="documentComments">
							<th mat-header-cell *matHeaderCellDef>
								<span>{{ data.sysText.documentComments | titlecase }}</span>
							</th>
							<td
								class="comments"
								mat-cell 
								*matCellDef="let doc"
								[ngClass]="{ caption: (isMobile$ | async) }">
								<span>{{ doc.documentComments }}</span>
							</td>
						</ng-container>
				
						<!--Icons Column -->
						<ng-container matColumnDef="icons">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let doc" [ngClass]="{ caption: (isMobile$ | async) }" class="mat-cell-action-buttons">
								<div class="icons">
									<mat-icon (click)="clearDocument(doc)">delete</mat-icon>
									<mat-icon (click)="editDocument(doc)">edit</mat-icon>
									<app-status-chip
										*ngIf="(hasDocErrors$ | async) && docHasError(doc)"
										[icon]="'error_outline'"
										[removeMargin]="true"
										[ngStyle]="chipStyles.warnBg"
										[matTooltip]="docErrorMessage(doc)" [matTooltipShowDelay]="500" matTooltipPosition="above">
									</app-status-chip>
								</div>
							</td>
						</ng-container>
				
						<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
					</table>
				</div>
			</div>
		</mat-expansion-panel>
	</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<ng-container *ngIf="!(isSaving$ | async); else saving">
		<button 
			*ngIf="!isHandheld" 
			mat-stroked-button 
			class="button" 
			color="accent" 
			(click)="cancel()" 
			[disabled]="isSaving$ | async">
			{{ data.sysText.cancel }}
		</button>
		<button 
			mat-stroked-button 
			class="button" 
			color="accent" 
			(click)="onSubmit()" 
			[disabled]="(isSaving$ | async)">
			{{ data.sysText.save }}
		</button>
	</ng-container>
	<ng-template #saving>
		<button 
			mat-button 
			class="button" 
			type="button" 
			color="accent" 
			disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</ng-template>
</mat-dialog-actions>