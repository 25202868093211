<div class="dialog--title">
    <h6 class="grey1">{{ data.sysText.employerSettings_header | titlecase }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
        <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
    </ng-container>
    <mat-divider class="section-header-underline"></mat-divider>
</div>
  
<mat-dialog-content class="mat-dialog-content">
    <ng-container>
        <form [formGroup]="form">
            <app-input-field
                [control]="this.form.controls.companyName"
                [icon]="'business'"
                [label]="data.sysText.companyName_Title"
                [group]="form"
            ></app-input-field>
            <div class="domainName">
                <app-input-field class="inputWidth"
                    [control]="this.form.controls.subdomainName"
                    [icon]="'language'"
                    [label]="data.sysText.subdomainName_Title"
                    [group]="form"
                    [patternError]="data.sysText.subdomainNameError"
                    [msErrorKey]="'subdomainName'"
                    [customMsError]="(data.erProfile.modelStateErrors.hasOwnProperty('subdomainName') ? ((data.sysText.subdomainName_Title | titlecase) + ' ' + data.sysText.required) : null)">
                </app-input-field>
                <p class="body1">{{ data.sysText.domainUrl}}</p>  
            </div>

            <div class="dialog--section">
                <mat-icon class="field-icon">image</mat-icon><p class="grey1">{{ 'Custom employer logo' | titlecase }}</p>
                <mat-icon 
                    class="info-icon"
                    [matTooltip]="data.sysText.logoUploadInfo" 
                    [matTooltipShowDelay]="500" 
                    matTooltipPosition="above">
                    info_outline
                </mat-icon>
            </div>

            <div class="logo-upload-section">
                <div class="pad" *ngIf="logoChangedEvent || setEmployerLogo; else noLogo">
                    <div class="file-name-container">
                        <mat-icon class="fill4-dk">check</mat-icon>
                        <div>
                            <span class="body2 side-space grey3">{{ employerLogo.value }}</span>
                        </div>
                        <mat-icon class="black-inactive small pointer" (click)="clearFile()">clear</mat-icon>
                    </div>
                    <div>
                        <image-cropper
                            id="logo-cropper"
                            [imageChangedEvent]="logoChangedEvent"
                            [resizeToWidth]="500"
                            [resizeToHeight]="500"
                            [onlyScaleDown]="true"
                            [maintainAspectRatio]="false"
                            [disabled]="true"
                            [imageBase64]="setEmployerLogo"
                            [alignImage]="'left'"
                            output="base64"
                            format="png"
                            (imageCropped)="getEmployerLogo($event)"
                            (loadImageFailed)="loadImageFailed()">
                        </image-cropper>
                    </div>
                    <mat-error *ngIf="employerLogo.errors?.msError" class="mse-form-error caption bottom-hint">{{ employerLogo.errors?.msError }}</mat-error>
                </div>
                <ng-template #noLogo>
                    <input hidden type="file" accept="image/*" #imgFileInput (change)="logoChangeEvent($event)" />
                    <button
                        mat-stroked-button
                        class="button"
                        color="accent"
                        (click)="imgFileInput.click()">
                        {{ data.sysText.uploadLogo }}
                    </button>
                </ng-template>
            </div>

            <app-input-field
                [control]="this.form.controls.headcount"
                [icon]="'people'"
                [label]="data.sysText.headcountTotal_Title"
                [group]="form"
                [inputType]="'number'"
                [msErrorKey]="'headcount'"
                [customMsError]="(data.erProfile.modelStateErrors.hasOwnProperty('headcount') ? ((data.sysText.headcountTotal_Title | titlecase) + ' ' + data.sysText.required) : null)">
            </app-input-field>   
            <div class="dialog--section">
                <mat-icon class="field-icon">tune</mat-icon><p class="grey1">{{ data.sysText.accessSettings_Section | titlecase }}</p>
            </div>
            <div class="toggle--section">
                <app-slide-toggle *ngIf="this.data.erProfile.showEmployerEnabled"
                    [control]="this.form.controls.enableAccess"
                    [label]="data.sysText.employerAccess_info"
                    [checked]="this.data.erProfile.employerEnabled">
                </app-slide-toggle>
                <div class="bottom-row">
                    <app-slide-toggle 
                    [control]="this.form.controls.allowNewLeaves"
                    [label]="data.sysText.allowNewLeaves_info"
                    [checked]="this.data.erProfile.allowNewLeaves">
                </app-slide-toggle>
                </div>
            </div>
        </form>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
    <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async" *ngIf="!isHandheld">
        {{ data.sysText.cancel }}
    </button>
    <button mat-stroked-button class="button" color="accent" (click)="save()">
        {{ data.sysText.save }}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </button>
    </mat-dialog-actions>
</ng-template>
  
