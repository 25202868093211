<div class="header">
    <p class="overline title" *ngIf="isPrimary">{{sysText.mergeDuplicates_primaryRecord}}</p>
    <p class="overline title" *ngIf="!isPrimary">{{sysText.mergeDuplicates_secondaryRecord}}</p>
    <img *ngIf="employeeInfo.employee.image" class="image-container" [src]="employeeInfo.employee.image">
    <div *ngIf="!employeeInfo.employee.image" class="circle">
        {{ getInitials(employeeInfo.employee.description) }}
    </div> 
    <h5>{{employeeInfo.employee.description}}</h5>
    <p class="body2">{{employeeInfo.company.description}}</p>
</div>

<!-- Employee Info -->
<div class="section-header-container">
    <p class="overline grey1 semibold section-title">{{sysText.mergeDuplicates_employeeInfo}}</p>
    <mat-divider class="section-header-underline"></mat-divider>
</div>
<div class="section-body">
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_fullName}}</span> {{employeeInfo.employee.description ?? '--'}}</p>
    <p class="body2">
        <span class="semibold body-title">{{sysText.mergeDuplicates_workEmail}} 
            <mat-icon *ngIf="employeeInfo.primaryEmailIsPersonal == false" [matTooltip]="sysText.mergeDuplicates_preferred" [matTooltipShowDelay]="500" matTooltipPosition="above">stars</mat-icon>
        </span> 
        {{employeeInfo.workEmail ?? '--'}}
    </p>
    <p class="body2">
        <span class="semibold body-title">{{sysText.mergeDuplicates_personalEmail}} 
            <mat-icon *ngIf="employeeInfo.primaryEmailIsPersonal == true" [matTooltip]="sysText.mergeDuplicates_preferred" [matTooltipShowDelay]="500" matTooltipPosition="above">stars</mat-icon>
        </span> 
        {{employeeInfo.personalEmail ?? '--'}}
    </p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_workPhoneNumber}}</span> {{employeeInfo.workPhone ?? '--'}}</p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_personalPhoneNumber}}</span> {{employeeInfo.personalPhone ?? '--'}}</p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_jobTitle}}</span> {{employeeInfo.jobTitle ?? '--'}}</p>
    <p class="body2 flex"><span class="semibold body-title">{{sysText.mergeDuplicates_supervisors}}</span>
        <span *ngIf="employeeInfo.supervisors?.length == 0; else list">--</span>
        <ng-template #list>
            <span>
                <p *ngFor="let superv of employeeInfo.supervisors">{{superv.description}}</p>
            </span>
        </ng-template>
    </p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_dateOfHire}}</span> {{employeeInfo.employeeHireDate ? (employeeInfo.employeeHireDate | date) : '--'}}</p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_hoursWorkedPerWeek}}</span> {{employeeInfo.hoursPerWeek ?? '--'}}</p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_workingInCity}}</span> {{employeeInfo.workingInCity ?? '--'}}</p>
    <p class="body2"><span class="semibold body-title">{{sysText.mergeDuplicates_workingInState}}</span> {{employeeInfo.workingInState ?? '--'}}</p>
</div>

<!-- Leave Cases -->
<div class="section-header-container">
    <p class="overline grey1 semibold section-title">{{sysText.mergeDuplicates_leaveCases}}</p>
    <mat-divider class="section-header-underline"></mat-divider>
</div>
<div *ngIf="employeeInfo.leaveCases?.length > 0" class="section-body">
    <p class="body2" *ngFor="let case of employeeInfo.leaveCases">
        <span class="semibold body-title">
            {{case.caseNumber}}
        </span> 
        {{case.leaveType ? case.leaveType.description : sysText.mergeDuplicates_newRequest}}
        <span class="bullet-spacing">&bull;</span>
        {{case.currentStage}}
    </p>
</div>
<div *ngIf="employeeInfo.leaveCases?.length == 0" class="section-body">
    <p class="body2">{{sysText.mergeDuplicates_none}}</p>
</div>

<!-- Leave Hours -->
<div class="section-header-container">
    <p class="overline grey1 semibold section-title">{{sysText.mergeDuplicates_leaveHours}}</p>
    <mat-divider class="section-header-underline"></mat-divider>
</div>
<div class="section-body">
    <p class="body2">
        <span class="semibold body-title">
            {{sysText.mergeDuplicates_fmlaHours}}
        </span> 
        {{employeeInfo.fmlaHours?.totalHours ?? '--'}} {{sysText.mergeDuplicates_total}} / {{employeeInfo.fmlaHours?.availableHours ?? '--'}} {{sysText.mergeDuplicates_available}}
    </p>
    <p class="body2">
        <span class="semibold body-title">
            {{sysText.mergeDuplicates_stateHours}}
        </span> 
        {{employeeInfo.stateHours?.totalHours ?? '--'}} {{sysText.mergeDuplicates_total}} / {{employeeInfo.stateHours?.availableHours ?? '--'}} {{sysText.mergeDuplicates_available}}
    </p>
</div>