import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StatusChips } from '@core/models';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { TemplateItem } from '@core/models/case-communication/template-item.model';
import { appRoutePaths, manageEmployersRoutes } from '@core/routes/route-paths.constants';
import { ConstantsService, LayoutService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { EditTemplateAssignedEmployersComponent } from '@modules/dialogs/edit-template-assigned-employers/edit-template-assigned-employers.component';
import { indicate } from '@shared/helpers';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-template-assigned-employers',
  templateUrl: './template-assigned-employers.component.html',
  styleUrls: ['./template-assigned-employers.component.scss']
})
export class TemplateAssignedEmployersComponent implements OnInit {

  @Input() templateDetails: TemplateDetails;
  @Input() sysText: any;
  @Input() unsavedChangesSysText: any;
  @Output() isEditing = new EventEmitter<boolean>();

  public isMobile$: Observable<boolean>;
  public panelOpenState: boolean = true;
  public chipStyles: StatusChips;
  public isLoading$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private caseCommunicationService: CaseCommunicationService,
    private constants: ConstantsService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES;
    this.isMobile$ = this.layoutService.isMobile$();
  }

  public viewEmployerProfile(erId: number) {
    this.router.navigate([`${appRoutePaths.MANAGE_EMPLOYERS}/${manageEmployersRoutes.PROFILE}`, erId]);
  }

  public editTemplateAssignedEmployers(event: any): void {
    event.stopPropagation();
    this.caseCommunicationService.getEditTemplateAssignedEmployersDialog(this.templateDetails.id)
    .pipe(indicate(this.isLoading$))
    .subscribe(res => {
      const dialogConfig: MatDialogConfig = {
        width: this.layoutService.isHandheld ? '100vw' : '550px',
        maxWidth: this.layoutService.isHandheld ? '100vw' : '550px',
        maxHeight: this.layoutService.isHandheld ? '85vh' : '',
        data: { 
          templateDetails: this.templateDetails,
          options: res, 
          sysText: this.sysText,
          unsavedChangesSysText: this.unsavedChangesSysText
        }};
      
      const dialogRef = this.dialog.open(EditTemplateAssignedEmployersComponent, dialogConfig);
      dialogRef.componentInstance.isEditing.subscribe((res) => {
        this.isEditing.emit(res);
      });
      dialogRef.afterClosed().subscribe(() => {
        this.isEditing.emit(false);
      })
    });
  }
}
