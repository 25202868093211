import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EmployerLeaveYearRuleDialog, LeaveHourBank, LeaveYearRuleForm, LeaveYearRulePost } from '@core/models/leave-admin/employers/employer-leave-year-rule.model';
import { EmployerProfile, LeaveYearRule } from '@core/models/leave-admin/employers/employer-profile.model';
import { ErrorService, LayoutService } from '@core/services';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { indicate, nameof, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Subject } from 'rxjs';
import { LeaveYearRulesMode } from '@core/enums/leave-year-rule-mode.enum';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { ModelStateErrors, SelectionOption, StiiraError } from '@core/models';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeaveYears } from '@core/enums/leave-years.enum';
import { Banks } from '@core/enums/banks.enum';
import { isNumber } from '@microsoft/applicationinsights-core-js';

@Component({
  selector: 'app-add-edit-leave-year-rule',
  templateUrl: './add-edit-leave-year-rule.component.html',
  styleUrls: ['./add-edit-leave-year-rule.component.scss']
})
export class AddEditLeaveYearRulesComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<LeaveYearRuleForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public leaveYearRulesMode = LeaveYearRulesMode;
  public selectedBank: LeaveHourBank;
  public bankOptions: SelectionOption[] = [];
  
  private formChangeEmitted: boolean = false;
  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();
  private banks = Banks;

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      employerId: number;
      dialogOptions: EmployerLeaveYearRuleDialog;
      sysText: any; 
      mode: LeaveYearRulesMode;
      // used for edit...
      leaveYearRuleData?: LeaveYearRule;
      modelStateErrors?: ModelStateErrors;
      modelStateIndex?: number;
    },
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<AddEditLeaveYearRulesComponent>,
    private store: EmployerProfileStoreService,
    private dialog: MatDialog,
    private service: ManageEmployersService,
    private errorService: ErrorService
  ) { 
    this.form = this.fb.group<LeaveYearRuleForm>({
      effectiveDate:  this.fb.control(null),
      bank:  this.fb.control(null, Validators.required),
      workingInState:  this.fb.control(null),
      rule:  this.fb.control(null, Validators.required),
      fixedStart: this.fb.control(null),
      restoreHours: this.fb.control(null),
      restoreConditions: this.fb.control(null),
    });
  }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    const dto: LeaveYearRulePost = {
      employerId: this.data.employerId,
      effectiveDate: this.form.controls.effectiveDate.value,
      bank: this.form.controls.bank.value,
      workingInStateId: this.form.controls.workingInState.value,
      rule: this.form.controls.rule.value,
      fixedStart: this.form.controls.fixedStart.value,
      restoreHours: this.form.controls.restoreHours.value,
      restoreConditions: this.form.controls.restoreHours.value == true
        ? this.form.controls.restoreConditions.value
        : null,
    }

    if (this.data.mode == LeaveYearRulesMode.Add) {
      this.service.postLeaveYearRulesAdd(dto)
        .pipe(indicate(this.isSubmitting$))
        .subscribe((res)=>{
          this.store.employerProfile = res;
          this.dialogRef.close();
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))

    } else if (this.data.mode == LeaveYearRulesMode.Edit) {
      dto.leaveYearRuleId = this.data.leaveYearRuleData.leaveYearRuleId;
      this.service.postLeaveYearRulesEdit(dto)
        .pipe(indicate(this.isSubmitting$))
        .subscribe((res)=>{
          this.store.employerProfile = res;
          this.dialogRef.close();
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
    }
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.store.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private setupForm(): void {
    let msErrors = null;
    if (this.data.mode == LeaveYearRulesMode.Edit) {
      this.form.patchValue({
        effectiveDate: this.data.leaveYearRuleData.effectiveDate,
        bank: this.data.leaveYearRuleData.bank as number,
        workingInState: this.data.leaveYearRuleData.workingInState?.id as number,
        rule: this.data.leaveYearRuleData.rule?.id as number,
        fixedStart: this.data.leaveYearRuleData.fixedStart,
        restoreHours: this.data.leaveYearRuleData.restoreHours,
        restoreConditions: this.data.leaveYearRuleData.restoreConditions?.map(rc => rc.id as string),
      });

      this.selectedBank = this.data.dialogOptions.leaveHourBanks
        .find(b => b.id == this.data.leaveYearRuleData.bank) ?? null;

      // leave year rules msErrors are formatted for the table (array)
      // build msErrors for selected rules by modelStateIndex 
      msErrors = {};
      Object.keys(this.data.modelStateErrors).forEach((err) => {
        if (err.includes(`${nameof<EmployerProfile>('leaveYearRules')}.${this.data.modelStateIndex}`)) {
          const fieldName = err.substring(err.lastIndexOf('.') + 1);
          msErrors[fieldName] = this.data.modelStateErrors[err];
        }
      });

      this.setFixedStartField(this.form.controls.fixedStart);
      this.setRestoreHoursField(this.form.controls.restoreHours, this.data.leaveYearRuleData.effectiveDate, this.form.controls.bank.value);
    } else {
      this.form.controls.fixedStart.disable();
      this.form.controls.restoreHours.disable();
    }

    this.buildBankOptions();

    setTimeout(() => {
      if (msErrors) {
        this.errorService.setFormModelStateErrors(this.form, msErrors);
      }

      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });

      this.form.controls.effectiveDate.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.setRestoreHoursField(this.form.controls.restoreHours, res, this.form.controls.bank.value);
        });

      this.form.controls.bank.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.handleBankChange(res);
        });

      this.form.controls.rule.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.setFixedStartField(this.form.controls.fixedStart);
        });

      this.form.controls.restoreHours.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!res){
            this.form.controls.restoreConditions.setValue(null);
          }
        });

      this.formInitValues = { ...this.form.value };
    },0);
  }

  private setFixedStartField(control: FormControl) {
    if (this.form.controls.rule.value == LeaveYears.Fixed ) {
      if (!control.enabled){
        control.enable();
      }
    } else {
      this.form.controls.fixedStart.setValue(null);
      control.disable();
    }
  }

  private handleBankChange(bankId: number): void {
    this.selectedBank = this.data.dialogOptions.leaveHourBanks.find(b => b.id == bankId) ?? null;
    this.form.controls.restoreConditions.setValue(null);
    this.setRestoreHoursField(this.form.controls.restoreHours, this.form.controls.effectiveDate.value, bankId);

    if (this.selectedBank?.workingInState
      && this.data.dialogOptions.stateOptions.find(so => so.id === this.selectedBank?.workingInState)) {
      this.form.controls.workingInState.setValue(this.selectedBank.workingInState);
      this.form.controls.workingInState.disable();
    } else if (this.form.controls.workingInState.disabled) {
      this.form.controls.workingInState.enable();
    }

    if (this.selectedBank?.rule
      && this.data.dialogOptions.leaveYearOptions.find(luo => luo.id === this.selectedBank?.rule)) {
      this.form.controls.rule.setValue(this.selectedBank.rule, {emitEvent: false});
      this.form.controls.rule.disable();
    } else if (this.form.controls.rule.disabled) {
      this.form.controls.rule.enable();
    }
  }

  private setRestoreHoursField(restoreHoursControl: FormControl<boolean>, effectiveDate: Date, bankId: number): void {
    if ((effectiveDate == null || bankId == null) && restoreHoursControl.enabled) {
      restoreHoursControl.setValue(null, {emitEvent: false});
      this.form.controls.restoreConditions.setValue(null);
      restoreHoursControl.disable();
    } else if (effectiveDate != null && bankId != null && !restoreHoursControl.enabled) {
      restoreHoursControl.enable();
    }
  }

  private buildBankOptions(): void {
    const bankIds = Object.values(this.banks).filter(v => isNumber(v));
    bankIds.forEach(id => {
      const desc: string = this.data.sysText['bank_' + id];
      const option: SelectionOption = {
        id: id,
        description: desc
      };
      this.bankOptions.push(option);
    });
  }
}