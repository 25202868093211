<ng-container>
  <h6 class="dialog--title" >
    {{ sysText.changeEmailDialog_title | titlecase }}
  </h6>
  <mat-dialog-content [formGroup]="form">
    <p class="subtitle1 bottom-margin">
      {{ sysText.changeEmailDialog_subtitle }}
    </p>
    <mat-form-field color="accent">
      <input 
        matInput type="text" 
        [placeholder]="sysText.newEmail | titlecase" 
        formControlName="email"
      />
      <mat-error>{{ email.errors?.msError }}</mat-error>
    </mat-form-field>
  </mat-dialog-content>  
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!(isSubmitting$ | async); else submitting">
      <button mat-stroked-button class="button" type="button" [mat-dialog-close] color="accent">
        {{ sysText.cancel }}
      </button>
      <button
        mat-stroked-button
        class="button"
        type="submit"
        (click)="changeEmail()"
        color="accent"
      >
        {{ sysText.changeEmail }}
      </button>
    </ng-container>
    <ng-template #submitting>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>
</ng-container>