<div class="dialog--title">
  <h6 class="grey1">{{data.sysText.editNoteDialog_title | titlecase}}</h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" mat-dialog-close class="icon__button">close</mat-icon>
  </ng-container>
</div> 

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="info-container">
      <app-text-area
        [control]="form.controls.message"
        [label]="data.sysText.note"
        [icon]="'edit_note'"
        [hint]="!data.note.canEditNote ? data.sysText.note_hint : null">
      </app-text-area>
    </div>
    <ng-container *ngIf="showVisibilityOptions">
      <p class="body2">{{data.sysText.editVisibilityDialog_subTitle}}</p>
      <div class="info-container">
        <div *ngFor="let control of visibilityOptionsFormArray.controls; index as i" class="checkbox">
          <mat-checkbox
            [formControl]="control"
          ></mat-checkbox>
          <p class="body2">{{visibilityOptionlabels[i] | titlecase}}</p>
          <mat-icon class="small-icon">{{visibilityOptionIcons[i]}}</mat-icon>
        </div>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!(isSubmitting$ | async); else saving">
    <button *ngIf="!isMobile"
      mat-stroked-button
      class="button"
      color="accent"
      (click)="close()"
      [disabled]="isSubmitting$ | async">
      {{data.sysText.cancel}}
    </button>
    <button mat-stroked-button class="button" color="accent" (click)="save()" [disabled]="(isSubmitting$ | async)">
      {{data.sysText.save}}
    </button>
  </ng-container>

  <ng-template #saving>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
