import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SendPhoneCode, StiiraError, VerifyPhoneCode } from '@core/models';
import { ErrorService, ProfileService, SnackbarService } from '@core/services';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.scss']
})
export class ChangePhoneNumberComponent implements OnInit {
  form: UntypedFormGroup;
  verifyForm: UntypedFormGroup;
  isSubmitting$ = new Subject<boolean>();
  showVerificationForm: boolean;

  get phoneNumber(): FormControl {
    return this.form.get('phoneNumber') as FormControl;
  }

  get otp(): FormControl {
    return this.verifyForm.get('otp') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangePhoneNumberComponent>,
    private profileService: ProfileService,
    private snackbarService: SnackbarService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public sysText
  ) { }

  ngOnInit(): void {
    this.showVerificationForm = false;
    this.form = this.fb.group({
      phoneNumber: [null, Validators.required],
    });
    this.verifyForm = this.fb.group({
      otp: [null, Validators.required],
    });
  }

  changePhoneNumber(): void {
    this.sendCode(this.phoneNumber.value)
  }

  confirmPhoneNumber(): void {    
    const setPhoneModel: VerifyPhoneCode = {
      phoneNumber: this.phoneNumber.value,
      otp: this.verifyForm.value.otp
    }
    this.profileService
      .verifyChangePhoneCode(setPhoneModel)
      .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {
          this.snackbarService.open("Phone number successfully set!", '', 5000);
          this.dialogRef.close();
        },
        (err: StiiraError) => this.errorService.setFormModelStateErrors(this.verifyForm, err.modelStateErrors)
      );
  }

  onResend(): void {
    this.sendCode(this.phoneNumber.value);
  }

  sendCode(number: string): void {
    const sendCode: SendPhoneCode = {
      phoneNumber: number
    }
    this.profileService.sendChangePhoneCode(sendCode)
    .pipe(indicate(this.isSubmitting$))
      .subscribe(
        () => {
          this.showVerificationForm = true;   
          this.snackbarService.open('Verification code sent!', 'Dismiss');
        },
        (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
      );
  }
}