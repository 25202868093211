import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseEmailInfo, EmailAttachment } from '@core/models/case-communication/case-communication-drawer.model';
import { ConstantsService, LayoutService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { base64StringToBlob } from 'blob-util';
import { Subject } from 'rxjs';
import { Editor } from 'tinymce';

@Component({
  selector: 'app-view-email',
  templateUrl: './view-email.component.html',
  styleUrls: ['./view-email.component.scss']
})
export class ViewEmailComponent implements OnInit {
  public isDownloadingAttachment$: Subject<boolean> = new Subject<boolean>();

  public editorStyle: string;

  private attachmentBeingDownloaded: number = null;

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      emailData: CaseEmailInfo,
      sysText: any,
      isPreview: boolean
    },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<ViewEmailComponent>,
    private constants: ConstantsService,
    private service: CaseCommunicationService,
    private leaveAdminStore: LeaveAdminStoreService
  ) { }

  ngOnInit(): void {
    this.editorStyle = this.constants.EDITOR_STYLES;
  }

  public tinySetup(editor: Editor): void {
    editor.mode.set("readonly");
  }

  public close(): void {
    this.dialogRef.close();
  }

  public downloadAttachment(attachment: EmailAttachment): void {
    this.attachmentBeingDownloaded = attachment.attachmentId;

    this.service.getAttachment({
        caseId: this.leaveAdminStore.caseDetails.leaveInformation.caseId,
        attachmentId: attachment.attachmentId,
        isDocument: attachment.isDocument,
        isPreview: this.data.isPreview
      })
      .pipe(indicate(this.isDownloadingAttachment$))
      .subscribe(res => {
        var newBlob = base64StringToBlob(res.fileBytes, res.mimeType);
        var url = window.URL.createObjectURL(newBlob);
    
        var win = window.open(url, '_blank');

        win.onload = () => { this.attachmentBeingDownloaded = null; };
      })
  }

  public isDownloadingAttachment (attachmentId: number): boolean {
    return attachmentId == this.attachmentBeingDownloaded;
  }
}