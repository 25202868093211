<div class="dialog--title">
    <h6>
        {{ data.sysText.addNewTemplate_header }}
    </h6>
  </div>
  <mat-dialog-content>
    <app-input-field
        [control]="form.controls.templateName"
        [icon]="'email'"
        [label]="data.sysText.templateName"
        [inputType]="'string'"
    ></app-input-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
    <button mat-stroked-button class="button" mat-dialog-close color="accent">
      {{ data.sysText.cancel }}
    </button>
    <button mat-stroked-button class="button" color="accent" (click)="onSubmit()">
      {{ data.sysText.add }}
    </button>
  </mat-dialog-actions>
  
  <ng-template #submitting>
    <mat-dialog-actions align="end">
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </mat-dialog-actions>
  </ng-template>
  