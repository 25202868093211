import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddEditNoteMode } from '@core/enums/add-edit-note-mode.enum';
import { LeaveCaseNoteVisibilityOption } from '@core/enums/leave-case-note-visibility-option.enum';
import { StiiraError } from '@core/models';
import { AddCaseNote } from '@core/models/leave-admin/case-note.model';
import { ConstantsService, ErrorService, LayoutService } from '@core/services';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { isNumber } from '@microsoft/applicationinsights-core-js';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';

@Component({
  selector: 'app-add-new-note',
  templateUrl: './add-new-note.component.html',
  styleUrls: ['./add-new-note.component.scss']
})
export class AddNewNoteComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public isSubmitting$: Subject<boolean>;
  public visibilityOptionIcons: string[];
  public visibilityOptionlabels: string[] = [];
  public showVisibilityOptions: boolean = false;

  private formInitValues: any;
  private visibilityOptionsEnum = LeaveCaseNoteVisibilityOption;
  private visibilityOptions: (string | LeaveCaseNoteVisibilityOption)[];
  private destroy$: Subject<void> = new Subject<void>();
  private formChangeEmitted: boolean = false;

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get visibilityOptionsFormArray(): FormArray {
    return this.form.controls.visibilityOptions as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      caseId?: number;
      employeeId?: number;
      employerId?: number;
      sysText: any;
      unsavedSysText: any;
      mode: AddEditNoteMode;
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewNoteComponent>,
    private layoutService: LayoutService,
    private leaveAdminService: LeaveAdminService,
    private leaveAdminStore: LeaveAdminStoreService,
    private manageEmployerService: ManageEmployersService,
    private employerProfileStore: EmployerProfileStoreService,
    private manageEmployeesService: ManageEmployeesService,
    private employeeRecordStore: EmployeeRecordStoreService,
    private errorService: ErrorService,
    private constants: ConstantsService,
    private dialog: MatDialog
    ) {
    this.form = this.fb.group({ 
      message: [null],
      visibilityOptions: this.fb.array([])
    });
    this.isSubmitting$ = new Subject();
  }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public save(): void {
    this.dialogRef.disableClose = true;
    switch(this.data.mode) {
      case AddEditNoteMode.Employee: {
        this.manageEmployeesService.addEmployeeNote({employeeId: this.data.employeeId, message: this.form.controls.message.value})
          .pipe(indicate(this.isSubmitting$))
          .subscribe(res => {
            this.employeeRecordStore.addEmployeeNote(res);
            this.dialogRef.close();
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
        break;
      }

      case AddEditNoteMode.Employer: {
        this.manageEmployerService.postEmployerNoteAdd({employerId: this.data.employerId, message: this.form.controls.message.value})
          .pipe(indicate(this.isSubmitting$))
          .subscribe(res => {
            this.employerProfileStore.addEmployerNote(res);
            this.dialogRef.close();
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
        break;
      }

      case AddEditNoteMode.LeaveCase: {
        const note: AddCaseNote = {
          caseId: this.data.caseId,
          message: this.form.controls.message.value,
          visibilitySettings: []
        }

        this.visibilityOptions.forEach((id, index) => {
          if (this.visibilityOptionsFormArray.controls[index].value) {
            note.visibilitySettings.push(id as LeaveCaseNoteVisibilityOption);
          }
        })

        this.leaveAdminService.addCaseNote(note)
          .pipe(indicate(this.isSubmitting$))
          .subscribe(res => {
            this.leaveAdminStore.addCaseNote(res);
            this.dialogRef.close();
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
        break;
      }
    }
  }

  private buildVisibilityOptions(): void {
    this.visibilityOptions.forEach(id => {
      this.visibilityOptionlabels.push(this.data.sysText['visibilityOption_' + id]);
      this.visibilityOptionsFormArray.push(this.fb.control(false));
    });
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private setupForm(): void {
    if (this.data.mode == AddEditNoteMode.LeaveCase) {
      this.visibilityOptions = Object.values(this.visibilityOptionsEnum).filter(v => isNumber(v));
      this.buildVisibilityOptions();
      this.showVisibilityOptions = true;
    }
    this.visibilityOptionIcons = this.constants.VISIBILITY_ICONS;
    
    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }
}
