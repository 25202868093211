import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeWithRoles, Role, SelectionOption } from '@core/models';
import { EmployeeRole } from '@core/models/role-admin/employee-role.model';
import { RoleAdminService } from '@core/services/role-admin.service';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit {
  public selectedRole: FormControl<string>;
  public roleOptions: SelectionOption[];
  public roleNotes: string;
  public isSubmitting$: Subject<boolean>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sysText: any; allRoles: Role[]; eWithRoles: EmployeeWithRoles },
    private service: RoleAdminService,
    private dialogRef: MatDialogRef<AddRoleComponent>
  ) {
    this.selectedRole = new FormControl<string>(null, Validators.required);
    this.isSubmitting$ = new Subject();
  }

  ngOnInit(): void {
    this.roleOptions = this.data.allRoles
      .filter((r: Role) => !this.data.eWithRoles.roles.includes(r))
      .map((res: Role) => {
        return { id: res.id, description: res.name } as SelectionOption;
      });

    this.selectedRole.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val) {
          this.roleNotes = this.data.allRoles.find((r) => r.id === val).notes;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    this.dialogRef.disableClose = true;
    const eRole: EmployeeRole = {
      eeId: this.data.eWithRoles.eeId,
      roleId: this.selectedRole.value,
    };
    this.service.addEmployeeRole(eRole)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe(() => {
        this.dialogRef.close(eRole);
      });
  }
}
