<div class="dialog--title">
	<h6 class="grey1">{{ data.sysText.duplicateDetected_header | titlecase }}</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" [mat-dialog-close]="false" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
	<p>{{ data.duplicateDetectedDialog.employeeDuplicatesFound ? data.sysText.duplicateDetected_employeeMessage2 : data.sysText.duplicateDetected_caseMessage }}</p>

	<!-- Duplicate Employees -->
	<ng-container *ngIf="data.duplicateDetectedDialog.employeeDuplicatesFound; else cases">
		@for (duplicateEmployeesFound of data.duplicateDetectedDialog.employeeDuplicatesFound; track duplicateEmployeesFound; let i = $index) {
			<div class="border-box">
				<div class="section-info-container">
					<p class="body2 grey2 overline">{{ data.sysText.duplicateDetected_youEntered | uppercase }}:</p>
					<div class="name-section">
						<h6 class="grey1 bullet-spacing">{{ duplicateEmployeesFound.postEmployeeInfo.fullName }}</h6><span class="bullet-spacing">&bull;</span><p>{{ duplicateEmployeesFound.postEmployeeInfo.company }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.workEmail | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployeesFound.postEmployeeInfo.workEmail ?? "--" }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.personalEmail | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployeesFound.postEmployeeInfo.personalEmail ?? "--" }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.workPhone | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployeesFound.postEmployeeInfo.workPhone ?? "--" }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.personalPhone | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployeesFound.postEmployeeInfo.personalPhone ?? "--" }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.jobTitle | titlecase}}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployeesFound.postEmployeeInfo.jobTitle ?? "--"}}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.supervisors | titlecase}}</p>
						</div>
						<p class="body2 grey1 semibold" *ngIf="!(duplicateEmployeesFound.postEmployeeInfo.supervisors?.length > 0); else list">--</p>
						<ng-template #list>
							<span class="ellipsis">
								<p class="body2 grey1 semibold ellipsis align-left" *ngFor="let superv of duplicateEmployeesFound.postEmployeeInfo.supervisors">{{ superv}}</p>
							</span>
						</ng-template>
					</div>
				</div>
			</div>
			<div class="border-box">
				<p class="body2 grey2 overline">{{ data.sysText.duplicateDetected_potentialDuplicates | uppercase}}:</p>
				@for (duplicateEmployee of duplicateEmployeesFound.duplicateEmployeesFound; track duplicateEmployee; let i = $index) {
					<div class="section-info-container  margin-bottom">
						<div class="name-section">
							<h6 class="grey1 bullet-spacing">{{ duplicateEmployee.fullName }}</h6><span class="bullet-spacing">&bull;</span><p>{{ duplicateEmployee.company }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.workEmail | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployee.workEmail ?? "--" }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.personalEmail | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployee.personalEmail ?? "--" }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.workPhone | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployee.workPhone ?? "--" }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.personalPhone | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployee.personalPhone ?? "--" }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.jobTitle | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold ellipsis">{{ duplicateEmployee.jobTitle ?? "--" }}</p>
						</div>
						<div class="info-row">
							<div class="tile-label-container info-spacing" >
								<p class="body2 grey2 ellipsis">{{ data.sysText.supervisors | titlecase }}</p>
							</div>
							<p class="body2 grey1 semibold" *ngIf="!(duplicateEmployee.supervisors?.length > 0); else list">--</p>
							<ng-template #list>
								<span class="ellipsis">
									<p class="body2 grey1 semibold ellipsis align-left" *ngFor="let superv of duplicateEmployee.supervisors">{{superv }}</p>
								</span>
							</ng-template>
						</div>
					</div>
				}
			</div>
			<mat-divider *ngIf="i != data.duplicateDetectedDialog.employeeDuplicatesFound.length - 1" class="section-underline"></mat-divider>
		}
	</ng-container>

	<!-- Duplicate Cases -->
	<ng-template #cases>
		<div class="border-box">
			<div class="section-info-container">
				<p class="body2 grey2 overline">{{ data.sysText.duplicateDetected_youEntered | uppercase}}:</p>
				<div class="name-section">
					<h6 class="grey1 bullet-spacing">{{ data.duplicateDetectedDialog.caseDuplicatesFound.postCaseInfo.fullName }}</h6><span class="bullet-spacing">&bull;</span><p>{{ data.duplicateDetectedDialog.caseDuplicatesFound.postCaseInfo.company}}</p>
				</div>
				<div class="info-row">
					<div class="tile-label-container info-spacing" >
						<p class="body2 grey2 ellipsis">{{ data.sysText.leaveReason | titlecase }}</p>
					</div>
					<p class="body2 grey1 semibold ellipsis">{{  data.duplicateDetectedDialog.caseDuplicatesFound.postCaseInfo.leaveReason }}</p>
				</div>
				<div class="info-row">
					<div class="tile-label-container info-spacing" >
						<p class="body2 grey2 ellipsis">{{ data.sysText.requestedStartDate | titlecase}}</p>
					</div>
					<p class="body2 grey1 semibold ellipsis">{{ data.duplicateDetectedDialog.caseDuplicatesFound.postCaseInfo.requestedStartDate | date}}</p>
				</div>
			</div>
		</div>
		<div class="border-box">
			<p class="body2 grey2 overline">{{ data.sysText.duplicateDetected_potentialDuplicates | uppercase }}:</p>
			@for (duplicateCase of data.duplicateDetectedDialog.caseDuplicatesFound.duplicateCasesFound; track duplicateCase; let i = $index) {
				<div class="section-info-container  margin-bottom">
					<div class="name-section">
						<h6 class="grey1 bullet-spacing">{{ duplicateCase.fullName }}</h6><span class="bullet-spacing">&bull;</span><p>{{duplicateCase.company}}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{data.sysText.caseId | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateCase.caseId }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{data.sysText.leaveReason | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateCase.leaveReason }}</p>
					</div>
					<div class="info-row">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.dateReceived | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateCase.dateReceived | date }}</p>
					</div>
					<div class="info-row" *ngIf="duplicateCase.startDate">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{data.sysText.startDate | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateCase.startDate | date }}</p>
					</div>
					<div class="info-row" *ngIf="duplicateCase.requestedStartDate">
						<div class="tile-label-container info-spacing" >
							<p class="body2 grey2 ellipsis">{{ data.sysText.requestedStartDate | titlecase }}</p>
						</div>
						<p class="body2 grey1 semibold ellipsis">{{ duplicateCase.requestedStartDate | date }}</p>
					</div>
				</div>
			}
		</div>
	</ng-template>
	
	<p class="bottom-message">{{ data.duplicateDetectedDialog.employeeDuplicatesFound ? data.sysText.duplicateDetected_employeeMessage2 : data.sysText.duplicateDetected_caseMessage2}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<ng-container *ngIf="!(isSaving$ | async); else saving">
		<button mat-stroked-button class="button" color="accent" [mat-dialog-close]="false" [disabled]="isSaving$ | async"
			*ngIf="!isHandheld">
			{{ data.sysText.duplicateDetected_cancel }}
		</button>
		<button mat-stroked-button class="button" color="accent" [mat-dialog-close]="true" [disabled]="(isSaving$ | async)">
			{{ data.sysText.duplicateDetected_save }}
		</button>
	</ng-container>
	<ng-template #saving>
		<button mat-button class="button" type="button" color="accent" disabled>
			<mat-spinner diameter="30" color="accent"></mat-spinner>
		</button>
	</ng-template>
</mat-dialog-actions>