<div class="filter-and-buttons">
	<div class="flex">
		<mat-form-field color="accent" class="field search-field">
			<mat-label>{{sysText.search | capitalizefirst}}...</mat-label>
			<mat-icon matSuffix class="suffix-icon">search</mat-icon>
			<input matInput [formControl]="searchValue" (keyup)="applySearch($event)" #input title="apply">
		</mat-form-field>
		<app-select-field
			class="field"
			[control]="typeControl"
			[label]="sysText.emailType"
			[options]="emailTemplatesData.typeOptions"
			[shouldCapitalize]="false"
			[showBlankOption]="true"
			[showHintArea]="false"
			[hideIconSpace]="true">
		</app-select-field>
		<app-select-field
			class="field"
			[control]="employerControl"
			[label]="sysText.owner"
			[options]="emailTemplatesData.employerOptions"
			[shouldCapitalize]="false"
			[showBlankOption]="true"
			[showHintArea]="false"
			[hideIconSpace]="true">
		</app-select-field>
	</div>
	<div class="button-container">
		<button
			mat-stroked-button
			class="button button-height"
			color="accent"
			(click)="onCreateNew()">
			<mat-icon *ngIf="!isLoadingNewTemplateDialog">add</mat-icon>
			<mat-icon *ngIf="isLoadingNewTemplateDialog">
				<mat-spinner [diameter]="18" color="accent"></mat-spinner>
			</mat-icon>
			<span> {{ sysText.submitNewRequest }} </span>
			<span> {{ sysText.addNewTemplate }} </span>
		</button>
	</div>
</div>

<div [ngClass]="{ 'make-scroll': !isMobile }" class="container">
	<table mat-table [dataSource]="_et" matSort id="data-table">

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.name | titlecase }}</span>
			</th>
			<td mat-cell *matCellDef="let et" [ngClass]="{ caption: isMobile }">
				<div class="chip-container">
					<span class="link" (click)="viewTemplate(et.id)">
						{{ et.name }}
					</span>
				</div>
			</td>
		</ng-container>

		<!-- TemplateType Column -->
		<ng-container matColumnDef="templateType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.emailType | titlecase }}</span>
			</th>
			<td mat-cell *matCellDef="let et" [ngClass]="{ caption: isMobile }">
				<span>{{ et.templateType.description }}</span>
			</td>
		</ng-container>

		<!-- Owner Column -->
		<ng-container matColumnDef="ownerEmployer">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.owner | titlecase }}</span>
			</th>
			<td mat-cell *matCellDef="let et" [ngClass]="{ caption: isMobile }">
				<div class="chip-container">
					<span *ngIf="et.ownerEmployer; else noLink" class="link" (click)="viewEmployerProfile(et.ownerEmployer.id)">
						{{ et.ownerEmployer.employerName }}
					</span>
					<ng-template #noLink>
						-- {{ (et.id < 0 ? sysText.system : sysText.stock) | titlecase }} --
					</ng-template>
					<app-status-chip
							*ngIf="et.isOwner"
							[icon]="'stars'"
							[ngStyle]="chipStyles.successBg"
							[tooltip]="sysText.templateOwner | titlecase"
							[removeMargin]="true">
						</app-status-chip>
				</div>
			</td>
		</ng-container>

		<!-- Allow Use Column -->
		<ng-container matColumnDef="allowUse">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.allowUse | titlecase }}</span>
			</th>
			<td mat-cell *matCellDef="let et" [ngClass]="{ caption: isMobile }">
				<span class="chip-container">
					<app-status-icon [isEnabled]="et.allowUse"></app-status-icon> 
					{{et.allowUse ? (sysText.enabled | titlecase) : (sysText.disabled | titlecase)}}
				</span>
			</td>
		</ng-container>

		<!-- Assigned to My Employer Column -->
		<ng-container matColumnDef="assignedToEmployer">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span>{{ sysText.assignedToMyEmployer }}</span>
			</th>
			<td mat-cell *matCellDef="let et" [ngClass]="{ caption: isMobile }">
				<span class="chip-container" *ngIf="et.assignedToEmployer != null" >
					<app-status-icon [isEnabled]="et.assignedToEmployer"></app-status-icon> 
					{{et.assignedToEmployer ? (sysText.assigned | titlecase) : (sysText.unassigned | titlecase)}}
				</span>
			</td>
		</ng-container>

		<!-- Actions Column -->
		<ng-container matColumnDef="actionMenu">
			<th mat-header-cell *matHeaderCellDef>
			</th>
			<td mat-cell *matCellDef="let et" class="cell-align-right" [ngClass]="{ caption: isMobile }" class="action-cell">
				<ng-container *ngIf="(isSubmittingId !== et.id); else spinning">
					<button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item class="grey1" (click)="viewTemplate(et.id)">
							<ng-container *ngIf="!et.ownerEmployer || et.id < 0; else edit">
								<mat-icon>search</mat-icon>
								<span>{{ sysText.view | capitalizefirst }}</span>
							</ng-container>
							<ng-template #edit>
								<mat-icon>edit</mat-icon>
								<span>{{ sysText.edit | capitalizefirst }}</span>
							</ng-template>
						</button>
						<button *ngIf="et.canDuplicate" mat-menu-item class="grey1" (click)="duplicateTemplate(et)">
							<mat-icon>library_add</mat-icon>
							<span>{{ sysText.duplicateTemplate }}</span>
						</button>
						<ng-container *ngIf="et.canAssignMyEmployer ">
							<button *ngIf="!et.assignedToEmployer ; else assign" mat-menu-item class="grey1" (click)="onAssignMyEmployer(et.id)">
								<mat-icon>check</mat-icon>
								<span>{{ sysText.assignEmployer }}</span>
							</button>
							<ng-template #assign>
								<button mat-menu-item class="grey1" (click)="onUnassignMyEmployer(et.id)">
									<mat-icon>close</mat-icon>
									<span>{{ sysText.unassignEmployer }}</span>
								</button>
							</ng-template>
						</ng-container>
					</mat-menu>
				</ng-container>
				<ng-template #spinning>
					<div class="spinner-container">
						<mat-spinner [diameter]="25" color="accent"></mat-spinner>
					</div>
				</ng-template>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
<mat-paginator [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" color="accent" [showFirstLastButtons]="pageSizeOptions.length > 1"></mat-paginator>