import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DuplicateDetectedDialog } from '@core/models/leave-admin/employees/duplicate-detected-dialog.model';
import { LayoutService } from '@core/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duplicate-detected-dialog',
  templateUrl: './duplicate-detected-dialog.component.html',
  styleUrls: ['./duplicate-detected-dialog.component.scss']
})
export class DuplicateDetectedDialogComponent implements OnInit {

  public isSaving$: Subject<boolean> = new Subject<boolean>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      sysText: any,
      duplicateDetectedDialog: DuplicateDetectedDialog
      },
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
  }
}
