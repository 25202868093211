import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { EmployeeRecord } from '@core/models/leave-admin/employees/employee-record.model';
import { EmployeeSettingsForm, EmployeeSettingsFormPost } from '@core/models/leave-admin/employees/employee-settings-form.model';
import { ErrorService } from '@core/services';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';

@Component({
  selector: 'app-employee-settings',
  templateUrl: './employee-settings.component.html',
  styleUrls: ['./employee-settings.component.scss']
})
export class EmployeeSettingsComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  public form: FormGroup<EmployeeSettingsForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;

  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get allowAccess(): FormControl {
    return this.form.controls.allowAccess as FormControl;
  }

  get allowNewLeaves(): FormControl {
    return this.form.controls.allowNewLeaves as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      eeRecord: EmployeeRecord; 
      sysText: any; 
      unsavedChangesSysText: any
    },
    private employeeSettingsdialogRef: MatDialogRef<EmployeeSettingsComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private manageEmployeesService: ManageEmployeesService,
    private errorService: ErrorService,
  ) { 
    this.isSaving$ = new Subject();
    this.form = this.fb.group<EmployeeSettingsForm>({
      allowAccess: this.fb.control(null),
      allowNewLeaves: this.fb.control(null)
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      allowAccess: this.data.eeRecord.allowAccess,
      allowNewLeaves: this.data.eeRecord.allowNewLeaves
    });
    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.formChangeEmitted && !this.noChanges) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save(): void {
    this.employeeSettingsdialogRef.disableClose = true;
    const dto: EmployeeSettingsFormPost = {
      employeeId: this.data.eeRecord.employeeId,
      allowAccess: this.allowAccess.value,
      allowNewLeaves: this.allowNewLeaves.value
    }
      this.manageEmployeesService.
        postEmployeeSettings(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.employeeSettingsdialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.employeeSettingsdialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.employeeSettingsdialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.employeeSettingsdialogRef.close();
        }
      });
  }
}