<div class="dialog--title">
    <h6 class="grey1">{{'edit leave information' | titlecase}}</h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<ng-container *ngIf="!isAnonymous; else anonymousMessage">
    <mat-dialog-content>
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="main-container">
                <div class="column-container">
                    <!-- CaseID -->
                    <app-input-field
                        class="field"
                        [control]="caseId"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_caseNum"
                        [inputType]="'string'"
                        [icon]="'assignment_turned_in'"
                        [group]="form"
                        [hint]="(data.fieldNotifications.hasOwnProperty('caseId') ? data.fieldNotifications['caseId'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-input-field>
                    <!-- daterecieved -->
                    <app-date-field
                        class="field"
                        [control]="dateReceived"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_dateReceived"
                        [group]="form"
                        [icon]="'calendar_today'"
                        [hint]="(data.fieldNotifications.hasOwnProperty('dateReceived') ? data.fieldNotifications['dateReceived'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-date-field>
                    <!-- stage  -->
                    <app-input-field
                        class="field"
                        [control]="stage"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_stage"
                        [icon]="'format_list_numbered'"
                        [group]="form"
                        [hint]="(data.fieldNotifications.hasOwnProperty('stage') ? data.fieldNotifications['stage'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true" 
                    ></app-input-field>
                    <!-- req start date  -->
                    <app-date-field
                        class="field"
                        [control]="requestedStartDate"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_requestedStartDate"
                        [group]="form"
                        [icon]="'calendar_today'"
                        [hint]="(data.fieldNotifications.hasOwnProperty('requestedStartDate') ? data.fieldNotifications['requestedStartDate'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-date-field>
                    <!-- req end date  -->
                    <app-date-field
                        class="field"
                        [control]="requestedEndDate"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_requestedEndDate"
                        [group]="form"
                        [icon]="'calendar_today'"
                        [hint]="(data.fieldNotifications.hasOwnProperty('requestedEndDate') ? data.fieldNotifications['requestedEndDate'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-date-field>
                    <!-- start date -->
                    <app-date-field
                        class="field"
                        [control]="startDate"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_startDate"
                        [group]="form"
                        [icon]="'calendar_today'"
                        [hint]="(data.fieldNotifications.hasOwnProperty('startDate') ? data.fieldNotifications['startDate'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-date-field>
                    <!-- end date -->
                    <app-date-field
                        class="field"
                        [control]="endDate"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_endDate"
                        [group]="form"
                        [icon]="'calendar_today'"
                        [hint]="(data.fieldNotifications.hasOwnProperty('endDate') ? data.fieldNotifications['endDate'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-date-field>
                    <!-- leave reason  -->
                    <app-select-field
                        [control]="leaveReason"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_leaveReason"
                        [options]="data.options.leaveReason"
                        [icon]="'track_changes'"
                        [shouldCapitalize]="false"
                        [showBlankOption]="true"
                        [hint]="(data.fieldNotifications.hasOwnProperty('leaveReason') ? data.fieldNotifications['leaveReason'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-select-field>
                    <!-- leave type  -->
                    <app-select-field
                        [control]="leaveType"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_leaveType"
                        [options]="data.options.leaveTypes"
                        [icon]="'domain'"
                        [shouldCapitalize]="false"
                        [showBlankOption]="true"
                        [hint]="(data.fieldNotifications.hasOwnProperty('leaveType') ? data.fieldNotifications['leaveType'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true">
                    </app-select-field> 
                    <!-- leave subtype -->
                    <app-autocomplete-field
                        [group]="form"
                        [control]="form.controls.leaveSubtype"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_leaveSubtype"
                        [options]="data.options.leaveSubtypes"
                        [isSimple]="true"
                        [hint]="(data.fieldNotifications.hasOwnProperty('leaveSubtype') ? data.fieldNotifications['leaveSubtype'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-autocomplete-field>
                    <!-- leave category  -->
                    <app-input-field
                        class="field"
                        [control]="leaveCategory"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_leaveCategory"
                        [inputType]="'string'"
                        [icon]="'business_center'"
                        [group]="form"
                        [hint]="(categoryHint != null ? categoryHint : data.fieldNotifications.hasOwnProperty('leaveCategory') ? data.fieldNotifications['leaveCategory'] : null) | capitalizefirst"
                        [isHintHighlighted]="categoryHint != null"
                        [isHintWarning]="categoryHint == null"
                    ></app-input-field>
                </div>
                <div class="column-container">
                    <!-- eligibility  -->
                    <app-select-field
                        [control]="eligibility"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_eligibility"
                        [options]="data.options.eligibility"
                        [icon]="'domain'"
                        [shouldCapitalize]="false"
                        [showBlankOption]="true"
                        [hint]="(data.fieldNotifications.hasOwnProperty('eligibility') ? data.fieldNotifications['eligibility'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-select-field>
                    <!-- ineligibility reason  -->
                    <app-select-field
                        [control]="ineligibilityReasonId"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_ineligibilityReason"
                        [options]="data.options.ineligibilityReasons"
                        [icon]="'assignment'"
                        [shouldCapitalize]="false"
                        [showBlankOption]="true"
                        [hint]="(data.fieldNotifications.hasOwnProperty('ineligibilityReason') ? data.fieldNotifications['ineligibilityReason'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-select-field>
                    <!-- ineligibility reason comments  -->
                    <app-input-field
                        class="field"
                        [control]="ineligibilityReasonComments"
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_ineligibilityReasonComments"
                        [inputType]="'string'"
                        [icon]="'comment'"
                        [group]="form"
                        [hint]="(data.fieldNotifications.hasOwnProperty('ineligibilityReasonComments') ? data.fieldNotifications['ineligibilityReasonComments'] : null) | capitalizefirst"
                        [isHintHighlighted]="false"
                        [isHintWarning]="true"
                    ></app-input-field>
                    <!-- intermittent freq  -->
                    <app-input-field
                        class="field"
                        [control]="intermittentFreq"   
                        [label]="data.sysText.caseLeaveInfo.caseLeaveInfo_intermittentFreq"
                        [inputType]="'string'"
                        [icon]="'calendar_today'"
                        [group]="form"
                        [hint]="(intermittentFreqHint != null ? intermittentFreqHint : data.fieldNotifications.hasOwnProperty('intermittentFreq') ? data.fieldNotifications['intermittentFreq'] : null) | capitalizefirst"
                        [isHintHighlighted]="intermittentFreqHint != null"
                        [isHintWarning]="intermittentFreqHint == null"
                    ></app-input-field>
                    <!-- related case  -->
                    <div class="select-container">
                        <mat-icon [ngClass]="{ focus: select.focused }">assignment_turned_in</mat-icon>
                        <mat-form-field color="accent">
                            <mat-label>{{ data.sysText.caseLeaveInfo.caseLeaveInfo_relatedCases | titlecase }}</mat-label>
                            <mat-select #select="matSelect" [formControl]="relatedCases" multiple disableOptionCentering panelClass="dropDownSelectMultiple">
                                <mat-select-trigger>
                                    <span *ngIf="relatedCases.value?.length >= 1">
                                        <span *ngFor="let relCase of relatedCases.value; index as i">
                                            {{relCase}}<span *ngIf="i !== relatedCases.value?.length - 1">, </span> 
                                        </span>
                                    </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let case of data.options.relatedCases" [value]="case.caseId">
                                    <span>{{ case.caseId }}</span>
                                    <span *ngIf="case.leaveType" class="caption option-extras">{{ case.leaveType }}</span>
                                    <span *ngIf="case.startDate" class="caption option-extras"><span class="bullet-spacing" *ngIf="case.leaveType">&bull;</span>{{ data.sysText.caseLeaveInfo.caseLeaveInfo_started | titlecase }} {{case.startDate | date}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="data.fieldNotifications.hasOwnProperty('relatedCases'); else hint" class="warn">
                                {{ data.fieldNotifications['relatedCases'] }}
                            </mat-hint>
                            <ng-template #hint>
                                <mat-hint *ngIf="data.options.relatedCases.length === 0">{{ data.sysText.caseLeaveInfo.caseLeaveInfo_noRelatedCases }}</mat-hint>
                            </ng-template>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</ng-container>

<ng-template #anonymousMessage>
    <mat-dialog-content class="form-container">
        <p class="disallow">{{ data.sysText.caseLeaveInfoDialog.caseLeaveInfo_anonymousMessage }}</p>
    </mat-dialog-content>
</ng-template>

<mat-dialog-actions align="end">
    <mat-error *ngIf="this.form.errors?.msError" class="mse-form-error caption">{{ this.form.errors?.msError }}</mat-error>
    <ng-container *ngIf="!(isSubmitting$ | async); else saving">
      <button
        mat-stroked-button
        class="button"
        color="accent"
        (click)="close()"
        [disabled]="isSubmitting$ | async">
        {{ !isAnonymous ? data.sysText.caseLeaveInfoDialog.cancel : data.sysText.caseLeaveInfoDialog.close }}
      </button>
      <button 
        *ngIf="!isAnonymous"
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="save()">
        {{ data.sysText.caseLeaveInfoDialog.save }}
      </button>
    </ng-container>
    <ng-template #saving>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>