import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployerProfile } from '@core/models/leave-admin/employers/employer-profile.model';
import { EmployerSettingsPost } from '@core/models/leave-admin/employers/employer-settings-post.model';
import { AvatarService, ErrorService, SnackbarService } from '@core/services';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { StiiraError } from '@core/models';
import { finalize, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-employer-settings',
  templateUrl: './employer-settings.component.html',
  styleUrls: ['./employer-settings.component.scss']
})
export class EmployerSettingsComponent implements OnInit {
  @ViewChild('imgFileInput') fileInput: ElementRef;
  @Output() isEditing = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  public logoChangedEvent: Event = null;
  public employerLogoImage: string = null;
  public setEmployerLogo: string = null;
  
  private subdomainNamePattern: string = '(^[a-zA-Z0-9][a-zA-Z0-9-]*)[a-zA-Z0-9]+$';
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get companyName(): FormControl {
    return this.form.controls.companyName as FormControl;
  }

  get subdomainName(): FormControl {
    return this.form.controls.subdomainName as FormControl;
  }

  get employerLogo(): FormControl {
    return this.form.controls.employerLogo as FormControl;
  }

  get headcount(): FormControl {
    return this.form.controls.headcount as FormControl;
  }

  get allowNewLeaves(): FormControl {
    return this.form.controls.allowNewLeaves as FormControl;
  }

  get enableAccess(): FormControl {
    return this.form.controls.enableAccess as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      erProfile: EmployerProfile;
      sysText: any;
      unsavedChangesSysText: any;
    },
    private employerSettingsdialogRef: MatDialogRef<EmployerSettingsComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private manageEmployersService: ManageEmployersService,
    private errorService: ErrorService,
    private snackbarService: SnackbarService,
    private avatarService: AvatarService
    ) { 
    this.isSaving$ = new Subject();
    this.form = this.fb.group({
      companyName: [null, Validators.required],
      subdomainName: [null, Validators.compose([Validators.pattern(this.subdomainNamePattern), Validators.minLength(2)])],
      employerLogo: [null], // holds image file name, also used for file size ms error
      headcount: [null],
      allowNewLeaves: [null],
      enableAccess: [null]
    })
}

  ngOnInit(): void {
    this.form.patchValue({
      companyName: this.data.erProfile.companyName,
      subdomainName: this.data.erProfile.customSubdomainName,
      headcount: this.data.erProfile.headcount,
      allowNewLeaves: this.data.erProfile.allowNewLeaves,
    });

    if (this.data.erProfile.showEmployerEnabled) {
      this.form.controls.enableAccess.patchValue(this.data.erProfile.employerEnabled);
    }

    if (this.data.erProfile.isTenantEmployer) {
      this.form.controls.subdomainName.setValidators(Validators.required)
      this.form.updateValueAndValidity();
    }

    if (this.data.erProfile.customEmployerLogo) {
      this.setEmployerLogo = this.data.erProfile.customEmployerLogo.image;
      this.employerLogo.setValue(this.data.erProfile.customEmployerLogo.fileName);
    }

    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.erProfile.modelStateErrors);
      this.formInitValues = { ...this.form.value };
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public logoChangeEvent(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files[0]) {
      this.employerLogo.setValue(element.files[0].name);
      this.logoChangedEvent = event;
    }
  }

  public getEmployerLogo(event: ImageCroppedEvent) {
    this.employerLogoImage = event.base64;
  }

  public loadImageFailed() {
    this.clearFile();
    this.snackbarService.open(this.data.sysText.imageLoadError, this.data.sysText.dismiss, 5000);
  }

  public clearFile(): void {
    this.logoChangedEvent = null;
    this.employerLogo.setValue(null);
    this.employerLogoImage = null;
    this.setEmployerLogo = null;
  }

  public save(): void {
    this.employerSettingsdialogRef.disableClose = true;
    const dto: EmployerSettingsPost = {
      employerId: this.data.erProfile.employerId,
      companyName: this.companyName.value,
      subdomainName: this.subdomainName.value,
      employerLogo: this.employerLogoImage ? {
          image: this.employerLogoImage, 
          fileName: this.employerLogo.value 
        } : null,
      headcount: this.headcount.value,
      employerEnabled: this.enableAccess.value,
      enableNewLeaves: this.allowNewLeaves.value
    }
      this.manageEmployersService.postEmployerSettings(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.employerSettingsdialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.employerSettingsdialogRef.close(res);
          // if employer logo was changed, trigger sidenav to update
          if (res&& this.employerLogoImage != this.data.erProfile.customEmployerLogo?.image) {
            this.avatarService.updateSidenav();
          }
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.employerSettingsdialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.employerSettingsdialogRef.close();
        }
      });
  }
}
