import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { indicate, nameof, noChangesReplacer } from '@shared/helpers';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { ConstantsService, ErrorService, LayoutService } from '@core/services';
import { AddRecertDialog, DocumentEditDialog, ModelStateErrors, PostRecert, PostRecertDocument, RecertForm, StatusChips, StiiraError } from '@core/models';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { DocumentUploadMode } from '@core/enums/document-upload-mode.enum';
import { EditDocumentComponent } from '../edit-document/edit-document.component';
import { DocumentUploadDialog } from '@core/models/leave-admin/document-upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';

@Component({
  selector: 'app-add-recert',
  templateUrl: './add-recert.component.html',
  styleUrls: ['./add-recert.component.scss'],
})
export class AddRecertComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<RecertForm>;
  public isHandheld: boolean;
  public isSaving$: Subject<boolean> = new Subject<boolean>();
  public hasDocErrors$: Subject<boolean> = new Subject<boolean>();
  public uploadDocsOpenState: boolean = true;
  public documents: PostRecertDocument[]= [];
  public _docs: MatTableDataSource<PostRecertDocument>;
  public displayedColumns: string[] = ['documentTitle', 'documentCategory', 'documentComments', 'icons'];
  public chipStyles: StatusChips;
  public isAnonymous: boolean = false;
  
  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get isMobile$(): Observable<boolean> {
    return this.layoutService.isMobile$();
  }

  set dataSource(dataSource: PostRecertDocument[]) {
    this._docs = new MatTableDataSource<PostRecertDocument>(dataSource);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      addRecertDialog: AddRecertDialog;
      caseId: number;
      sysText: any;
      unsavedChangesSysText: any;
      editDocSysText: any;
      uploadDocSysText: any;
    },
    private dialogRef: MatDialogRef<AddRecertComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private service: LeaveAdminService,
    private errorService: ErrorService,
    private constants: ConstantsService,
    private store: LeaveAdminStoreService,
  ) { 
    this.form = this.fb.group<RecertForm>({
      name: this.fb.control(null, Validators.required),
      warningDate: this.fb.control(null),
      dueDate: this.fb.control(null),
      completedDate: this.fb.control(null),
      comments: this.fb.control(null)
    });
  }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES;

    this.isAnonymous = this.store.caseDetails.employeeInformation.isAnonymous;

    this.refreshDataSource();
    this.formInitValues = { ...this.form.value };

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        if (!this.noChanges && !this.formChangeEmitted) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public onSubmit(): void {
    const dto: PostRecert = {
      caseId: this.data.caseId,
      name: this.form.controls.name.value,
      warningDate: this.form.controls.warningDate.value,
      dueDate: this.form.controls.dueDate.value,
      completedDate: this.form.controls.completedDate.value,
      comments: this.form.controls.comments.value,
      documents: this.documents
    }
    
    this.clearDocumentErrors();
    this.hasDocErrors$.next(false);

    this.service.postAddRecert(this.makeFormData(dto))
      .pipe(
        indicate(this.isSaving$),
        finalize(() => { this.dialogRef.disableClose = false; })
      )
      .subscribe(
        (res) => { this.dialogRef.close(res); },
        (err: StiiraError) => { 
          this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors);
          this.setDocumentErrors(err.modelStateErrors);
          this.hasDocErrors$.next(true);
        }
      );
  }

  public uploadDocument(): void {
    const docUploadDialog: DocumentUploadDialog = {
      categories: this.data.addRecertDialog.documentCategories
    }

    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '500px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '85vw',
      restoreFocus: false,
      data: {
        docUploadDialog: docUploadDialog,
        sysText: this.data.uploadDocSysText,
        unsavedChangesSysText: this.data.unsavedChangesSysText,
        mode: DocumentUploadMode.Recert
      }
    };

    const dialogRef = this.dialog.open(DocumentUploadComponent, dialogConfig);

    dialogRef.componentInstance.isEditing.subscribe((res) => {
      this.isEditing.emit(res);
    });

    dialogRef.afterClosed()
      .pipe(
        filter((res) => !!res),
        finalize(() => this.isEditing.emit(false))
        )
      .subscribe((res: PostRecertDocument) => {
        this.documents.push(res);
        this.refreshDataSource();
      });
  }

  public clearDocument(doc: PostRecertDocument): void {
    this.documents.splice(this.documents.indexOf(doc), 1);
    this.refreshDataSource();
  }

  public editDocument(doc: PostRecertDocument): void {
    const docEditDialog: DocumentEditDialog = {
      categories: this.data.addRecertDialog.documentCategories
    }
    
    const dialogConfig: MatDialogConfig = {
      width: this.layoutService.isHandheld ? '100vw' : '500px',
      maxWidth: this.layoutService.isHandheld ? '100vw' : '85vw',
      data: { 
        docData: doc,
        subjectId: this.data.caseId,
        options: docEditDialog,
        sysText: this.data.editDocSysText,
        unsavedSysText: this.data.unsavedChangesSysText,
        mode: DocumentUploadMode.Recert
      }
    };
    
    const dialogRef = this.dialog.open(EditDocumentComponent, dialogConfig);

    dialogRef.componentInstance.isEditing.subscribe((res) => {
      this.isEditing.emit(res);
    });

    dialogRef.afterClosed()
      .pipe(
        filter((res) => !!res),
        finalize(() => this.isEditing.emit(false))
        )
      .subscribe((res: PostRecertDocument) => {
        this.documents[this.documents.indexOf(doc)] = res;
        this.refreshDataSource();
      });
  } 

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private refreshDataSource() {
    this.dataSource = this.documents;
  }

  private makeFormData(dto: PostRecert): FormData {
    const formData = new FormData();

    formData.append(nameof<PostRecert>('caseId'), dto.caseId.toString());
    formData.append(nameof<PostRecert>('name'), dto.name);
    formData.append(nameof<PostRecert>('warningDate'), dto.warningDate ? dto.warningDate.toISOString() : null);
    formData.append(nameof<PostRecert>('dueDate'), dto.dueDate ? dto.dueDate.toISOString() : null);
    formData.append(nameof<PostRecert>('completedDate'), dto.completedDate ? dto.completedDate.toISOString() : null);
    formData.append(nameof<PostRecert>('comments'), dto.comments);

    this.documents.forEach((document, index) => {
      formData.append(`${nameof<PostRecert>('documents')}.${nameof<PostRecertDocument>('fileKey')}`, index.toString());

      if (document.document != null)
        formData.append(`${nameof<PostRecert>('documents')}.${index}.${nameof<PostRecertDocument>('document')}`, document.document, document.document.name);
    
      formData.append(`${nameof<PostRecert>('documents')}.${index}.${nameof<PostRecertDocument>('fileKey')}`, index.toString());
      formData.append(`${nameof<PostRecert>('documents')}.${index}.${nameof<PostRecertDocument>('documentTitle')}`, document.documentTitle);
      formData.append(`${nameof<PostRecert>('documents')}.${index}.${nameof<PostRecertDocument>('documentCategoryId')}`, document.documentCategoryId?.toString());
      formData.append(`${nameof<PostRecert>('documents')}.${index}.${nameof<PostRecertDocument>('documentComments')}`, document.documentComments);
    });

    return formData;
  }

  public docHasError(document: PostRecertDocument): boolean {
    var noErrors = document.modelStateErrors == null;

    return !noErrors;
  }

  public docErrorMessage(document: PostRecertDocument): string {
    if (document.modelStateErrors == null)
      return null;

    var docErrors = Object.values(document.modelStateErrors).join("; ");

    return docErrors;
  }

  private clearDocumentErrors(): void {
    if (this.documents == null)
      return;

    this.documents.forEach((document) => {
      document.modelStateErrors = null;
    });
  }

  private setDocumentErrors(modelStateErrors: ModelStateErrors): void {

    if (modelStateErrors == null)
      return;

    if (this.documents == null)
      return;

    Object.keys(modelStateErrors).forEach((key) => {
      if (key.toLowerCase().startsWith(`${nameof<PostRecert>('documents')}.`)) {
        var docNum = key.split(".")[1];

        if (this.documents[docNum].modelStateErrors == null)
          this.documents[docNum].modelStateErrors = { };

        this.documents[docNum].modelStateErrors[key] = modelStateErrors[key];
      };
    });
  }
}
