<ng-template #loadingTemplate>
  <div class="spinner-container">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</ng-template>

<ng-container *observe="sysText$ as sysText; before: loadingTemplate">
  <h6 class="dialog--title">{{ sysText.idleAlertDialog_title | titlecase }}</h6>
  <mat-dialog-content>
    <p class="subtitle1">
      {{ sysText.idleAlertDialog_subtitle1 | capitalizefirst }}
      <span class="semibold">{{ data.timeRemaining }} {{ data.timeRemaining === 1 ? 'second' : 'seconds' }}.</span>
    </p>
    <p class="subtitle1">
      {{ sysText.idleAlertDialog_subtitle2 | capitalizefirst }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button class="button" color="accent" [mat-dialog-close]="false">{{ sysText.noLogOut }}</button>
    <button mat-stroked-button class="button" color="accent" [mat-dialog-close]="true">
      {{ sysText.yesContinue }}
    </button>
  </mat-dialog-actions>
</ng-container>
