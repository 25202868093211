import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';
import { LayoutService } from '@core/services';

@Component({
  selector: 'app-calendar-update-confirmation',
  templateUrl: './calendar-update-confirmation.component.html',
  styleUrls: ['./calendar-update-confirmation.component.scss']
})
export class CalendarUpdateConfirmationComponent implements OnInit {

  public CalendarWarnMessages = CalendarWarnMessages;

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: { sysText: any, warnMessages: CalendarWarnMessages[] }, 

    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<CalendarUpdateConfirmationComponent>,
    ) {}

  ngOnInit(): void {}

  public get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
