import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { TemplateInfoDialog, TemplateInfoDialogPost, TemplateInfoForm } from '@core/models/case-communication/template-info-dialog.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { ErrorService } from '@core/services';
import { indicate, noChangesReplacer } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { Validators } from '@angular/forms';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';

@Component({
  selector: 'app-edit-template-info',
  templateUrl: './edit-template-info.component.html',
  styleUrls: ['./edit-template-info.component.scss']
})
export class EditTemplateInfoComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  
  public form: FormGroup<TemplateInfoForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    let curValues = JSON.stringify(this.form.value, noChangesReplacer);
    let initValues = JSON.stringify(this.formInitValues, noChangesReplacer);
    return curValues === initValues;
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      templateDetails: TemplateDetails; 
      options: TemplateInfoDialog;
      sysText: any; 
      unsavedChangesSysText: any
    },
    private templateInfoDialogRef: MatDialogRef<EditTemplateInfoComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private caseCommunicationService: CaseCommunicationService,
    private store: TemplateDetailsStoreService,
    private errorService: ErrorService
    ) { 
    this.isSaving$ = new Subject();
    this.form = this.fb.group({
      templateName: this.fb.control(null, Validators.required),
      emailType: [null],
      ownerEmployerId: [null],
      allowUse: [null]
    })
  }

  ngOnInit(): void {
    this.form.patchValue({
      templateName: this.data.templateDetails.name,
      emailType: this.data.templateDetails.emailType,
      ownerEmployerId: this.data.templateDetails.ownerEmployerId,
      allowUse: this.data.templateDetails.allowUse
    });

    // currently, the only template type that users can create are "case from employee"
    // all other types only occur with system templates
    // so there is currently no use case that allows a user to set the type 
    this.form.controls.emailType.disable();

    if (!this.data.options.canEditName) {
      this.form.controls.templateName.disable();
    }

    if (!this.data.options.canEditOwner) {
      this.form.controls.ownerEmployerId.disable();
    }

    if (!this.data.options.canEditAllowUse) {
      this.form.controls.ownerEmployerId.disable();
    }

    this.formInitValues = { ...this.form.value };

    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.templateDetails.missingDetails)
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },0);
  }

  public save(): void {
    this.templateInfoDialogRef.disableClose = true;
    const dto: TemplateInfoDialogPost = {
      templateId: this.data.templateDetails.id,
      templateName: this.form.controls.templateName.value,
      ownerEmployerId: this.form.controls.ownerEmployerId.value,
      allowUse: this.form.controls.allowUse.value
    }
      this.caseCommunicationService.postTemplateInfo(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.templateInfoDialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.store.templateDetails = res;
          this.templateInfoDialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.templateInfoDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.templateInfoDialogRef.close();
        }
      });
  }
}
