import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EmailPreview, 
  SendAttachment, 
  PostSendNewEmail, 
  Recipients, 
  RecipientsFormGroup, 
  SendNewEmailDialog, 
  SendNewEmailForm } from '@core/models/case-communication/send-new-email-dialog.model';
import { ConstantsService, ErrorService, LayoutService, SnackbarService } from '@core/services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ViewEmailComponent } from '../view-email/view-email.component';
import { CaseEmailInfo, EmailAttachment, Recipient } from '@core/models/case-communication/case-communication-drawer.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { indicate, insertPlaceholderChips, nameof, removePlaceholderChips } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { EmailSubjectEditorComponent } from '@modules/case-communication/components/email-subject-editor/email-subject-editor.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { EmailBodyEditorComponent } from '@modules/case-communication/components/email-body-editor/email-body-editor.component';
import { CaseCommunicationStoreService } from '@core/services/case-communication.store.service';
import { RecipientType } from '@modules/case-communication/components/add-recipients/add-recipients.component';

@Component({
  selector: 'app-send-new-email',
  templateUrl: './send-new-email.component.html',
  styleUrls: ['./send-new-email.component.scss'],
})
export class SendNewEmailComponent implements OnInit {
  public form: FormGroup<SendNewEmailForm>;
  public recipientsOpenState: boolean = true;
  public addCcOpenState: boolean = false;
  public addBccOpenState: boolean = false;
  public emailContentOpenState: boolean = true;
  public attachDocsOpenState: boolean = true;
  public attachments: SendAttachment[] = [];
  public isPreviewLoading$: Subject<boolean> = new Subject<boolean>();
  public isSending$: Subject<boolean>  = new Subject();
  public templateIsLoading$: Subject<boolean> = new Subject<boolean>();
  public recipientTypes = RecipientType;

  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(EmailSubjectEditorComponent) subjectEditor: EmailSubjectEditorComponent;
  @ViewChild(EmailBodyEditorComponent) bodyEditor: EmailBodyEditorComponent;

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogData: SendNewEmailDialog,
      sysText: any,
      viewEmailSysText: any
      unsavedSysText: any
    },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<SendNewEmailComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private service: CaseCommunicationService,
    private ccStore: CaseCommunicationStoreService,
    private errorService: ErrorService,
    private snackbar: SnackbarService,
    private laStoreService: LeaveAdminStoreService,
    private constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSend():void {
    this.service.postSendNewEmail(this.makePostSendNewEmailFormData(this.makePostEmailData()))
      .pipe(indicate(this.isSending$))
      .subscribe((res) => {
        if (res) {
          this.ccStore.caseComDrawer = res;
          this.dialogRef.close();
          const snackText =  this.data.sysText.sendNewEmailDialog_emailSuccess;
          this.snackbar.open(snackText, this.data.sysText.dismiss);
        }
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  public close(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public onPreview():void {
    // to get the subject in text form, start with the html, 
    // perform the chip replacement, set the content to the 
    // modified content, get the text, then restore the 
    // original html 

    let subjectHtml = this.subjectEditor.subEditor.editor.getContent();
    let subjectChipless = removePlaceholderChips(subjectHtml, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions);
    this.subjectEditor.subEditor.editor.setContent(subjectChipless);
    let subjectText = this.subjectEditor.getEditorText();
    this.subjectEditor.subEditor.editor.setContent(subjectHtml);

    let bodyHtml = this.bodyEditor.bodyEditor.editor.getContent();
    let bodyChipless = removePlaceholderChips(bodyHtml, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions);

    const dto: EmailPreview = {
      caseId: this.laStoreService.caseDetails.leaveInformation.caseId,
      subject: subjectText,
      bodyHtml: bodyChipless
    }
    this.service.getEmailPreview(dto)
      .pipe(indicate(this.isSending$))
      .subscribe(res => {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '600px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          disableClose: false,
          closeOnNavigation: true,
          data: { 
            sysText: this.data.viewEmailSysText,
            emailData: this.makePreviewEmailData(res),
            isPreview: true
          }
        };
        
        this.dialog.open(ViewEmailComponent, dialogConfig);
    })
  }

  public handleFileInput(files: FileList): void {
    this.attachments.push({isTemplateFile: false, file: files.item(0)});
  }

  public clearFile(file: File): void {
    this.attachments = this.attachments.filter(a => a.file !== file);
  }

  private setupForm(): void{
    this.form = this.fb.group({
      templates: [],
      to: this.initNewRecipientGroup(),
      cc: this.initNewRecipientGroup(this.data.dialogData.automaticCcAddresses),
      bcc: this.initNewRecipientGroup(this.data.dialogData.automaticBccAddresses),
      subject: [""],
      body: [""],
    });

    this.form.controls.templates.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(templateId => {
        this.handleSelectTemplate(templateId);
      });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        if (!this.ccStore.ccIsEditing && !this.noChanges) {
          this.ccStore.setCcIsEditing = true;
        } else if (this.noChanges) {
          this.ccStore.setCcIsEditing = false;
        }
      });

    this.formInitValues = JSON.parse(JSON.stringify(this.form.value));
  }

  private initNewRecipientGroup(autoAddresses?: string[]): FormGroup {
    return this.fb.group<RecipientsFormGroup>({
      relevantContacts: new FormControl<number[]>([]),
      allContactAdd: new FormControl<number>(null),
      allContacts: new FormControl<number[]>([]),
      additionalEmailAddresseAdd: new FormControl<string>(null),
      additionalEmailAddresses: new FormControl<string[]>(autoAddresses ?? [])
    });
  }

  private makePreviewEmailData(replacedData: EmailPreview): CaseEmailInfo {
    const attachments: EmailAttachment[] = this.attachments.map(a => {
      return {
        attachmentId: a.attachmentId,
        attachmentTitle: a.file.name,
        isDocument: a.isTemplateFile,
        disableDownload: !a.isTemplateFile
      }
    });
    return {
      sender: this.data.dialogData.sender,
      recipients: this.makePreviewRecipients(this.form.controls.to),
      subject: replacedData.subject,
      cc: this.makePreviewRecipients(this.form.controls.cc),
      bcc: this.makePreviewRecipients(this.form.controls.bcc),
      bodyHtml: replacedData.bodyHtml,
      sendDateTime: null,
      attachments: attachments
    }
  }

  private makePreviewRecipients(fg: FormGroup<RecipientsFormGroup>): Recipient[] {
    const relCons =  fg.controls.relevantContacts.value?.map(rcid => {
      const rc = this.data.dialogData.relevantContactOptions.find(rco => rcid === rco.id);
      return { name: rc.description, email: rc.explicitEmailAddress };
    });
    const allCons =  fg.controls.allContacts.value?.map(ocid => {
      const ac = this.data.dialogData.allContactOptions.find(oco => ocid === oco.id);
      return { name: ac.description, email: ac.explicitEmailAddress };
    });
    const addEmails =  fg.controls.additionalEmailAddresses.value?.map(ae => {
      return { name: null, email: ae };
    });
    return [...relCons, ...allCons, ...addEmails];
  }

  private makePostEmailData(): PostSendNewEmail {
    // to get the subject and body in text form, start with 
    // the html, perform the chip replacement, set the content 
    // to the modified content, get the text, then restore the 
    // original html 

    let subjectHtml = this.subjectEditor.subEditor.editor.getContent();
    let subjectChipless = removePlaceholderChips(subjectHtml, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions);
    this.subjectEditor.subEditor.editor.setContent(subjectChipless);
    let subjectText = this.subjectEditor.getEditorText();
    this.subjectEditor.subEditor.editor.setContent(subjectHtml);

    let bodyHtml = this.bodyEditor.bodyEditor.editor.getContent();
    let bodyChipless = removePlaceholderChips(bodyHtml, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions);
    this.bodyEditor.bodyEditor.editor.setContent(bodyChipless);
    let bodyText = this.bodyEditor.getEditorText();
    this.bodyEditor.bodyEditor.editor.setContent(bodyHtml);

    return {
      caseId: this.laStoreService.caseDetails.leaveInformation.caseId,
      to: this.makePostRecipients(this.form.controls.to),
      subject: subjectText,
      cc: this.makePostRecipients(this.form.controls.cc),
      bcc: this.makePostRecipients(this.form.controls.bcc),
      bodyText: bodyText,
      body: bodyChipless,
      templateAttachmentIds: this.attachments.filter(a => a.isTemplateFile).map(a => a.attachmentId),
      userAttachmentFiles: this.attachments.filter(a => !a.isTemplateFile).map(a => a.file)
    }
  }

  private makePostRecipients(fg: FormGroup<RecipientsFormGroup>): Recipients {
    return {
      contacts: [...fg.controls.relevantContacts.value, ...fg.controls.allContacts.value],
      emailAddresses: fg.controls.additionalEmailAddresses.value
    }
  }

  private makePostSendNewEmailFormData( dto: PostSendNewEmail): FormData {
    const formData = new FormData();
    formData.append(nameof<PostSendNewEmail>('caseId'), dto.caseId.toString());
    dto.to.contacts.forEach(tc => {
      formData.append(`${nameof<PostSendNewEmail>('to')}.${nameof<Recipients>('contacts')}`, tc.toString());
    });
    dto.to.emailAddresses.forEach(tea => {
      formData.append(`${nameof<PostSendNewEmail>('to')}.${nameof<Recipients>('emailAddresses')}`, tea);
    });
    formData.append(nameof<PostSendNewEmail>('subject'), dto.subject);
    dto.cc.contacts.forEach(ccc => {
      formData.append(`${nameof<PostSendNewEmail>('cc')}.${nameof<Recipients>('contacts')}`, ccc.toString());
    });
    dto.cc.emailAddresses.forEach(ccea => {
      formData.append(`${nameof<PostSendNewEmail>('cc')}.${nameof<Recipients>('emailAddresses')}`, ccea);
    });
    dto.bcc.contacts.forEach(bccc => {
      formData.append(`${nameof<PostSendNewEmail>('bcc')}.${nameof<Recipients>('contacts')}`, bccc.toString());
    });
    dto.bcc.emailAddresses.forEach(bccea => {
      formData.append(`${nameof<PostSendNewEmail>('bcc')}.${nameof<Recipients>('emailAddresses')}`, bccea);
    });
    formData.append(nameof<PostSendNewEmail>('bodyText'), dto.bodyText);
    formData.append(nameof<PostSendNewEmail>('body'), btoa(dto.body)); // base 64 encode to avoid ASP.Net request validation
    dto.templateAttachmentIds.forEach(ta => {
      formData.append(nameof<PostSendNewEmail>('templateAttachmentIds'), ta.toString());
    });
    dto.userAttachmentFiles.forEach(ua => {
      formData.append(ua.name, ua);
    });
    return formData;
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedSysText,
    };

    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private handleSelectTemplate(templateId: number): void {
    if (templateId) {
      this.form.controls.templates.disable({emitEvent: false});
      this.service.getSendNewEmailTemplateDetails(
        {
          caseId: this.laStoreService.caseDetails.leaveInformation.caseId, 
          templateId: templateId
        })
        .pipe(
          indicate(this.templateIsLoading$),
          finalize(()=>{this.form.controls.templates.enable({emitEvent: false})}))
        .subscribe((res)=>{
          this.form.controls.subject
            .patchValue(insertPlaceholderChips(res.subjectText, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions));
          this.form.controls.body
            .patchValue(insertPlaceholderChips(res.bodyHtml, this.constants.EDITOR_CHIP, this.data.dialogData.placeholderOptions));
            
          this.attachments = [];
          res.attachments?.forEach(a => {
            const tempAttach= new File([""] , a.attachmentTitle);
            this.attachments.push({isTemplateFile: true, file: tempAttach, attachmentId: a.attachmentId});
          });
        });
    } else {
      this.form.controls.subject.patchValue(null);
      this.form.controls.body.patchValue(null);
      this.attachments = [];
    }
  }
}
