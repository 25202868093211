<div class="dialog--title">
  <h6>
    {{ data.sysText.addEmployee | titlecase }}
  </h6>
</div>
<mat-dialog-content>
  <p class="body2">{{ data.sysText.addEmployeeDesc | capitalizefirst }}</p>
  <div class="input-field">
    <app-autocomplete-field
      [icon]="'person_add'"
      [label]="data.sysText.searchEmployees"
      [control]="selectedEmployee"
      [options]="data.options"
      [showSearchIcon]="true"
      [showImage]="true"
    >
    </app-autocomplete-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <button mat-stroked-button class="button" mat-dialog-close color="accent">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" [disabled]="!selectedEmployee.valid" (click)="onSubmit()">
    {{ data.sysText.save }}
  </button>
</mat-dialog-actions>

<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
