import { Injectable } from '@angular/core';
import { EmailTemplatesDashboardStore } from '@core/models/case-communication/email-templates-dashboard-store.model';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesDashboardStoreService {

  private readonly _initialStoreData: EmailTemplatesDashboardStore = {
    emailTemplates: null,
    sysText: null
  };

  private emailTemplatesDashboardStore: BehaviorSubject<EmailTemplatesDashboardStore>;  

  private store$: Observable<EmailTemplatesDashboardStore>;

  private readonly templateNames = {
    emailTemplates: "emailTemplates",
    unsavedChanges: "unsavedChanges",
    addNewTemplate: "addNewTemplate",
    templateDocCategories: "templateDocCategories"
  };

  constructor() { 
    this.emailTemplatesDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.emailTemplatesDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.emailTemplatesDashboardStore.value;
  }
  
  public get emailTemplates$(): Observable<EmailTemplates> {
    return this.store$.pipe(pluck('emailTemplates'));
  }

  public get emailTemplates() {
    return this.store.emailTemplates;
  }

  public set emailTemplates(emailTemplates: EmailTemplates) {
    const store = { ...this.store, emailTemplates };
    this.emailTemplatesDashboardStore.next(store);
  }
  
  public get sysText() {
    return this.store.sysText;
  }
  
  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.emailTemplatesDashboardStore.next(store);
  }

  public emailTemplateAssignedToEmployer(templateId: number, assigned: boolean) {
    this.store.emailTemplates.templateItems.find(et => et.id === templateId).assignedToEmployer = assigned;
  }

  public unloadStore() {
    const store: EmailTemplatesDashboardStore = {
      emailTemplates: null,
      sysText: null
    }
    this.emailTemplatesDashboardStore.next(store)
  }
}
