<div class="dialog--title">
    <h6 >
        {{data.sysText.editWorkdays_title | titlecase}}
    </h6>
    <ng-container *ngIf="isHandheld">
      <span class="spacer"></span>
      <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
    </ng-container>
</div>

<mat-dialog-content>
    <form *ngIf="!isAnonymous; else anonymousMessage" [formGroup]="form" (ngSubmit)="onSubmit()">

        <p class="body2">{{data.sysText.editWorkdays_subtitle | capitalizefirst}}:</p>
        <mat-error>{{ form.controls.sunday.errors?.msError }}</mat-error>
        <div class="form-fields">
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.sunday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.sunday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.monday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.monday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.tuesday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.tuesday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.wednesday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.wednesday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.thursday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.thursday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.friday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.friday | titlecase}}</p>
            </div>
            <div class="checkbox">
                <mat-checkbox
                    [formControl]="form.controls.saturday"
                ></mat-checkbox>
                <p class="body2">{{this.data.sysText.saturday | titlecase}}</p>
            </div>
        </div>
    </form>

    <ng-template #anonymousMessage>
        <p>{{ data.sysText.editWorkdays_anonymousMessage }}</p>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="actions" *ngIf="!(isSubmitting$ | async); else submitting">
    <mat-error *ngIf="this.form.errors?.msError" class="mse-form-error caption">{{ this.form.errors?.msError }}</mat-error>
    <button mat-stroked-button class="button" color="accent" (click)="close(false)">
      {{this.data.caseDetails.leaveCalendar.workdays != null ? data.sysText.cancel : data.sysText.close}}
    </button>
    <button mat-stroked-button class="button" color="accent" *ngIf="this.data.caseDetails.leaveCalendar.workdays != null"
      [disabled]="(isSubmitting$ | async)" (click)="onSubmit()">
      {{data.sysText.save}}
    </button>
</mat-dialog-actions>

<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </button>
    </mat-dialog-actions>
</ng-template>