<div class="dialog--title">
  <h6 class="grey1">{{ data.sysText.editEmployeeHeader | titlecase }}</h6>
  <ng-container *ngIf="isHandheld">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content class="edit-employee-content">
  <form [formGroup]="eeForm" (ngSubmit)="save()">
    <!-- Basic -->
    <div class="section-header-container">
      <p class="overline grey1 semibold section-title">{{ data.sysText.basicInfo }}</p>
      <mat-divider class="section-header-underline"></mat-divider>
    </div>
    <div [ngClass]="isHandheld ? 'main-container' : 'main-container flex'">
      <div [ngClass]="isHandheld ? 'column-container' : 'column-container width-46'">
        <app-input-field 
          [label]="data.sysText.caseEmployeeInfo_firstName" 
          [control]="eeForm.controls.firstName" 
          [group]="eeForm"
          [hideIconSpace]="true"
        ></app-input-field>
        <div class="last-suffix-container">
          <app-input-field 
            class="last-name"
            [label]="data.sysText.caseEmployeeInfo_lastName"
            [control]="eeForm.controls.lastName" 
            [group]="eeForm"
            [hideIconSpace]="true"
          ></app-input-field>
          <app-input-field 
            class="suffix"
            [label]="data.sysText.caseEmployeeInfo_suffix"
            [control]="eeForm.controls.suffix" 
            [group]="eeForm"    
            [hideIconSpace]="true"
          ></app-input-field>
        </div>
      </div>  
      <div [ngClass]="isHandheld ? 'column-container' : 'column-container width-46'">
        <app-input-field 
          [label]="data.sysText.caseEmployeeInfo_middleName" 
          [control]="eeForm.controls.middleName" 
          [group]="eeForm"
          [hideIconSpace]="true"
        ></app-input-field>
        <app-input-field 
          [label]="data.sysText.prefferedFirstName" 
          [control]="eeForm.controls.preferredFirstName" 
          [group]="eeForm"    
          [hideIconSpace]="true"
        ></app-input-field>
      </div>
    </div>

    <!-- Contact Info -->
    <div class="section-header-container">
      <p class="overline grey1 semibold section-title">{{ data.sysText.contactInfo }}</p>
      <mat-divider class="section-header-underline"></mat-divider>
    </div>
    <div class="column-container">
      <app-email-field-group
        [group]="eeForm"
        [workEmailControl]="eeForm.controls.workEmail"
        [isWorkEmailPreferredControl]="eeForm.controls.workEmailChecked"
        [personalEmailControl]="eeForm.controls.personalEmail"
        [isPersonalEmailPreferredControl]="eeForm.controls.personalEmailChecked">
      </app-email-field-group>
    </div>
    <div [ngClass]="isHandheld ? 'main-container' : 'main-container flex'">
      <div [ngClass]="isHandheld ? 'column-container' : 'column-container width-46'">
        <app-input-field
          [group]="eeForm"
          [control]="eeForm.controls.workPhone"
          [icon]="'phone'"
          [label]="data.sysText.workPhone"
          [inputType]="'string'"
        ></app-input-field>
      </div>
      <div [ngClass]="isHandheld ? 'column-container' : 'column-container width-46'">
        <app-input-field
          [group]="eeForm"
          [control]="eeForm.controls.personalPhone"
          [label]="data.sysText.personalPhone"
        ></app-input-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else saving">
  <mat-error *ngIf="this.eeForm.errors?.msError" class="mse-form-error caption">{{ this.eeForm.errors?.msError }}</mat-error>
  <button mat-stroked-button class="button" color="accent" (click)="close()">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" (click)="save()">
    {{ data.sysText.save }}
  </button>
</mat-dialog-actions>

<ng-template #saving>
  <mat-dialog-actions align="end">
    <mat-spinner [diameter]="30" color="accent"></mat-spinner>
  </mat-dialog-actions>
</ng-template>
