<ng-container [formGroup]="group" *ngIf="group">
    <ng-container *ngTemplateOutlet="formField"></ng-container>
  </ng-container>
  
  <ng-container *ngIf="!group">
    <ng-container *ngTemplateOutlet="formField"></ng-container>
  </ng-container>
  
  <ng-template #formField>
    <div class="container">
      <div class="toggle-container">
        <mat-icon *ngIf="icon != null">{{ icon }}</mat-icon>
        <mat-slide-toggle #toggle="matSlideToggle" checked="checked" [formControl]="control" [disabled]="isDisabled"></mat-slide-toggle>
        <p class="subtitle2"> {{ label | capitalizefirst }}</p>
      </div>
        <mat-hint *ngIf="hint" class="hint-error" [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
        <mat-error *ngIf="control.errors?.required" class="hint-error" >{{ sysText.required | capitalizefirst }}</mat-error>
        <mat-error *ngIf="control.errors?.msError" class="hint-error">{{ control.errors?.msError }}</mat-error>
    </div>
  </ng-template>
