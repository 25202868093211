<div class="dialog--title">
  <h6 class="grey1">{{ data.sysText.reopenCase.reopenCase | titlecase }}</h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content class="mat-dialog-content">
  <p class="body2 bottom-margin">{{ data.sysText.reopenCase.reopenCaseMessage1 }}</p>
  <p class="body2 bottom-margin">{{ data.sysText.reopenCase.reopenCaseMessage2 }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="data.rcdata.caseHasEmployee">
    <app-slide-toggle
      [control]="form.controls.notifyEmployee"
      [isDisabled]="(isSaving$ | async)"
      [label]="data.sysText.reopenCase.reopenCase_notifyEmployee">
    </app-slide-toggle>
  </ng-container>
  <ng-container *ngIf="!(isSaving$ | async); else saving">
    <div>
      <button
        mat-stroked-button
        class="button"
        color="accent"
        (click)="cancel()"
        [disabled]="isSaving$ | async"
        *ngIf="!isMobile">
        {{ data.sysText.reopenCase.cancel }}
      </button>
      <button 
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="reopenCase()" 
        [disabled]="!formIsValid || (isSaving$ | async)">
        {{ data.sysText.reopenCase.reopenCase }}
      </button>
    </div>
  </ng-container>
  <ng-template #saving>
      <button mat-button class="button" type="button" color="accent" disabled>
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </button>
  </ng-template>
</mat-dialog-actions>

