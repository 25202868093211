import { Component, DoCheck, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeDetails, EmployeeDetailsPost, StiiraError } from '@core/models';
import { AvatarService, EmployeeFormService, ErrorService, LayoutService, SnackbarService } from '@core/services';
import { ProfileService } from '@core/services/profile.service';
import { indicate } from '@shared/helpers/rxjs.helpers';
import { BehaviorSubject, noop, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss'],
  providers: [EmployeeFormService],
})
export class EditEmployeeComponent implements OnInit, DoCheck, OnDestroy {
  @Output() editEmployeeHasChanges = new EventEmitter<boolean>();

  public isSaving$: Observable<boolean>;
  public isHandheld: boolean;

  private formChangeEmitted = false;
  private destroy$: Subject<void> = new Subject<void>();
  private isSavingSubject = new BehaviorSubject<boolean>(false);

  get eeForm(): FormGroup {
    return this.eeFormService.form;
  } 
  
  get noChanges(): boolean {
    return JSON.stringify(this.eeForm.value) === JSON.stringify(this.eeFormService.formInitValues);
  }
  

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { eeData: EmployeeDetails; sysText: any },
    private profileService: ProfileService,
    private eeFormService: EmployeeFormService,
    private layoutService: LayoutService,
    private editEmployeeDialogRef: MatDialogRef<EditEmployeeComponent>,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private snackbarService: SnackbarService,
    private avatarService: AvatarService
  ) {
    this.isSaving$ = this.isSavingSubject.asObservable();
  }

  ngOnInit() {
    this.isHandheld = this.layoutService.isHandheld;
    this.eeFormService.loadFormForEdit(this.data.eeData);

    this.editEmployeeDialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.isSavingSubject.value ? noop : this.close()));
  }

  ngDoCheck() {
    if (!this.formChangeEmitted) {
      if (!this.noChanges) 
      {
        this.editEmployeeHasChanges.emit(true);
        this.formChangeEmitted = true;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }  

  public save(): void {
    const details: EmployeeDetailsPost = this.eeFormService.createEmployeeDetailsDto();
    this.profileService
      .updateEmployeeDetails(details)
      .pipe(
        indicate(this.isSavingSubject)
      )
      .subscribe(
        (res) => (
          this.profileService.updateCachedEmployeeDetails(res),
          this.avatarService.updateName(res.firstName + " " + res.lastName),
          this.snackbarService.open('Employee details updated', 'Dismiss'),
          this.editEmployeeDialogRef.close(details)
        ),
        (err: StiiraError) => this.errorService.setFormModelStateErrors(this.eeForm, err.modelStateErrors)
      );
  }  

  close(): void {
    if (this.noChanges) {
      this.editEmployeeDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.editEmployeeDialogRef.close();
        }
      });
  }
}
