<div class="dialog--title">
  <h6>
      {{ data.sysText.confirmation | titlecase }} 
  </h6>
  <ng-container *ngIf="isHandheld">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="subtitle1">
    <p class="body2 dialog-section">{{ data.sysText.leaveCalendarUpdate_title }}</p>
  </div>

  <div>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.EmployeeCasesImpacted)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_employeeCasesImpacted }}
    </p>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.EmployeeCasesInaccessible)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_employeeCasesInaccessible }}
    </p>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.ThisCaseImpacted)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_thisCaseImpacted }}
    </p>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.ThisCaseInaccessible)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_thisCaseInaccessible }}
    </p>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.LeavesOutsideCaseTimeframe)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_leavesOutsideCaseTimeframe }}
    </p>
    <p class="body2" *ngIf=" data.warnMessages.includes(CalendarWarnMessages.LeavesOnNonWorkdays)">
      <span class="bullet-space">&bull;</span>{{ data.sysText.leaveCalendarUpdate_leavesOnNonWorkdays }}
    </p>
  </div>

  <div class="subtitle1">
    <p class="body2 dialog-section">{{ data.sysText.leaveCalendarUpdate_subtitle_2 }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isHandheld" class="actions" align="end">
  <button mat-stroked-button class="button" [mat-dialog-close]="false" color="accent">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" [mat-dialog-close]="true" color="accent">
    {{ data.sysText.proceed }}
  </button>
</mat-dialog-actions>
