import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MergeDuplicatesEmployee } from '@core/models/leave-admin/employees/merge-duplicates-data.model';
import { MergeDuplicatesForm } from '@core/models/leave-admin/employees/merge-duplicates-form.model';
import { ErrorService, LayoutService } from '@core/services';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';
import { CalendarUpdateConfirmationComponent } from '../calendar-update-confirmation/calendar-update-confirmation.component';
import { EmployeeCalendarRequiredValues, showEmployeeCalendarWarning } from '@shared/helpers/calendar-warn.helpers';
import { StiiraError } from '@core/models';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-review-merge',
  templateUrl: './review-merge.component.html',
  styleUrls: ['./review-merge.component.scss']
})
export class ReviewMergeComponent implements OnInit {
  public form: FormGroup;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      employeeInfo: MergeDuplicatesEmployee; 
      dto: MergeDuplicatesForm,
      sysText: any; 
      calendarUpdateConfSysText: any;
    },
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<ReviewMergeComponent>,
    private service: ManageEmployeesService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private fb: FormBuilder,
  ) { 
    //empty form in order to display form errors
    this.form = this.fb.group({})
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    // todo: need a form in order to process model state errors
    const eeReqVals: EmployeeCalendarRequiredValues = {
      newLeaveYearFixedStart: this.data.employeeInfo.fixedLeaveYearStart,
      prevLeaveYearFixedStart: this.data.employeeInfo.prevFixedLeaveYearStart,

      newHireDate: this.data.employeeInfo.employeeHireDate,
      prevHireDate: this.data.employeeInfo.prevEmployeeHireDate,
      newHoursPerWeek: this.data.employeeInfo.hoursPerWeek,
      prevHoursPerWeek: this.data.employeeInfo.prevHoursPerWeek,
      newWorkdays: null,
      prevWorkdays: null,

      newFmlaTotalHours: this.data.employeeInfo.fmlaHours.totalHours,
      prevFmlaTotalHours: this.data.employeeInfo.prevFmlaTotalHours,

      newStateTotalHours: this.data.employeeInfo.stateHours.totalHours,
      prevStateTotalHours: this.data.employeeInfo.prevStateTotalHours,

      newPloTotalHours: this.data.employeeInfo.ploHours.totalHours,
      prevPloTotalHours: this.data.employeeInfo.prevPloTotalHours,
    };

    const showCalendarWarningMessage: CalendarWarnMessages[] = showEmployeeCalendarWarning(eeReqVals);

    if (showCalendarWarningMessage) {
      const dialogConfig: MatDialogConfig = {
        disableClose: false,
        closeOnNavigation: true,
        data: {
          sysText: this.data.calendarUpdateConfSysText, 
          warnMessages: showCalendarWarningMessage
        }
      }
      
      this.dialog.open(CalendarUpdateConfirmationComponent, dialogConfig)
        .beforeClosed().subscribe((res: boolean) => {
          if (res) {
            this.postSaveInfo();
          }
        });
    } else {
      this.postSaveInfo();
    }
  }

  private postSaveInfo(): void {
    this.service.postConfirmMerge(this.data.dto)
    .pipe(indicate(this.isSubmitting$))
    .subscribe(res => {
      this.dialogRef.close(res)
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }
}
