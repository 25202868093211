import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeaveCaseDocument, PostRecertDocument, StiiraError } from '@core/models';
import { DocumentEditDialog, PostDocumentEdit, PostDocumentEditForm, PostEmployeeDocumentEdit, PostEmployerDocumentEdit, PostTemplateDocumentEdit } from '@core/models/leave-admin/document-edit.model';
import { ErrorService, LayoutService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { indicate, nameof, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { DocumentUploadMode } from '@core/enums/document-upload-mode.enum';
import { EmployerProfileStoreService } from '@core/services/employer-profile-store.service';
import { EmployeeRecordStoreService } from '@core/services/employee-record-store.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { EmployerDocument } from '@core/models/leave-admin/employers/employer-profile.model';
import { EmployeeDocument } from '@core/models/leave-admin/employees/employee-record.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { TemplateDocument } from '@core/models/case-communication/template-details.model';
import { takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();

  public form: FormGroup<PostDocumentEditForm>;
  public isSaving$: Subject<boolean> = new Subject<boolean>();
  public docUploadMode = DocumentUploadMode;
  public currentDocumentId: number;

  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  get documentTitle(): FormControl<string> {
    return this.form.get(nameof<PostDocumentEditForm>('documentTitle')) as FormControl<string>;
  }

  get documentCategoryId(): FormControl<number> {
    return this.form.get(nameof<PostDocumentEditForm>('documentCategoryId')) as FormControl<number>;
  }

  get documentComments(): FormControl<string> {
    return this.form.get(nameof<PostDocumentEditForm>('documentComments')) as FormControl<string>;
  }

  get associatedCase(): FormControl<number> {
    return this.form.get(nameof<PostDocumentEditForm>('associatedCase')) as FormControl<number>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      docData: LeaveCaseDocument | EmployerDocument | EmployeeDocument | TemplateDocument | PostRecertDocument,
      subjectId: number,
      associatedCaseId: number,
      options: DocumentEditDialog,
      sysText: any, 
      unsavedSysText: any,
      mode: DocumentUploadMode },
    private fb: FormBuilder,
    private editDocumentDialogComponent: MatDialogRef<EditDocumentComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private leaveService: LeaveAdminService,
    private leaveStore: LeaveAdminStoreService,
    private employerService: ManageEmployersService,
    private employerStore: EmployerProfileStoreService,
    private employeeService: ManageEmployeesService,
    private employeeStore: EmployeeRecordStoreService,
    private caseCommService: CaseCommunicationService,
    private templateDetailsStore: TemplateDetailsStoreService
  ) { }

  ngOnInit(): void {
    this.setUpForm();

    if ('leaveCaseDocumentId' in this.data.docData) {
      this.currentDocumentId = this.data.docData.leaveCaseDocumentId
    } else if ('employerDocumentId' in this.data.docData) {
      this.currentDocumentId = this.data.docData.employerDocumentId
    } else if ('documentId' in this.data.docData) {
      this.currentDocumentId = this.data.docData.documentId
    } else if ('templateDocumentId' in this.data.docData) {
      this.currentDocumentId = this.data.docData.templateDocumentId
    } else if ('recertDocumentId' in this.data.docData) {
      this.currentDocumentId = this.data.docData.recertDocumentId
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public cancel(): void {
    if (this.noChanges) {
      this.editDocumentDialogComponent.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public save(): void {
    this.editDocumentDialogComponent.disableClose = true; 

    switch(this.data.mode) {
      case DocumentUploadMode.LeaveCase: {
        const dto: PostDocumentEdit = {
          caseId: this.data.subjectId,
          leaveCaseDocumentId: this.currentDocumentId,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        }
    
        this.leaveService.postDocumentEdit(dto)
          .pipe(indicate(this.isSaving$))
          .subscribe((res)=>{
              this.leaveStore.caseDetails = res;
              this.editDocumentDialogComponent.close();
            },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
          );
        break;
      }
      case DocumentUploadMode.Employer: {
        const dto: PostEmployerDocumentEdit = {
          employerDocumentId: this.currentDocumentId,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        }
    
        this.employerService.postDocumentEdit(dto)
          .pipe(indicate(this.isSaving$))
          .subscribe((res)=>{
              this.employerStore.employerProfile = res;
              this.editDocumentDialogComponent.close();
            },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
          );
        break;
      }
      case DocumentUploadMode.Employee: {
        const dto: PostEmployeeDocumentEdit = {
          employeeId: this.data.subjectId,
          documentId: this.currentDocumentId,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value,
          associatedCase: this.associatedCase.value
        }
        this.employeeService.postDocumentEdit(dto)
          .pipe(indicate(this.isSaving$))
          .subscribe((res)=>{
              this.employeeStore.employeeRecord = res;
              this.editDocumentDialogComponent.close();
            },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
          );
        break;
      }
      case DocumentUploadMode.Template: {
        const dto: PostTemplateDocumentEdit = {
          templateId: this.data.subjectId,
          templateDocumentId: this.currentDocumentId,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        }
        this.caseCommService.postDocumentEdit(dto)
          .pipe(indicate(this.isSaving$))
          .subscribe((res)=>{
              this.templateDetailsStore.templateDetails = res;
              this.editDocumentDialogComponent.close();
            },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
          );
        break;
      }
      case DocumentUploadMode.Recert: {
        this.form.markAllAsTouched();
        if (this.formIsValid) {
          const doc = this.data.docData as PostRecertDocument;
          const dto: PostRecertDocument = {
            ...doc,
            recertDocumentId:this.currentDocumentId,
            documentTitle: this.documentTitle.value,
            documentCategoryId: this.documentCategoryId.value,
            documentComments: this.documentComments.value
          }
          this.editDocumentDialogComponent.close(dto);
        }
        break;
      }
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.editDocumentDialogComponent.close();
        }
      });
  }

  private setUpForm(): void {
    this.form = this.fb.group<PostDocumentEditForm>({
      documentTitle: this.fb.control(null, Validators.required),
      documentCategoryId: this.fb.control(null, Validators.required),
      documentComments: this.fb.control(null),
      associatedCase: this.fb.control(null)
    });

    this.form.patchValue({
      documentTitle: this.data.docData.documentTitle,
      documentCategoryId: this.data.docData.documentCategoryId,
      documentComments: this.data.docData.documentComments,
    });

    if (this.data.associatedCaseId) {
      this.form.controls.associatedCase.patchValue(this.data.associatedCaseId)
    }

    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe(()=>{
      if (!this.noChanges && !this.formChangeEmitted) {
        this.isEditing.emit(true);
        this.formChangeEmitted = true;
      } else if (this.noChanges) {
        this.isEditing.emit(false);
        this.formChangeEmitted = false;
      }
    });
  }
}
