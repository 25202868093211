import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusChips } from '@core/models';
import { DocumentCategoryItem } from '@core/models/case-communication/document-category-item.model';
import { EmailTemplates } from '@core/models/case-communication/email-templates.model';
import { appRoutePaths, manageEmployersRoutes } from '@core/routes/route-paths.constants';
import { ConstantsService, LayoutService } from '@core/services';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { EmailTemplatesDashboardStoreService } from '@core/services/email-templates-dashboard-store.service';
import { AddEditDocumentCategoryComponent } from '@modules/dialogs/add-edit-document-category/add-edit-document-category.component';
import { indicate, nameof } from '@shared/helpers';
import { searchIncludes } from '@shared/helpers/search.helpers';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EmailTemplateOwnerOptions } from '@core/enums/email-template-owner-options.enum';

@Component({
  selector: 'app-document-categories-tab',
  templateUrl: './document-categories-tab.component.html',
  styleUrls: ['./document-categories-tab.component.scss']
})
export class DocumentCategoriesTabComponent implements OnInit {
  @Input() emailTemplatesData: EmailTemplates;
  @Input() isMobile: boolean;
  @Input() sysText: any;
  @Input() unsavedChangesSysText: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private destroy$: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup;
  public _dc: MatTableDataSource<DocumentCategoryItem>;
  public displayedColumns: string[] = ['documentCategoryName','ownerEmployer','allowUse'];
  public pageSizeOptions: number[];  
  public pageIndex: number = 0;
  public isLoadingAddEditDocumentCategoryDialog$: Subject<boolean> = new Subject<boolean>();
  public chipStyles: StatusChips;

  set dataSource(dataSource: DocumentCategoryItem[]) {
    this._dc = new MatTableDataSource<DocumentCategoryItem>(dataSource);

    this._dc.sortingDataAccessor = (item: DocumentCategoryItem, property: string) => {
      switch(property) {
        case nameof<DocumentCategoryItem>('documentCategoryName'): 
          return item.documentCategoryName.toLowerCase();
        case nameof<DocumentCategoryItem>('ownerEmployer'): 
          return item.documentCategoryId < 0 ? "-- system --" : !item.ownerEmployer ? "-- stock --" : item.ownerEmployer.employerName.toLowerCase();
        default: 
          return item[property];
      }
    };
    
    this._dc.paginator = this.paginator;
    this._dc.sort = this.sort;
  }

  public get searchValue(): FormControl<string> {
    return this.form.controls.searchValue as FormControl<string>;
  }

  public get employerControl(): FormControl<number> {
    return this.form.controls.employer as FormControl<number>;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private store: EmailTemplatesDashboardStoreService,
    private router: Router,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private service: CaseCommunicationService,
    private constants: ConstantsService
  ) { 
    this.form = this.fb.group({
      searchValue: [null],
      employer: [null]
    });
  }

  ngOnInit(): void {
    this.chipStyles = this.constants.STAT_CHIP_STYLES;

    if (!this.emailTemplatesData.documentCategoryShowOwner) {
      this.displayedColumns.splice(this.displayedColumns.indexOf("ownerEmployer"),1)
    }

    this.store.emailTemplates$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {  
        if (res != null) {
          this.dataSource = res.documentCategoryItems; 
          this.setPageSizeOptions(res.documentCategoryItems.length);
        }
      });

    this.form.valueChanges.subscribe(()=>{
      this.applyFilters();
    })
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.setFilterPredicate();
      this._dc.filter = this.searchValue?.value?.trim().toLowerCase();
    },1)
    this._dc.sort = this.sort;
    this._dc.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._dc.filter = filterValue.trim().toLowerCase();
  }

  public onCreateNew(): void {
    this.service.getAddEditDocumentCategoryDialog()
      .pipe(indicate(this.isLoadingAddEditDocumentCategoryDialog$))
      .subscribe((res) => {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '550px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          data: { 
            sysText: this.sysText,
            unsavedChangesSysText: this.unsavedChangesSysText,
            dialogData: res,
          }
        }

      this.dialog.open(AddEditDocumentCategoryComponent, dialogConfig)
        .afterClosed()
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          this.store.emailTemplates = res;
      });
    });
  }

  public viewEmployerProfile(employerId: number) {
    this.router.navigate([`${appRoutePaths.MANAGE_EMPLOYERS}/${manageEmployersRoutes.PROFILE}`, employerId]);
  }

  public editCategory(category: DocumentCategoryItem) {
    this.service.getAddEditDocumentCategoryDialog(category.documentCategoryId)
      .pipe(indicate(this.isLoadingAddEditDocumentCategoryDialog$))
      .subscribe((res) => {
        const dialogConfig: MatDialogConfig = {
          width: this.layoutService.isHandheld ? '100vw' : '550px',
          maxWidth: this.layoutService.isHandheld ? '100vw' : '80vw',
          data: { 
            sysText: this.sysText,
            category: category,
            unsavedChangesSysText: this.unsavedChangesSysText,
            dialogData: res,
          }
        }

      this.dialog.open(AddEditDocumentCategoryComponent, dialogConfig)
        .afterClosed()
        .pipe(filter((res) => !!res))
        .subscribe((res) => {
          this.store.emailTemplates = res;
      });
    });
  }

  private applyFilters() {
    let filteredList = this.store.emailTemplates.documentCategoryItems;
    if (this.employerControl.value !== null) {
      filteredList = filteredList.filter(i => {
        return (i.ownerEmployer?.employerId === this.employerControl.value) 
          || (i.documentCategoryId < 0 && this.employerControl.value === EmailTemplateOwnerOptions.System)
          || ((!i.ownerEmployer && i.documentCategoryId >= 0) && this.employerControl.value === EmailTemplateOwnerOptions.Stock)
      });
    }
    this.dataSource = filteredList;
    this.setPageSizeOptions(filteredList.length);
    this.setFilterPredicate();
    this._dc.filter = this.searchValue?.value?.trim().toLowerCase();
  }

  private setFilterPredicate() {
    this._dc.filterPredicate = (data, filter) => {
      const filterClean = filter.toLowerCase().trim()
      const dataStr = (`${data.documentCategoryName} ${data.ownerEmployer?.employerName ?? ''} `).toLowerCase();
      return searchIncludes(dataStr, filterClean); 
    }
  }

  private setPageSizeOptions(length: number) {
    const multipleOf = 10;
    this.pageSizeOptions = [10];
    const multiples = Math.floor(length / multipleOf) + 1;
    for (let i = 2; i <= multiples; i++) {
      this.pageSizeOptions.push(i * multipleOf);
    }
  }

}
