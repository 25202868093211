import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeaveStages } from '@core/enums/leave-stages.enum';
import { CaseDetails } from '@core/models';
import { LayoutService } from '@core/services';

@Component({
  selector: 'app-calendar-requirements-warning',
  templateUrl: './calendar-requirements-warning.component.html',
  styleUrls: ['./calendar-requirements-warning.component.scss']
})
export class CalendarRequirementsWarningComponent implements OnInit {
  public leaveStages = LeaveStages;
  public caseDetails: CaseDetails;
  public sysText: any;

  constructor (
    @Inject(MAT_DIALOG_DATA)
    public data: { caseDetails: CaseDetails; sysText: any },

    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<CalendarRequirementsWarningComponent>,
  ) {}

  ngOnInit(): void {
    this.caseDetails = this.data.caseDetails;
    this.sysText = this.data.sysText;
  }

  public get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
