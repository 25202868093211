import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-proceed-confirmation',
  templateUrl: './proceed-confirmation.component.html',
  styleUrls: ['./proceed-confirmation.component.scss']
})
export class ProceedConfirmationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) 
    public data: {
      title: any, 
      message1: any; 
      message2: any; 
      cancelCaption: any 
      proceedCaption: any; 
    }) {}

  ngOnInit(): void {
  }
}