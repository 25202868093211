<div class="dialog--title">
    <h6 class="grey1">{{ data.sysText.sendInvitation_header }}</h6>
    <ng-container *ngIf="isHandheld$ | async">
        <span class="spacer"></span>
        <mat-icon role="button" mat-dialog-close class="icon__button">close</mat-icon>
    </ng-container>
    <mat-divider class="section-header-underline"></mat-divider>
</div>

<mat-dialog-content class="mat-dialog-content">
    <ng-container *ngIf="!data.inviteDialog.isEmployerAccessAllowed; else hasAccess">
        <p class="body2 bottom-margin">{{data.sysText.sendInvitation_noAccess}}</p>
    </ng-container>
    <ng-template #hasAccess>
        <ng-container *ngIf="data.inviteDialog.isEmployeeInactive; else active">
            <p class="body2 bottom-margin">{{data.sysText.sendInvitation_inactive}}</p>
        </ng-container>
        <ng-template #active>
            <ng-container *ngIf="!data.inviteDialog.isUserActivated">
                <p class="body2">{{data.sysText.sendInvitation_body1}}</p>
                <p class="body2 email">{{data.inviteDialog.email}}</p>
                <p class="body2 bottom-margin">{{data.sysText.sendInvitation_body2}}</p>
            </ng-container>
            <ng-container *ngIf="data.inviteDialog.isUserActivated">
                <p class="body2 bottom-margin">{{data.sysText.sendInvitation_body3}}</p>
            </ng-container>
        </ng-template>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" mat-dialog-close [disabled]="isSaving$ | async" *ngIf="!(isHandheld$ | async)">
    {{ (data.inviteDialog.isEmployerAccessAllowed && !data.inviteDialog.isEmployeeInactive) ? data.sysText.cancel : data.sysText.close}}
  </button>
  <button *ngIf="data.inviteDialog.isEmployerAccessAllowed && !data.inviteDialog.isEmployeeInactive" mat-stroked-button class="button" color="accent" (click)="save()">
    {{ data.sysText.sendInvite }}
  </button>
</mat-dialog-actions>
<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
        </button>
    </mat-dialog-actions>
</ng-template>
  
