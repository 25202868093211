<h6 class="dialog--title">
  {{ sysText.comingSoonDialog_title | titlecase }}
</h6>

<mat-dialog-content>
  <p class="subtitle1">
    {{ sysText.comingSoonDialog_subtitle }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button class="button" [mat-dialog-close] color="accent">
    {{ sysText.close }}
  </button>
</mat-dialog-actions>
