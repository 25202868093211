<div class="dialog--title">
	<h6 >
		{{(data.isPreview ? data.sysText.viewEmailDialog_previewTitle : data.sysText.viewEmailDialog_viewTitle) | titlecase}}
	</h6>
	<ng-container *ngIf="isHandheld">
		<span class="spacer"></span>
		<mat-icon role="button" (click)="close()" class="icon__button">close</mat-icon>
	</ng-container>
</div>

<mat-dialog-content #content>
	<div class="info-section">
		<span class="section-label body2 grey6 semibold">{{data.sysText.viewEmailDialog_from | titlecase}}:</span>
		<span 
			class="body2 grey3 contact-chip"
			[matTooltip]="data.emailData.sender?.explicitEmailAddress" 
			[matTooltipShowDelay]="500" 
			matTooltipPosition="above" 
		>
			{{data.emailData.sender?.description}}
		</span>
	</div>
	<div class="info-section">
		<span class="section-label body2 grey6 semibold">{{data.sysText.viewEmailDialog_to | titlecase}}:</span>
		<div>
			<span 
				*ngFor="let rec of data.emailData.recipients; index as i"
				class="body2 grey3 contact-chip"
				[matTooltip]="rec.name ? rec.email : null" 
				[matTooltipShowDelay]="500" 
				matTooltipPosition="above" 
			>
				{{rec.name ?? rec.email}}
			</span>
		</div>
	</div>
	<div class="info-section">
		<span class="section-label body2 grey6 semibold">{{data.sysText.viewEmailDialog_cc | titlecase}}:</span>
		<div>
			<span 
				*ngFor="let rec of data.emailData.cc; index as i"
				class="body2 grey3 contact-chip"
				[matTooltip]="rec.name ? rec.email : null" 
				[matTooltipShowDelay]="500" 
				matTooltipPosition="above" 
			>
				{{rec.name ?? rec.email}}
			</span>
		</div>
	</div>
	<div class="info-section">
		<span class="section-label body2 grey6 semibold">{{data.sysText.viewEmailDialog_bcc | titlecase}}:</span>
		<div>
			<span 
				*ngFor="let rec of data.emailData.bcc; index as i"
				class="body2 grey3 contact-chip"
				[matTooltip]="rec.name ? rec.email : null" 
				[matTooltipShowDelay]="500" 
				matTooltipPosition="above" 
			>
				{{rec.name ?? rec.email}}
			</span>
		</div>
	</div>
	<div class="flex">
		<mat-icon class="section-label">title</mat-icon>
		<span class="body2 grey6 semibold">{{data.emailData.subject}}</span>
	</div>
	<div class="info-section">
		<mat-icon class="body-icon">subject</mat-icon>
		<div class="editor-container">
			<editor
				#editor
				[(ngModel)]="data.emailData.bodyHtml"
				[init]="{ 
					width: '100%',
					menubar: false,
					statusbar: false,
					toolbar: false,
					setup: tinySetup,
					paste_block_drop: true,
					paste_as_text: true,
					promotion: false,
					plugins: 'autoresize',
					content_style: editorStyle
				}"
			></editor>
		</div>
	</div>
	<div class="info-section">
		<mat-icon class="section-label">attach_file</mat-icon>
		<div class="attachments-container">
			<ng-container *ngIf="data.emailData.attachments.length > 0; else noAttachments">
				<span *ngFor="let attachment of data.emailData.attachments; index as i">
					<ng-container *ngIf="!attachment.disableDownload; else noDownload">
						<span 
							class="body2" 
							[ngClass]="{ 'click-link': !(isDownloadingAttachment$ | async), 'click-nolink': (isDownloadingAttachment$ | async) }" 
							(click)="downloadAttachment(attachment)">
							{{attachment.attachmentTitle}}
						</span>
						<mat-spinner *ngIf="(isDownloadingAttachment$ | async) && isDownloadingAttachment(attachment.attachmentId)" class="download-spinner" [diameter]="18" color="accent"></mat-spinner>
					</ng-container>
					<ng-template #noDownload>
						<span class="body2">
							{{attachment.attachmentTitle}}
						</span>
					</ng-template>
				</span>
			</ng-container>
			<ng-template #noAttachments>
				<span class="no-attachments">{{data.sysText.viewEmailDialog_noattachments}}</span>
			</ng-template>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions class="actions">
	<button mat-stroked-button class="button" color="accent" (click)="close()">
		{{data.sysText.viewEmailDialog_close}}
	</button>
</mat-dialog-actions>