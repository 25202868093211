<div class="dialog--title">
  <h6>
      {{data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeInfo | titlecase}}
  </h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="close(false)" class="icon__button">close</mat-icon>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="main-container">
    <div class="column-container">
      <ng-container *ngIf="data.caseDetails.employeeInformation.isAnonymous">
        <div class="anonymous-info-container">
          <div class="icon-container">
            <p class="body2">{{data.sysText.caseEmployeeInfo.caseEmployeeInfo_confirmInfo  | capitalizefirst}}</p>
            <mat-icon 
              class="info-icon" 
              [matTooltip]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_anonymousExplanation" 
              [matTooltipShowDelay]="500" 
              matTooltipPosition="right">
              info_outline
            </mat-icon>
          </div>
          <p class="body2">
            <strong>{{anonEmployee.fullName}}</strong>
          </p>
          <em>
            <p class="body2" *ngIf="anonEmployee.workEmail != null || anonEmployee.workPhone != null">
              {{anonEmployee.workEmail}}
              <span *ngIf="anonEmployee.workEmail != null && anonEmployee.workPhone != null">&bull;</span>
              {{anonEmployee.workPhone}}
            </p>
            <p class="body2" *ngIf="anonEmployee.personalEmail != null || anonEmployee.personalPhone != null">
              {{anonEmployee.personalEmail}}
              <span *ngIf="anonEmployee.personalEmail != null && anonEmployee.personalPhone != null">&bull;</span>
              {{anonEmployee.personalPhone}}
            </p>
          </em>
        </div>
      </ng-container>

      <app-input-field
        *ngIf="!this.data.canChangeEmployee"
        [group]="form"
        [control]="form.controls.employeeName"
        [icon]="'person'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employee"
        [hint]="(data.fieldNotifications.hasOwnProperty('employee') ? data.fieldNotifications['employee'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true">
      </app-input-field>

      <app-autocomplete-field
        *ngIf="this.data.canChangeEmployee"
        [group]="form"
        [control]="form.controls.employee"
        [icon]="'person'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employee"
        [options]="data.employeeOptions"
        [interfaceName]="'employee'"
        [isDisabled]="isNewEmployee"
        (hintClick)="onEEHintClick()"
        [hasClickableHint]="true"
        [showSearchIcon]="!(isLoading$ | async)"
        [comboMsError]="true"
        [showSpinner]="(isLoading$ | async)">
      </app-autocomplete-field>

      <div class="new-employee" *ngIf="isNewEmployee">
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_firstName" 
              [control]="newEmployee.controls.firstName" 
              [hideIconSpace]="true"
              [group]="newEmployee">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_middleName" 
              [control]="newEmployee.controls.middleName" 
              [hideIconSpace]="true"
              [group]="newEmployee">
            </app-input-field>
          </div>
        </div>
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_lastName" 
              [control]="newEmployee.controls.lastName" 
              [hideIconSpace]="true"
              [group]="newEmployee">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_suffix" 
              [control]="newEmployee.controls.suffix" 
              [hideIconSpace]="true"
              [group]="newEmployee">
            </app-input-field>
          </div>
        </div>
        <div class="new-ee-full-column">
          <app-input-field 
            [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_preferredName" 
            [control]="newEmployee.controls.preferredName" 
            [group]="newEmployee"
            [hideIconSpace]="true">
          </app-input-field>
        </div>
      </div>

      <app-email-field-group
        [group]="form"
        [workEmailControl]="employeeWorkEmailField"
        [isWorkEmailPreferredControl]="form.controls.employeeWorkEmailChecked"
        [personalEmailControl]="employeePersonalEmailField"
        [isPersonalEmailPreferredControl]="form.controls.employeePersonalEmailChecked">
      </app-email-field-group>

      <app-input-field
        [group]="form"
        [control]="form.controls.employeeWorkPhone"
        [icon]="'phone'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_workPhone"
        [inputType]="'string'"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeeWorkPhone') ? data.fieldNotifications['employeeWorkPhone'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-input-field>

      <app-input-field
        [group]="form"
        [control]="form.controls.employeePersonalPhone"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_personalPhone"
        [inputType]="'string'"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeePersonalPhone') ? data.fieldNotifications['employeePersonalPhone'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-input-field>

      <app-input-field
        [group]="form"
        [control]="form.controls.company"
        [icon]="'business'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_company"
        [hint]="(data.fieldNotifications.hasOwnProperty('company') ? data.fieldNotifications['company'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true">
      </app-input-field>

      <app-autocomplete-field
        [group]="form"
        [control]="form.controls.employeeJobTitle"
        [icon]="'work'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeJobTitle"
        [options]="data.jobTitles"
        [isSimple]="true"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeeJobTitle') ? data.fieldNotifications['employeeJobTitle'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-autocomplete-field>

      <app-text-area
        [group]="form"
        [control]="form.controls.fixedLeaveYearStart"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_fixedLeaveYearStart"
        [info]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_fixedLeaveInfo"
        [icon]="'today'">
      </app-text-area>

      <app-date-field
        [group]="form"
        [control]="form.controls.firstLeaveUsageDate"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_firstLeaveDate"
        [info]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_firstLeaveInfo"
        [icon]="'today'">
      </app-date-field>
    </div>

    <div class="column-container">
      <app-date-field
        [group]="form"
        [control]="form.controls.employeeHireDate"
        [icon]="'today'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeHireDate"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeeHireDate') ? data.fieldNotifications['employeeHireDate'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-date-field>

      <app-input-field
        [group]="form"
        [control]="form.controls.employeeHoursPerWeek"
        [icon]="'schedule'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeHoursPerWeek"
        [inputType]="'number'"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeeHoursPerWeek') ? data.fieldNotifications['employeeHoursPerWeek'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-input-field>

      <div class="select-container">
        <mat-icon [ngClass]="{ focus: select.focused }">today</mat-icon>
        <mat-form-field color="accent">
          <mat-label>{{ data.sysText.caseEmployeeInfo.caseEmployeeInfo_workdays | titlecase }}</mat-label>
          <mat-select #select="matSelect" [formControl]="workdays" multiple disableOptionCentering panelClass="dropDownSelectMultiple">
            <mat-select-trigger>
                <span *ngIf="workdays.value?.length >= 1">
                    <span *ngFor="let day of workdays.value; index as i">
                        {{ this.data.sysText.caseEmployeeInfo["caseEmployeeInfo_" + day] }}<span *ngIf="i !== workdays.value?.length - 1">, </span> 
                    </span>
                </span>
            </mat-select-trigger>
            <mat-option *ngFor="let dayOption of workdaySelectionOptions" [value]="dayOption">
              <span>{{ dayOption | titlecase }}</span>
            </mat-option>
          </mat-select>
          <!-- mat hint for spacing -->
          <mat-hint></mat-hint>
          <mat-error>{{ workdays.errors?.msError }}</mat-error>
          <mat-error *ngIf="workdays.errors?.required">{{ data.sysText.caseEmployeeInfo.workdays | titlecase }} {{data.sysText.required}}</mat-error>
        </mat-form-field>
      </div>

      <app-input-field
        [group]="form"
        [control]="form.controls.employeeCity"
        [icon]="'location_on'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeCity"
        [hint]="(data.fieldNotifications.hasOwnProperty('employeeCity') ? data.fieldNotifications['employeeCity'] : null) | capitalizefirst"
        [isHintHighlighted]="false"
        [isHintWarning]="true"
        [isDisabled]="(isLoading$ | async)">
      </app-input-field>

      <div class="select-container">
          <mat-icon [ngClass]="{ focus: select.focused }">map</mat-icon>
          <mat-form-field color="accent">
            <mat-label class="body2">{{ data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeState | titlecase }}</mat-label>
            <mat-select #select="matSelect" [formControl]="form.controls.employeeStateId" [value]="data.caseDetails.employeeInformation.employeeStateId" disableOptionCentering panelClass="dropDownSelect">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let state of data.stateOptions" [value]=state.id>
                <span>{{ state.description }}</span>
              </mat-option>
            </mat-select>
            <mat-hint>
              <p *ngIf="data.fieldNotifications.hasOwnProperty('employeeStateId')" class="mat-hint-combined warn">
                {{ data.fieldNotifications['employeeStateId'] }}
              </p>
            </mat-hint>
            <mat-error>{{ form.controls.employeeStateId.errors?.msError }}</mat-error>
            <mat-error *ngIf="form.controls.employeeStateId.errors?.required">{{ data.sysText.caseEmployeeInfo.required }}</mat-error>
          </mat-form-field>
          <mat-icon [matTooltip]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeStateInfo" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
      </div>
      
      <div class="contact-group">
        <div *ngFor="let sv of supervisorsArray.controls; let i = index" class="group-form-container">
          <div class="contact-container">
            <div class="input-auto-field" [ngClass]="{'w-90': (supervisorsArray.controls.length > 1)}">
              <app-autocomplete-field
                [group]="sv"
                [control]="sv.controls.employeeId"
                [icon]="i > 0 ? '' : 'person'"
                [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeSupervisor"
                [options]="data.supervisorOptions"
                [interfaceName]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeSupervisor"
                [isDisabled]="isNewSupervisor[i]"
                (hintClick)="onAddSupervisorContact(i)"
                [hasClickableHint]="true"
                [showSearchIcon]="true"
                [comboMsError]="true">
              </app-autocomplete-field>
            </div>
            <button
              mat-icon-button
              aria-label="remove button"
              [disableRipple]="true"
              (click)="onRemoveSupervisorContact(i)"
              *ngIf="supervisorsArray.controls.length > 1">
              <mat-icon [ngClass]="{'accent': true, 'margin-bottom-17': !isHandheld}">close</mat-icon>
            </button>
          </div>

          <div class="new-employee" *ngIf="isNewSupervisor[i]">
            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_firstName" 
                  [control]="sv.controls.firstName"
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>

              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_middleName" 
                  [control]="sv.controls.middleName" 
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>
            </div>

            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_lastName" 
                  [control]="sv.controls.lastName" 
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>

              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_suffix" 
                  [control]="sv.controls.suffix" 
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>
            </div>
            
            <div class="new-ee-full-column">
              <app-input-field 
                [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_preferredName" 
                [control]="sv.controls.preferredName"
                [group]="sv"
                [hideIconSpace]="true">
              </app-input-field>
            </div>

            <app-email-field-group
              [group]="form"
              [hideIconSpace]="true"
              [workEmailControl]="sv.controls.workEmail"
              [isWorkEmailPreferredControl]="sv.controls.workEmailChecked"
              [personalEmailControl]="sv.controls.personalEmail"
              [isPersonalEmailPreferredControl]="sv.controls.personalEmailChecked">
            </app-email-field-group>

            <div class="sub-employee">
              <div class="new-ee-column">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_workPhone" 
                  [control]="sv.controls.workPhone"
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>
              <div class="new-ee-column left-margin">
                <app-input-field 
                  [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_personalPhone" 
                  [control]="sv.controls.personalPhone" 
                  [hideIconSpace]="true"
                  [group]="sv">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <button
          (click)="addSupervisorFormGroup()"
          mat-flat-button
          color="accent"
          class="button add-additional-button"
        >
          {{data.sysText.caseEmployeeInfo.addAdditionalSupervisor}}
        </button>
      </div>
      
      <app-autocomplete-field
        [group]="form"
        [control]="form.controls.employeeHrManagerId"
        [icon]="'supervisor_account'"
        [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeHrManager"
        [options]="data.hrManagerOptions"
        [interfaceName]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_employeeHrManager"
        [isDisabled]="isNewHrManager || (isLoading$ | async)"
        (hintClick)="onHrManagerHintClick()"
        [hasClickableHint]="true"
        [showSearchIcon]="true"
        [showHintAndError]="true"
        [shouldCasePipe]="false">
      </app-autocomplete-field>
      
      <div class="new-employee" *ngIf="isNewHrManager">
        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_firstName" 
              [control]="newEmployeeHrManager.controls.firstName" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_middleName" 
              [control]="newEmployeeHrManager.controls.middleName"
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager">
            </app-input-field>
          </div>
        </div>

        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_lastName" 
              [control]="newEmployeeHrManager.controls.lastName" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_suffix" 
              [control]="newEmployeeHrManager.controls.suffix" 
              [hideIconSpace]="true"
              [group]="newEmployeeHrManager">
            </app-input-field>
          </div>
        </div>

        <div class="new-ee-full-column">
          <div>
            <app-input-field 
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_preferredName" 
              [control]="newEmployeeHrManager.controls.preferredName" 
              [group]="newEmployeeHrManager"
              [hideIconSpace]="true">
            </app-input-field>
          </div>
        </div>

        <app-email-field-group
          [group]="form"
          [hideIconSpace]="true"
          [workEmailControl]="newEmployeeHrManager.controls.workEmail"
          [isWorkEmailPreferredControl]="newEmployeeHrManager.controls.workEmailChecked"
          [personalEmailControl]="newEmployeeHrManager.controls.personalEmail"
          [isPersonalEmailPreferredControl]="newEmployeeHrManager.controls.personalEmailChecked">
        </app-email-field-group>

        <div class="sub-employee">
          <div class="new-ee-column">
            <app-input-field 
              [group]="newEmployeeHrManager"
              [control]="newEmployeeHrManager.controls.workPhone"
              [hideIconSpace]="true"
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_workPhone"
              [inputType]="'string'">
            </app-input-field>
          </div>
          <div class="new-ee-column left-margin">
            <app-input-field
              [group]="newEmployeeHrManager"
              [control]="newEmployeeHrManager.controls.personalPhone"
              [hideIconSpace]="true"
              [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_personalPhone"
              [inputType]="'string'">
            </app-input-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-error *ngIf="this.form.errors?.msError" class="mse-form-error caption">{{ this.form.errors?.msError }}</mat-error>
  <div *ngIf="(showNotifyToggle$ | async) || (!(isSubmitting$ | async) && showNotifyToggle())" class="slide-container">
    <app-slide-toggle
      [control]="form.controls.notifyEmployee"
      [isDisabled]="(isSubmitting$ | async)"
      [label]="data.sysText.caseEmployeeInfo.caseEmployeeInfo_notifyEmployee">
    </app-slide-toggle>
  </div>
  <ng-container *ngIf="!(isSubmitting$ | async); else submitting" >
    <div>
      <button *ngIf="!isMobile"
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="close(false)">
        {{ data.sysText.caseEmployeeInfo.cancel }}
      </button>
      <button 
        mat-stroked-button 
        class="button" 
        color="accent" 
        (click)="onSubmit(false)">
        {{ data.sysText.caseEmployeeInfo.save }}
      </button>
    </div>
  </ng-container>
  <ng-template #submitting>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
