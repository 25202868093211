import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseDetails, StiiraError } from '@core/models';
import { LeaveCalendarWorkdaysPost } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { Workdays } from '@core/models/leave-admin/leave-calendar/work-days.model';
import { LayoutService, ErrorService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { CalendarUpdateConfirmationComponent } from '../calendar-update-confirmation/calendar-update-confirmation.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { EmployeeCalendarRequiredValues, showEmployeeCalendarWarning } from '@shared/helpers/calendar-warn.helpers';
import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';

@Component({
  selector: 'app-edit-work-week',
  templateUrl: './edit-work-week.component.html',
  styleUrls: ['./edit-work-week.component.scss']
})
export class EditWorkdaysComponent implements OnInit {
  public form: UntypedFormGroup;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();
  public isAnonymous: boolean = false;

  private formInitValues: any;

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      workDays: Workdays, 
      caseDetails: CaseDetails,
      sysText: any;
      unsavedChangesSystext: any; 
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditWorkdaysComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: LeaveAdminService,
    private store: LeaveAdminStoreService,
  ) { 
    this.form = this.fb.group({
      sunday: [null],
      monday: [null],
      tuesday: [null],
      wednesday: [null],
      thursday: [null],
      friday: [null],
      saturday: [null],
    });
  }

  ngOnInit(): void {
    this.isAnonymous = this.data.caseDetails.employeeInformation.isAnonymous;

    if (this.data.caseDetails.leaveCalendar.workdays != null) {
      this.form.patchValue({
        sunday: this.data.workDays.sunday,
        monday: this.data.workDays.monday,
        tuesday: this.data.workDays.tuesday,
        wednesday: this.data.workDays.wednesday,
        thursday: this.data.workDays.thursday,
        friday: this.data.workDays.friday,
        saturday: this.data.workDays.saturday,
      })
    }

    this.formInitValues = { ...this.form.value };
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public onSubmit(): void {
    const eeReqVals: EmployeeCalendarRequiredValues = {
      newLeaveYearFixedStart: this.store.caseDetails.employeeInformation.fixedLeaveYearStart,
      prevLeaveYearFixedStart: this.store.caseDetails.employeeInformation.fixedLeaveYearStart,
      newHireDate: this.store.caseDetails.employeeInformation.employeeHireDate,
      prevHireDate: this.store.caseDetails.employeeInformation.employeeHireDate,
      newHoursPerWeek: this.store.caseDetails.employeeInformation.employeeHoursPerWeek,
      prevHoursPerWeek: this.store.caseDetails.employeeInformation.employeeHoursPerWeek,
      newWorkdays: JSON.stringify(this.form.value),
      prevWorkdays: JSON.stringify(this.formInitValues),

      newFmlaTotalHours: this.store.caseDetails.leaveHours.employeeFmlaTotalHours,
      prevFmlaTotalHours: this.store.caseDetails.leaveHours.employeeFmlaTotalHours,

      newStateTotalHours: this.store.caseDetails.leaveHours.employeeStateTotalHours,
      prevStateTotalHours: this.store.caseDetails.leaveHours.employeeStateTotalHours,

      newPloTotalHours: this.store.caseDetails.leaveHours.employeePloTotalHours,
      prevPloTotalHours: this.store.caseDetails.leaveHours.employeePloTotalHours,
    };

    const showCalendarWarningMessage: CalendarWarnMessages[] = showEmployeeCalendarWarning(eeReqVals, this.data.caseDetails.leaveCalendar?.employeeLeaveHoursExtended);

    if (showCalendarWarningMessage) {
      const dialogConfig: MatDialogConfig = {
        disableClose: false,
        closeOnNavigation: true,
        data: {
          sysText: this.store.sysText.leaveCalendarUpdateConfirmation, 
          warnMessages: showCalendarWarningMessage
        }
      }

      this.dialog.open(CalendarUpdateConfirmationComponent, dialogConfig)
        .beforeClosed().subscribe((res: boolean) => {
          if (res) {
            this.postSaveInfo();
          }
        });
    }
    else {
      this.postSaveInfo();
    }
  }

  public postSaveInfo() {
    const dto: LeaveCalendarWorkdaysPost = {
      caseId: this.data.caseDetails.leaveInformation.caseId,
      workdays: {
        sunday: this.form.controls.sunday.value,
        monday: this.form.controls.monday.value,
        tuesday: this.form.controls.tuesday.value,
        wednesday: this.form.controls.wednesday.value,
        thursday: this.form.controls.thursday.value,
        friday: this.form.controls.friday.value,
        saturday: this.form.controls.saturday.value,
      }
    };
    this.service.postLeaveCalendarWorkdays(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        this.dialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSystext,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }
}
