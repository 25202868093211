import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEventTypes } from '@core/enums/calendar-event-type.enum';
import { LeaveYears } from '@core/enums/leave-years.enum';
import { CaseDetails, LeaveInfo, SelectionOption } from '@core/models';
import { EmployeeLeaveHoursExtended } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { LayoutService } from '@core/services';
import { EventApi } from '@fullcalendar/core';

@Component({
  selector: 'app-view-calendar-item',
  templateUrl: './view-calendar-item.component.html',
  styleUrls: ['./view-calendar-item.component.scss']
})
export class ViewCalendarItemComponent implements OnInit {
  public calendarEventTypes = CalendarEventTypes;
  public leaveInformation: LeaveInfo;
  public leaveYearOptions: SelectionOption[];
  public employeeLeaveHoursExtended: EmployeeLeaveHoursExtended;
  public ploLeaveYearId: number = LeaveYears.OregonRollingForward;
  public businessWorkingDays: number[];

  constructor (
    private datePipe: DatePipe,
    private layoutService: LayoutService,
    private dialogRef: MatDialogRef<ViewCalendarItemComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { 
      event: EventApi,
      caseDetails: CaseDetails,
      sysText: any,
    },
  ) {
    this.leaveInformation = this.data.caseDetails?.leaveInformation;
    this.leaveYearOptions = this.data.caseDetails?.leaveCalendar?.leaveYearOptions;
    this.employeeLeaveHoursExtended = this.data.event.extendedProps?.employeeLeaveHoursExtended;
  }

  ngOnInit(): void
  { 
    this.setBusinessHours(this.data.caseDetails);
  }

  public get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  public close(): void {
    this.dialogRef.close();
  }
  
  public hasCaseHoursToday(): boolean {
    return this.employeeLeaveHoursExtended.leaveCaseHoursID != null && this.leaveInformation.hiddenDate == null;
  }

  public caseIsHidden(): boolean {
    return this.leaveInformation.hiddenDate != null;
  }

  public outsideCaseTimeframe(): boolean {
    let calendarDateFormed = this.datePipe.transform(new Date(this.employeeLeaveHoursExtended.calendarDate), "yyyy-MM-dd");

    let outsideTimeframe = false;

    outsideTimeframe = this.leaveInformation.startDate == null
      || this.leaveInformation.endDate == null
      || calendarDateFormed < this.datePipe.transform(this.leaveInformation.startDate, "yyyy-MM-dd") 
      || calendarDateFormed > this.datePipe.transform(this.leaveInformation.endDate, "yyyy-MM-dd");

    return outsideTimeframe;
  }

  public notWorkday(): boolean {
    return !this.businessWorkingDays?.includes((new Date(this.employeeLeaveHoursExtended.calendarDate))?.getDay());
  }

  public fmlaApplies(): boolean {
    return this.leaveInformation.isFmlaHoursApplicable;
  }

  public fmlaExhausted(): boolean {
    return this.fmlaApplies() 
      && this.employeeLeaveHoursExtended.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended.employeeFmlaAvailableHours != null 
      && this.employeeLeaveHoursExtended.employeeFmlaAvailableHours <= 0;
  }

  public stateApplies(): boolean {
    return this.leaveInformation.isStateHoursApplicable;
  }

  public stateExhausted(): boolean {
    return this.stateApplies() 
      && this.employeeLeaveHoursExtended.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended.employeeStateAvailableHours != null 
      && this.employeeLeaveHoursExtended.employeeStateAvailableHours <= 0;
  }

  public ploApplies(): boolean {
    return this.leaveInformation.isPloHoursApplicable;
  }

  public ploExhausted(): boolean {
    return this.ploApplies() 
      && this.employeeLeaveHoursExtended.leaveCaseLeaveHours == null 
      && this.employeeLeaveHoursExtended.employeePloAvailableHours != null 
      && this.employeeLeaveHoursExtended.employeePloAvailableHours <= 0;
  }

  public exhausted(): boolean {
    return this.fmlaExhausted() || this.stateExhausted() || this.ploExhausted();
  }

  public banks(): string {
    if (!this.fmlaApplies() && !this.stateApplies() && !this.ploApplies())
      return null;

    var drawsValue = "";

    if (this.fmlaApplies())
    {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "FMLA";
    }

    if (this.stateApplies()) {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "State";
    }

    if (this.ploApplies()) {
      if (drawsValue.length > 0)
        drawsValue += ", ";
      
      drawsValue += "PLO";
    }

    return drawsValue;
  }

  public multipleDraws(): boolean {
    let drawCount = 0;
    if (this.fmlaApplies())
      drawCount++;
    
    if (this.stateApplies())
      drawCount++;

    if (this.ploApplies())
      drawCount++;

    return drawCount > 1;
  }

  public period(startDate: Date, endDate: Date): string {
    if (startDate == null || endDate == null)
      return null;

    return `${this.datePipe.transform(startDate, "mediumDate")} - ${this.datePipe.transform(endDate, "mediumDate")}`;
  }

  public getLeaveYearName(leaveYearId: number): string {
    if (leaveYearId == null)
      return null;
    
    if (this.leaveYearOptions.find(ly => ly.id == leaveYearId) == null)
      return leaveYearId.toString();

    return this.leaveYearOptions.find(ly => ly.id == leaveYearId).description;
  }

  private setBusinessHours(cd: CaseDetails): void {
    let workdays = cd.leaveCalendar.workdays;

    if (workdays != null){
      let businessHours: number[] = [];

      if (workdays.sunday)
        businessHours.push(0);

      if (workdays.monday)
        businessHours.push(1);

      if (workdays.tuesday)
        businessHours.push(2);

      if (workdays.wednesday)
        businessHours.push(3);

      if (workdays.thursday)
        businessHours.push(4);

      if (workdays.friday)
        businessHours.push(5);

      if (workdays.saturday)
        businessHours.push(6);

      this.businessWorkingDays = businessHours;
    }   
  }
}
