import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionOption, StiiraError } from '@core/models';
import { ContactAdminForm, PostContactAdmin } from '@core/models/help-and-feedback/post-contact-admin.model';
import { ErrorService } from '@core/services';
import { HelpAndFeedbackService } from '@core/services/help-and-feedback.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { indicate, nameof } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';

@Component({
  selector: 'app-contact-admin',
  templateUrl: './contact-admin.component.html',
  styleUrls: ['./contact-admin.component.scss']
})
export class ContactAdminComponent implements OnInit {
  @ViewChild('imgFileInput') fileInput: ElementRef;
  public fileToUpload: File;
  public form: FormGroup<ContactAdminForm>;
  public isSaving$: Subject<boolean>;

  private formInitValues: any;

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get title(): FormControl<string> {
    return this.form.get(nameof<ContactAdminForm>('title')) as FormControl<string>;
  }

  get message(): FormControl<string> {
    return this.form.get(nameof<ContactAdminForm>('message')) as FormControl<string>;
  }

  get attachment(): FormControl<any> {
    return this.form.get(nameof<ContactAdminForm>('attachment')) as FormControl<any>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      admin: SelectionOption,
      sysText: any
    },
    private dialogRef: MatDialogRef<ContactAdminComponent>,
    private service: HelpAndFeedbackService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.isSaving$ = new Subject();
    this.form = this.fb.group<ContactAdminForm>({
        title: this.fb.control(null, Validators.required),
        message: this.fb.control(null, Validators.required),
        attachment: this.fb.control(null)
      })
   }

   ngOnInit(): void {
    this.formInitValues = { ...this.form.value };
  }

  onSubmit(): void {
    this.dialogRef.disableClose = true;

    const dto: PostContactAdmin = {
      adminId: +this.data.admin.id,
      title: this.title.value,
      message: this.message.value,
      attachment: this.fileToUpload ?? null
    };

    // convert dto into formData to send to backend
    const formData = this.makeContactAdminFormData(dto);

    this.service.postContactAdmin(formData)
      .pipe(
        indicate(this.isSaving$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
          this.dialogRef.close(res);
      },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
  }

  cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public clearFile(): void {
    this.fileToUpload = null;
    this.fileInput.nativeElement.value = "";
  }

  public handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.attachment.setErrors({"msError": null});
    this.form.updateValueAndValidity();
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.unsavedChanges,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private makeContactAdminFormData (dto: PostContactAdmin) {
    const formData = new FormData();
    formData.append(nameof<PostContactAdmin>('adminId'), dto.adminId.toString())
    formData.append(nameof<PostContactAdmin>('title'), dto.title)
    formData.append(nameof<PostContactAdmin>('message'), dto.message)
    formData.append(nameof<PostContactAdmin>('attachment'), dto.attachment)
    return formData;
  }
}
