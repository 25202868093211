import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CloseCase } from '@core/models/leave-admin/close-case.model';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { CloseCasePost } from '@core/models/leave-admin/close-case-post.model';
import { SnackbarService, NotificationService, ErrorService, LayoutService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { indicate } from '@shared/helpers';
import { finalize, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { StiiraError } from '@core/models';

@Component({
  selector: 'app-close-case',
  templateUrl: './close-case.component.html',
  styleUrls: ['./close-case.component.scss']
})
export class CloseCaseComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  public form: UntypedFormGroup;
  public isHandheld: boolean;
  public canClose: boolean;
  public isSaving$: Subject<boolean>;
  
  private formInitValues: any;
  private closeCasePost: CloseCasePost;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get closeReasonId(): FormControl {
    return this.form.controls.closeReasonId as FormControl;
  }

  get closeDate(): FormControl {
    return this.form.controls.closeDate as FormControl;
  }

  get comments(): FormControl {
    return this.form.controls.comments as FormControl;
  }

  get notifyEmployee(): FormControl {
    return this.form.controls.notifyEmployee as FormControl;
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { ccdata: CloseCase; sysText: any },
    private closeCaseDialogComponent: MatDialogRef<CloseCaseComponent>,
    private service: LeaveAdminService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private layoutService: LayoutService,
  ) {
    this.isSaving$ = new Subject();
    this.form = this.fb.group({
      caseId: [this.data.ccdata.caseId],
      closeReasonId: [null, Validators.required],
      closeDate: [null, Validators.required],
      comments: [null],
      notifyEmployee: false
    })
  }

  ngOnInit(): void {
    this.formInitValues = { ...this.form.value };
    this.canClose = this.data.ccdata.canClose;

    this.comments.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=> {
        if (!this.noChanges) {
          this.isEditing.emit(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public closeCase(): void {
    this.closeCaseDialogComponent.disableClose = true;

    this.closeCasePost = {
      caseId: this.data.ccdata.caseId,
      closeDate: this.closeDate.value,
      closeReasonId: this.closeReasonId.value,
      comments: this.comments.value,
      notifyEmployee: this.notifyEmployee.value
      };

    this.service.postCloseCase(this.closeCasePost)
      .pipe(
        indicate(this.isSaving$),
        finalize(() => {
          this.closeCaseDialogComponent.disableClose = false;
      }))
      .subscribe(
        (res) => {
          this.closeCaseDialogComponent.close(res.leaveCaseDetails);

          if (res?.emailDelivered == true) {
            let snackText =  this.data.sysText.closeCase.caseClosedWithEmail;
            snackText = snackText.replace('@[recipient]', res.recipient);
            // todo: static text Dismiss should be systext
            this.snackbar.open(snackText, 'Dismiss');
          }
          else if (res?.showEmailMessage == true) {
            let toastText =  this.data.sysText.closeCase.caseClosedWithoutEmail;
            this.notificationService.showErrorToast(toastText);
          }
        },
        (err: StiiraError) => {
            this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors);
          }
      );
  }

  public cancel(): void {
    if (this.noChanges) {
      this.closeCaseDialogComponent.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.sysText.leavePage,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.closeCaseDialogComponent.close();
        }
      });
  }

  public closeReasonChangeHandler (): void {
    this.isEditing.emit(true);
  }

  public closeDateChangeHandler(): void {
    this.isEditing.emit(true);
  }
}