<ng-container [formGroup]="group" *ngIf="group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-container *ngIf="!group">
  <ng-container *ngTemplateOutlet="formField"></ng-container>
</ng-container>

<ng-template #formField>
  <div class="container">
    <mat-icon [ngClass]="{ focus: input.focused }" *ngIf="!hideIconSpace">{{ icon }}</mat-icon>
    <mat-form-field color="accent">
      <mat-label>{{ label | titlecase }}</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="control" #input="matInput" (dateInput)="onEmitDateChange($event.value)" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [touchUi]="isTouchDevice"></mat-datepicker>
      <mat-hint [ngClass]="{ highlight: isHintHighlighted, warn: isHintWarning }">{{ hint }}</mat-hint>
      <mat-error *ngIf="control.errors?.msError">{{ control.errors?.msError == msErrorKey ? customMsError : control.errors?.msError }}</mat-error>
      <mat-error *ngIf="control.errors?.required && !control.errors?.matDatepickerParse">{{ sysText.required | capitalizefirst }}</mat-error>
      <mat-error *ngIf="control.errors?.matDatepickerParse">{{ sysText.invalidDateError }}</mat-error>
    </mat-form-field>
    <mat-icon *ngIf="info" [matTooltip]="info" [matTooltipShowDelay]="500" matTooltipPosition="above" class="info-icon">info_outline</mat-icon>
  </div>
</ng-template>
