import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReopenCase, ReopenCaseForm, StiiraError } from '@core/models';
import { ReopenCasePost } from '@core/models/leave-admin/reopen-case-post.model';
import { SnackbarService, NotificationService, ErrorService, LayoutService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';

@Component({
  selector: 'app-reopen-case',
  templateUrl: './reopen-case.component.html',
  styleUrls: ['./reopen-case.component.scss']
})
export class ReopenCaseComponent implements OnInit {
    @Output() isEditing = new EventEmitter<boolean>();
    @Output() createNew = new EventEmitter<number>();
    public form: FormGroup<ReopenCaseForm>;
    public reopenCasePost: ReopenCasePost;
    public isSaving$: Subject<boolean>;
    
    private  formInitValues: any;
    private destroy$: Subject<void> = new Subject<void>();

    get noChanges(): boolean {
      return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
    }
  
    get formIsValid(): boolean {
      return this.form.valid;
    }
  
    get notifyEmployee(): FormControl {
      return this.form.controls.notifyEmployee as FormControl;
    }

    get isMobile(): boolean {
      return this.layoutService.isMobile;
    }
    
    constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: { rcdata: ReopenCase; sysText: any },
      private reopenCaseDialogComponent: MatDialogRef<ReopenCaseComponent>,
      private service: LeaveAdminService,
      private dialog: MatDialog,
      private fb: FormBuilder,
      private snackbar: SnackbarService,
      private notificationService: NotificationService,
      private errorService: ErrorService,
      private layoutService: LayoutService
      ) { 
      this.isSaving$ = new Subject();
      this.form = this.fb.group<ReopenCaseForm>({
          caseId: this.fb.control(this.data.rcdata.caseId),
          notifyEmployee: this.fb.control(false)
        })
    }
  
    ngOnInit(): void {
      this.formInitValues = { ...this.form.value };
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }
  
    public reopenCase(): void {
      this.reopenCaseDialogComponent.disableClose = true;

      this.reopenCasePost = {
        caseId: this.data.rcdata.caseId,
        notifyEmployee: this.notifyEmployee.value
        };

      this.service.postReopenCase(this.reopenCasePost)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
              this.reopenCaseDialogComponent.disableClose = false;
        }))
        .subscribe(
          (res) => {
            this.reopenCaseDialogComponent.close(res.leaveCaseDetails);

            if (res?.emailDelivered == true) {
              let snackText =  this.data.sysText.reopenCase.caseReopenedWithEmail;
              snackText = snackText.replace('@[recipient]', res.recipient);
              // todo: static text Dismiss should be systext
              this.snackbar.open(snackText, 'Dismiss');
            }
            else if (res?.showEmailMessage == true) {
              let toastText =  this.data.sysText.reopenCase.caseReopenedWithoutEmail;
              this.notificationService.showErrorToast(toastText);
            }},
          (err: StiiraError) => 
            this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
    }
  
    public cancel(): void {
      if (this.noChanges)
        this.reopenCaseDialogComponent.close();
      else
        this.openUnsavedChangesDialog();
    }

    private openUnsavedChangesDialog(): void {
      const dialogConfig: MatDialogConfig = {
        width: '300px',
        data: this.data.sysText.leavePage,
      };
      
      this.dialog.open(UnsavedChangesComponent, dialogConfig)
        .beforeClosed()
        .subscribe(
          (res: boolean) => {
            if (res)
              this.reopenCaseDialogComponent.close();
            });
    }
  }
