<div class="dialog--title">
  <h6>
    {{ data.category == null ? data.sysText.addDocCategory_header : data.sysText.editDocCategory_header }}
  </h6>
</div>
<mat-dialog-content>
  <form [formGroup]="form">
    <app-input-field
        [control]="form.controls.categoryName"
        [icon]="'subject'"
        [label]="data.sysText.categoryName"
        [inputType]="'string'"
        [hint]="data.category?.isSystemCategory == true ? data.sysText.systemCategoryNameMessage : 
        this.data?.category?.isStockCategory == true ? data.sysText.stockCategoryNameMessage : ''"
    ></app-input-field>
    <div *ngIf="data.dialogData.showOwner">
      <app-select-field
        *ngIf="this.data.dialogData.canChangeOwner; else cantChangeOwner"
          [control]="form.controls.ownerEmployerId"
          [label]="data.sysText.owner"
          [options]="data.dialogData.employers"
          [icon]="'business'"
          [shouldCapitalize]="false"
      ></app-select-field>
      <ng-template #cantChangeOwner>
        <app-input-field
          [control]="form.controls.ownerEmployerId"
          [label]="data.sysText.owner"
          [icon]="'business'"
          [hint]="data.category?.isSystemCategory == true ? data.sysText.systemCategoryOwnerMessage : 
          this.data?.category?.isStockCategory == true ? data.sysText.stockCategoryOwnerMessage :
            data.dialogData.inUse ? data.sysText.inUseOwnerMessage : ''"
        ></app-input-field>
      </ng-template>
    </div>
    <app-slide-toggle
        [control]="form.controls.allowUse"
        [label]="data.sysText.allowUseMessage">
    </app-slide-toggle>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSubmitting$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()">
    {{ data.sysText.cancel }}
  </button>
  <button mat-stroked-button class="button" color="accent" (click)="onSubmit()">
    {{ data.category == null ? data.sysText.add : data.sysText.save }}
  </button>
  
</mat-dialog-actions>
<ng-template #submitting>
  <mat-dialog-actions align="end">
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
