<div class="dialog--title">
  <h6 class="grey1">{{ data.sysText.hideCase.hideCaseTitle | titlecase }}</h6>
  <ng-container *ngIf="isMobile">
    <span class="spacer"></span>
    <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
  </ng-container>
</div>
  
<mat-dialog-content class="mat-dialog-content">
  <p class="subtitle1 dialog-message">
    {{ data.sysText.hideCase.hideCaseMessage }}
  </p>
  <form [formGroup]="form">
    <app-select-field
      [control]="this.form.controls.hideReasonId"
      [icon]="'help_center'"
      [label]="data.sysText.hideCase.hideReasonCaption"
      [options]="hideReasonOptions"
      (emitSelectionChange)="hideReasonChangeHandler()"
    ></app-select-field>

    <app-input-field
      [control]="this.form.controls.comments"
      [label]="data.sysText.hideCase.hideCommentsCaption"
      [inputType]="'string'"
      [icon]="'comment'"
    ></app-input-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="!(isSaving$ | async); else saving">
    <button
      mat-stroked-button
      class="button"
      color="accent"
      (click)="cancel()"
      [disabled]="isSaving$ | async"
      *ngIf="!isMobile"
    >
      {{ data.sysText.hideCase.cancel }}
    </button>

    <button mat-stroked-button class="button" color="accent" (click)="hideCase()" [disabled]="(isSaving$ | async)">
      {{ data.sysText.hideCase.hideCaseButtonCaption }}
    </button>
  </ng-container>

  <ng-template #saving>
    <button mat-button class="button" type="button" color="accent" disabled>
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
