import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseDetails, StiiraError } from '@core/models';
import { EmployeeLeaveHoursExtended, LeaveCalendarHoursForm, LeaveCalendarHoursPost } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { LayoutService, ErrorService } from '@core/services';
import { LeaveAdminStoreService } from '@core/services/leave-admin-store.service';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { EventApi } from '@fullcalendar/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-calendar-time',
  templateUrl: './edit-calendar-time.component.html',
  styleUrls: ['./edit-calendar-time.component.scss']
})
export class EditCalendarTimeComponent implements OnInit {
  public form: FormGroup<LeaveCalendarHoursForm>;
  public isSubmitting$: Subject<boolean> = new Subject<boolean>();

  private formInitValues: any;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get isHandheld(): boolean {
    return this.layoutService.isHandheld;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      event: EventApi,
      caseDetails: CaseDetails,
      hoursExtended: EmployeeLeaveHoursExtended,
      sysText: any,
      unsavedChangesSystext: any
    },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditCalendarTimeComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    private service: LeaveAdminService,
    private store: LeaveAdminStoreService,
  ) { 
    this.form = this.fb.group<LeaveCalendarHoursForm>({
      hours: this.fb.control(null, [Validators.required, Validators.pattern(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/)])
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      hours: this.data.event.extendedProps.numberHours
    })
    this.formInitValues = { ...this.form.value };
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        this.checkHours();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(canNavigate: boolean): void {
    if (canNavigate) {
      this.dialogRef.close();
    } else {
      if (this.noChanges) {
        this.dialogRef.close();
      } else {
        this.openUnsavedChangesDialog();
      }
    }
  }

  public onSubmit(): void {
    const dto: LeaveCalendarHoursPost = {
      caseId: this.data.caseDetails.leaveInformation.caseId,
      hoursId: this.data.event.extendedProps.dateId,
      calendarDay: this.data.event.start,
      hours: this.form.controls.hours.value
    };    
    this.service.postLeaveCalendarHours(dto)
      .pipe(indicate(this.isSubmitting$))
      .subscribe((res)=>{
        this.dialogRef.close(res);
    },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
    
  }

  public onDelete(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      disableClose: false,
      closeOnNavigation: true,
      data: { sysText: this.store.sysText.deleteConfirmation }
    };

    this.dialog.open(DeleteConfirmationComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.service.postLeaveCalendarDayDelete({id: this.data.event.extendedProps.dateId})
            .pipe(indicate(this.isSubmitting$))
            .subscribe(res => {
              this.dialogRef.close(res)
            });
        }
      });
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSystext,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private checkHours(): void {
    if ((this.form.controls.hours.value > 24)) {
      this.form.controls.hours.setErrors({ 'hoursGreaterThan24': true });
    };
  }

}
