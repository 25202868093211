import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(private bpObserver: BreakpointObserver) {}

  isHandheld$(): Observable<boolean> {
    return this.bpObserver.observe('(max-width: 768px)').pipe(map(res => res.matches));
  }

  isMobile$(): Observable<boolean> {
    return this.bpObserver.observe('(max-width: 540px)').pipe(map(res => res.matches));
  }

  isCondensed$(): Observable<boolean> {
    return this.bpObserver.observe('(max-width: 1200px)').pipe(map(res => res.matches));
  }

  is1000pxOrLess$(): Observable<boolean> {
    return this.bpObserver.observe('(max-width: 1000px)').pipe(map(res => res.matches));
  }

  get isHandheld(): boolean {
    return this.bpObserver.isMatched('(max-width: 768px)');
  }

  get isMobile(): boolean {
    return this.bpObserver.isMatched('(max-width: 540px)');
  }

  get isCondensed(): boolean {
    return this.bpObserver.isMatched('(max-width: 1200px)');
  }
}
