import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StiiraError } from '@core/models';
import { NewEmployerPost } from '@core/models/leave-admin/employers/new-employer-post.model';
import { ErrorService } from '@core/services';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { indicate } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-employer',
  templateUrl: './add-new-employer.component.html',
  styleUrls: ['./add-new-employer.component.scss']
})
export class AddNewEmployerComponent implements OnInit {
  public form: UntypedFormGroup;

  isSubmitting$: Subject<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sysText: any },
    private service: ManageEmployersService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewEmployerComponent>
  ) {
    this.isSubmitting$ = new Subject();
    this.form = this.fb.group({
      companyName: [null, Validators.required]
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.dialogRef.disableClose = true;
    let newEmployerPost: NewEmployerPost = {
      companyName: this.form.controls.companyName.value
    }
    this.service
      .postNewEmployer(newEmployerPost)
      .pipe(
        indicate(this.isSubmitting$),
        finalize(() => {
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe((res) => {
        this.dialogRef.close(res);
      }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

}
