import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { TemplateDetails } from '@core/models/case-communication/template-details.model';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { ErrorService } from '@core/services';
import { indicate, removePlaceholderChips } from '@shared/helpers';
import { ConstantsService } from '@core/services';
import { finalize, takeUntil } from 'rxjs/operators';
import { StiiraError } from '@core/models';
import { EmailContentForm, EmailContentPost } from '@core/models/case-communication/email-content-form.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PlaceholderOption } from '@core/models/case-communication/placeholder-option.model';
import { EmailBodyEditorComponent } from '@modules/case-communication/components/email-body-editor/email-body-editor.component';
import { TemplateDetailsStoreService } from '@core/services/template-details-store.service';
import { EmailSubjectEditorComponent } from '@modules/case-communication/components/email-subject-editor/email-subject-editor.component';

@Component({
  selector: 'app-edit-email-content',
  templateUrl: './edit-email-content.component.html',
  styleUrls: ['./edit-email-content.component.scss']
})
export class EditEmailContentComponent implements OnInit {
  @Output() isEditing = new EventEmitter<boolean>();
  @ViewChild(EmailSubjectEditorComponent) subjectEditor: EmailSubjectEditorComponent;
  @ViewChild(EmailBodyEditorComponent) bodyEditor: EmailBodyEditorComponent;
  
  public form: FormGroup<EmailContentForm>;
  public isHandheld: boolean;
  public formInitValues: any;
  public isSaving$: Subject<boolean>;
  
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.formInitValues);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      templateDetails: TemplateDetails;
      placeholderOptions: PlaceholderOption[];
      sysText: any;
      unsavedChangesSysText: any
    },
    private emailContentDialogRef: MatDialogRef<EditEmailContentComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private caseCommunicationService: CaseCommunicationService,
    private store: TemplateDetailsStoreService,
    private errorService: ErrorService,
    private constants: ConstantsService
    ) { 
    this.isSaving$ = new Subject();
    this.form = this.fb.group<EmailContentForm>({
      subject: this.fb.control(null),
      body: this.fb.control(null)
    })
}

  ngOnInit(): void {
    this.form.patchValue({
      subject: this.data.templateDetails.subjectHtml,
      body: this.data.templateDetails.bodyHtml
    });

    this.setFormErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setFormErrors() {
    setTimeout(()=>{
      this.errorService.setFormModelStateErrors(this.form, this.data.templateDetails.missingDetails)
      this.formInitValues = { ...this.form.value };
      this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.formChangeEmitted && !this.noChanges) {
            this.isEditing.emit(true);
            this.formChangeEmitted = true;
          } else if (this.noChanges) {
            this.isEditing.emit(false);
            this.formChangeEmitted = false;
          }
        });
    },500);
  }

  public save(): void {
    this.emailContentDialogRef.disableClose = true;

    // to get the subject and body in text form, start with 
    // the html, perform the chip replacement, set the content 
    // to the modified content, get the text, then restore the 
    // original html 

    let subjectHtml = this.subjectEditor.subEditor.editor.getContent();
    let subjectChipless = removePlaceholderChips(subjectHtml, this.constants.EDITOR_CHIP, this.data.placeholderOptions);
    this.subjectEditor.subEditor.editor.setContent(subjectChipless);
    let subjectText = this.subjectEditor.getEditorText();
    this.subjectEditor.subEditor.editor.setContent(subjectHtml);

    let bodyHtml = this.bodyEditor.bodyEditor.editor.getContent();
    let bodyChipless = removePlaceholderChips(bodyHtml, this.constants.EDITOR_CHIP, this.data.placeholderOptions);
    this.bodyEditor.bodyEditor.editor.setContent(bodyChipless);
    let bodyText = this.bodyEditor.getEditorText();
    this.bodyEditor.bodyEditor.editor.setContent(bodyHtml);

    const dto: EmailContentPost = {
      templateId: this.data.templateDetails.id,
      subject: subjectText,
      body: bodyChipless,
      bodyText: bodyText,
    }
      this.caseCommunicationService.
        postTemplateEmailContent(dto)
        .pipe(
          indicate(this.isSaving$),
          finalize(() => {
            this.emailContentDialogRef.disableClose = false;
          })
        )
        .subscribe((res) => {
          this.store.templateDetails = res as TemplateDetails;
          this.emailContentDialogRef.close(res);
        }, (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors));
  }

  public cancel(): void {
    if (this.noChanges) {
      this.emailContentDialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.emailContentDialogRef.close();
        }
      });
  }
}