import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeWithRoles } from '@core/models';

@Component({
  selector: 'app-view-employee-roles',
  templateUrl: './view-employee-roles.component.html',
  styleUrls: ['./view-employee-roles.component.scss']
})
export class ViewEmployeeRolesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { showEmployer: boolean, ee: EmployeeWithRoles, sysText: any;  },
  ) { }

  ngOnInit(): void {
  }

}
