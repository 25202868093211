import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentUploadMode } from '@core/enums/document-upload-mode.enum';
import { PostRecertDocument, PostRecertDocumentValidate, StiiraError } from '@core/models';
import { DocumentUploadDialog, PostDocumentUpload, PostDocumentUploadForm, PostEmployeeDocumentUpload, PostEmployerDocumentUpload } from '@core/models/leave-admin/document-upload';
import { ErrorService, SnackbarService } from '@core/services';
import { LeaveAdminService } from '@core/services/leave-admin.service';
import { ManageEmployeesService } from '@core/services/manage-employees.service';
import { ManageEmployersService } from '@core/services/manage-employers.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { nameof, indicate, noChangesReplacer } from '@shared/helpers';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnsavedChangesComponent } from '../unsaved-changes/unsaved-changes.component';
import { CaseCommunicationService } from '@core/services/case-communication.service';
import { PostTemplateDocumentUpload } from '@core/models/case-communication/template-document-upload.model';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild('imgFileInput') fileInput: ElementRef;
  @Output() isEditing = new EventEmitter<boolean>();

  public fileToUpload: File;
  public form: FormGroup<PostDocumentUploadForm>;
  public isHandheld: boolean;
  public isSaving$: Subject<boolean>;
  public docUploadMode = DocumentUploadMode;

  private formInitValues: any;
  private formChangeEmitted: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  get noChanges(): boolean {
    return JSON.stringify(this.form.value, noChangesReplacer) === JSON.stringify(this.formInitValues, noChangesReplacer);
  }

  get formIsValid(): boolean {
    return this.form.valid;
  }

  get document(): FormControl<any> {
    return this.form.get(nameof<PostDocumentUploadForm>('document')) as FormControl<any>;
  }

  get documentTitle(): FormControl<string> {
    return this.form.get(nameof<PostDocumentUploadForm>('documentTitle')) as FormControl<string>;
  }

  get documentCategoryId(): FormControl<number> {
    return this.form.get(nameof<PostDocumentUploadForm>('documentCategoryId')) as FormControl<number>;
  }

  get documentComments(): FormControl<string> {
    return this.form.get(nameof<PostDocumentUploadForm>('documentComments')) as FormControl<string>;
  }

  get associatedCase(): FormControl<number> {
    return this.form.get(nameof<PostDocumentUploadForm>('associatedCase')) as FormControl<number>;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      subjectId: number,
      docUploadDialog: DocumentUploadDialog;
      sysText: any;
      unsavedChangesSysText: any;
      mode: DocumentUploadMode;
    },
    private dialogRef: MatDialogRef<DocumentUploadComponent>,
    private leaveAdminService: LeaveAdminService,
    private manageEmployersService: ManageEmployersService,
    private manageEmployeesService: ManageEmployeesService,
    private caseCommunicationService: CaseCommunicationService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackbar: SnackbarService
  ) {
    this.isSaving$ = new Subject();
    this.form = this.fb.group<PostDocumentUploadForm>({
        document: this.fb.control(null),
        documentTitle: this.fb.control(null, Validators.required),
        documentCategoryId: this.fb.control(null, Validators.required),
        documentComments: this.fb.control(null),
        associatedCase: this.fb.control(null)
      });
   }

  ngOnInit(): void {
    this.formInitValues = { ...this.form.value };

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(()=>{
        if (!this.noChanges && !this.formChangeEmitted) {
          this.isEditing.emit(true);
          this.formChangeEmitted = true;
        } else if (this.noChanges) {
          this.isEditing.emit(false);
          this.formChangeEmitted = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.dialogRef.disableClose = true;

    switch(this.data.mode) {
      case DocumentUploadMode.LeaveCase: {
        const dto: PostDocumentUpload = {
          caseId: this.data.subjectId,
          document: this.fileToUpload ?? null,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        };
    
        const formData = this.makePostDocumentUploadFormData(dto);
    
        this.leaveAdminService.postDocumentUpload(formData)
          .pipe(
            indicate(this.isSaving$),
            finalize(() => {
              this.dialogRef.disableClose = false;
            })
          )
          .subscribe((res) => {
              this.dialogRef.close(res);
              if (res) {
                let snackText =  this.data.sysText.successSnackBar;
                this.snackbar.open(snackText, this.data.sysText.dismiss);
              }
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
        break;
      }
      case DocumentUploadMode.Employer: {
        const dto: PostEmployerDocumentUpload = {
          employerId: this.data.subjectId,
          document: this.fileToUpload ?? null,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        };
    
        const formData = this.makePostEmployerDocumentUploadFormData(dto);
    
        this.manageEmployersService.postDocumentUpload(formData)
          .pipe(
            indicate(this.isSaving$),
            finalize(() => {
              this.dialogRef.disableClose = false;
            })
          )
          .subscribe((res) => {
              this.dialogRef.close(res);
              if (res) {
                let snackText =  this.data.sysText.successSnackBar;
                this.snackbar.open(snackText, this.data.sysText.dismiss);
              }
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
        break;
      }
      case DocumentUploadMode.Employee: {
        const dto: PostEmployeeDocumentUpload = {
          employeeId: this.data.subjectId,
          document: this.fileToUpload ?? null,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value,
          associatedCase: this.associatedCase.value
        };
    
        const formData = this.makePostEmployeeDocumentUploadFormData(dto);
    
        this.manageEmployeesService.postDocumentUpload(formData)
          .pipe(
            indicate(this.isSaving$),
            finalize(() => {
              this.dialogRef.disableClose = false;
            })
          )
          .subscribe((res) => {
              this.dialogRef.close(res);
              if (res) {
                let snackText =  this.data.sysText.successSnackBar;
                this.snackbar.open(snackText, this.data.sysText.dismiss);
              }
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
        break;
      }
      case DocumentUploadMode.Template: {
        const dto: PostTemplateDocumentUpload = {
          templateId: this.data.subjectId,
          document: this.fileToUpload ?? null,
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        };
    
        const formData = this.makePostTemplateDocumentUploadFormData(dto);
    
        this.caseCommunicationService.postTemplateDocumentUpload(formData)
          .pipe(
            indicate(this.isSaving$),
            finalize(() => {
              this.dialogRef.disableClose = false;
            })
          )
          .subscribe((res) => {
              this.dialogRef.close(res);
              if (res) {
                let snackText =  this.data.sysText.successSnackBar;
                this.snackbar.open(snackText, this.data.sysText.dismiss);
              }
          },(err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors))
        break;
      }
      case DocumentUploadMode.Recert: {
        const dto: PostRecertDocumentValidate = {
          hasFile: !!this.fileToUpload, // just need to know if a file was selected
          documentTitle: this.documentTitle.value,
          documentCategoryId: this.documentCategoryId.value,
          documentComments: this.documentComments.value
        }

        const formData = this.makePostRecertDocumentValidateFormData(dto);

        this.leaveAdminService.postRecertDocumentValidate(formData)
          .pipe(
            indicate(this.isSaving$),
            finalize(() => { this.dialogRef.disableClose = false; })
          )
          .subscribe(
            (res) => {
              const dto: PostRecertDocument = {
                document: this.fileToUpload,
                documentTitle: this.documentTitle.value,
                documentCategoryId: this.documentCategoryId.value,
                documentComments: this.documentComments.value,
                modelStateErrors: null
              };

              this.dialogRef.close(dto);
              if (res) {
                let snackText =  this.data.sysText.successSnackBar;
                this.snackbar.open(snackText, this.data.sysText.dismiss);
              }
            },
            (err: StiiraError) => this.errorService.setFormModelStateErrors(this.form, err.modelStateErrors)
          );

        break;
      }
    }
  }

  cancel(): void {
    if (this.noChanges) {
      this.dialogRef.close();
    } else {
      this.openUnsavedChangesDialog();
    }
  }

  public clearFile(): void {
    this.fileToUpload = null;
    this.form.patchValue({
      documentTitle: null,
      documentCategoryId: null,
    });
    this.fileInput.nativeElement.value = "";
    this.documentTitle.setValidators(null)
    this.documentCategoryId.setValidators(null)
    this.form.updateValueAndValidity();
  }

  public handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.document.setErrors({"msError": null});
    this.documentTitle.setValidators(Validators.required)
    this.documentCategoryId.setValidators(Validators.required)
    this.form.updateValueAndValidity();
  }

  private openUnsavedChangesDialog(): void {
    const dialogConfig: MatDialogConfig = {
      width: '300px',
      data: this.data.unsavedChangesSysText,
    };
    
    this.dialog.open(UnsavedChangesComponent, dialogConfig)
      .beforeClosed().subscribe((res: boolean) => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

  private makePostDocumentUploadFormData (dto: PostDocumentUpload) {
    const formData = new FormData();
    formData.append(nameof<PostDocumentUpload>('caseId'), dto.caseId.toString())
    formData.append(nameof<PostDocumentUpload>('document'), dto.document)
    formData.append(nameof<PostDocumentUpload>('documentTitle'), dto.documentTitle)
    formData.append(nameof<PostDocumentUpload>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null)
    formData.append(nameof<PostDocumentUpload>('documentComments'), dto.documentComments)

    return formData;
  }

  private makePostEmployerDocumentUploadFormData (dto: PostEmployerDocumentUpload) {
    const formData = new FormData();
    formData.append(nameof<PostEmployerDocumentUpload>('employerId'), dto.employerId.toString())
    formData.append(nameof<PostEmployerDocumentUpload>('document'), dto.document)
    formData.append(nameof<PostEmployerDocumentUpload>('documentTitle'), dto.documentTitle)
    formData.append(nameof<PostEmployerDocumentUpload>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null)
    formData.append(nameof<PostEmployerDocumentUpload>('documentComments'), dto.documentComments)

    return formData;
  }

  private makePostEmployeeDocumentUploadFormData (dto: PostEmployeeDocumentUpload) {
    const formData = new FormData();
    formData.append(nameof<PostEmployeeDocumentUpload>('employeeId'), dto.employeeId.toString())
    formData.append(nameof<PostEmployeeDocumentUpload>('document'), dto.document)
    formData.append(nameof<PostEmployeeDocumentUpload>('documentTitle'), dto.documentTitle)
    formData.append(nameof<PostEmployeeDocumentUpload>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null)
    formData.append(nameof<PostEmployeeDocumentUpload>('documentComments'), dto.documentComments)
    formData.append(nameof<PostEmployeeDocumentUpload>('associatedCase'), dto.associatedCase ? dto.associatedCase.toString() : null)

    return formData;
  }

  private makePostTemplateDocumentUploadFormData (dto: PostTemplateDocumentUpload) {
    const formData = new FormData();
    formData.append(nameof<PostTemplateDocumentUpload>('templateId'), dto.templateId.toString())
    formData.append(nameof<PostTemplateDocumentUpload>('document'), dto.document)
    formData.append(nameof<PostTemplateDocumentUpload>('documentTitle'), dto.documentTitle)
    formData.append(nameof<PostTemplateDocumentUpload>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null)
    formData.append(nameof<PostTemplateDocumentUpload>('documentComments'), dto.documentComments)

    return formData;
  }

  private makePostRecertDocumentValidateFormData (dto: PostRecertDocumentValidate) {
    const formData = new FormData();
    formData.append(nameof<PostRecertDocumentValidate>('hasFile'), dto.hasFile.toString())
    formData.append(nameof<PostRecertDocumentValidate>('documentTitle'), dto.documentTitle)
    formData.append(nameof<PostRecertDocumentValidate>('documentCategoryId'), dto.documentCategoryId ? dto.documentCategoryId.toString() : null)
    formData.append(nameof<PostRecertDocumentValidate>('documentComments'), dto.documentComments)

    return formData;
  }
}
