import { Injectable } from '@angular/core';
import { ManageEmployeesDashboardStore } from '@core/models/leave-admin/employees/manage-employees-dashboard-store.model';
import { ManageEmployees } from '@core/models/leave-admin/employees/manage-employees.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeesDashboardStoreService {

  private readonly _initialStoreData: ManageEmployeesDashboardStore = {
    manageEmployees: null,
    sysText: null
  };

  private manageEmployeesDashboardStore: BehaviorSubject<ManageEmployeesDashboardStore>;

  private store$: Observable<ManageEmployeesDashboardStore>;

  private readonly templateNames = {
    manageEmployees: "manageEmployees",
    unsavedChanges: "unsavedChanges",
    addNewEmployee: "addNewEmployee",
    mergeDuplicates: "mergeDuplicates",
    duplicateDetected: "duplicateDetected",
    leaveCalendarUpdateConfirmation: "leaveCalendarUpdateConfirmation"
  };

  constructor() { 
    this.manageEmployeesDashboardStore = new BehaviorSubject(this._initialStoreData);
    this.store$ = this.manageEmployeesDashboardStore.asObservable();
  }

  public get templates() {
    return this.templateNames;
  }
  
  public get store() {
    return this.manageEmployeesDashboardStore.value;
  }

  public get manageEmployees$(): Observable<ManageEmployees> {
    return this.store$.pipe(pluck('manageEmployees'));
  }

  public get manageEmployees() {
    return this.store.manageEmployees;
  }

  public set manageEmployees(manageEmployees: ManageEmployees) {
    const store = { ...this.store, manageEmployees };
    this.manageEmployeesDashboardStore.next(store);
  }

  public get sysText() {
    return this.store.sysText;
  }

  public set sysText(sysText: any) {
    const store = { ...this.store, sysText };
    this.manageEmployeesDashboardStore.next(store);
  }

  public unloadStore() {
    const store: ManageEmployeesDashboardStore = {
      manageEmployees: null,
      sysText: null
    }
    this.manageEmployeesDashboardStore.next(store)
  }
}
