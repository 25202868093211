import { CalendarWarnMessages } from '@core/enums/calendar-warn-messages.enum';
import { EmployeeLeaveHoursExtended } from '@core/models/leave-admin/leave-calendar/leave-calendar.model';
import { Workdays } from '@core/models/leave-admin/leave-calendar/work-days.model';
import { nameof } from './typescript.helpers';

export interface EmployeeCalendarRequiredValues {
	newLeaveYearFixedStart: string
	prevLeaveYearFixedStart: string
	newHireDate: Date
	prevHireDate: Date
	newHoursPerWeek: number | string
	prevHoursPerWeek: number | string
	newWorkdays: string
	prevWorkdays: string

	newFmlaTotalHours: number | string
	prevFmlaTotalHours: number | string

	newStateTotalHours: number | string
	prevStateTotalHours: number | string

	newPloTotalHours: number | string
	prevPloTotalHours: number | string
}

export interface LeaveCaseCalendarRequiredValues {
	newStartDate: Date
	prevStartDate: Date
	newEndDate: Date
	prevEndDate: Date
	newLeaveTypeId: string
	prevLeaveTypeId: string

	newDrawFromFmla: boolean
	prevDrawFromFmla: boolean

	newDrawFromState: boolean
	prevDrawFromState: boolean
	
	newDrawFromPlo: boolean
	prevDrawFromPlo: boolean
}

function employeeRequiredValuesSupplied(ecrv: EmployeeCalendarRequiredValues): boolean {
	return ((ecrv.newHoursPerWeek != null && ecrv.newHoursPerWeek != '')
		&& ((ecrv.newFmlaTotalHours != null && ecrv.newFmlaTotalHours != '') 
			|| (ecrv.newStateTotalHours != null && ecrv.newStateTotalHours != '')
			|| (ecrv.newPloTotalHours != null && ecrv.newPloTotalHours != '')));
}

function employeeRequiredValuesPreviouslySupplied(ecrv: EmployeeCalendarRequiredValues): boolean {
	return (ecrv.prevHoursPerWeek != null
		&& (ecrv.prevFmlaTotalHours != null 
			|| ecrv.prevStateTotalHours != null
			|| ecrv.prevPloTotalHours != null));
}

function employeeRequiredValuesChanged(ecrv: EmployeeCalendarRequiredValues): boolean {
	return ((ecrv.prevHoursPerWeek != ecrv.newHoursPerWeek)
		|| (ecrv.prevWorkdays != ecrv.newWorkdays)
		|| (ecrv.prevFmlaTotalHours != ecrv.newFmlaTotalHours)
		|| (ecrv.prevStateTotalHours != ecrv.newStateTotalHours)
		|| (ecrv.prevPloTotalHours != ecrv.newPloTotalHours));
}

function caseRequiredValuesSupplied(lccrv: LeaveCaseCalendarRequiredValues, ecrv: EmployeeCalendarRequiredValues): boolean {
	return lccrv.newStartDate != null
		&& lccrv.newEndDate != null
		&& lccrv.newLeaveTypeId != null
		&& (!lccrv.newDrawFromFmla || ecrv.newFmlaTotalHours != null)
		&& (!lccrv.newDrawFromState || ecrv.newStateTotalHours != null)
		&& (!lccrv.newDrawFromPlo || ecrv.newPloTotalHours != null);
}

function caseRequiredValuesPreviouslySupplied(lccrv: LeaveCaseCalendarRequiredValues, ecrv: EmployeeCalendarRequiredValues): boolean {
	return lccrv.prevStartDate != null
		&& lccrv.prevEndDate != null
		&& lccrv.prevLeaveTypeId != null
		&& (!lccrv.prevDrawFromFmla || ecrv.prevFmlaTotalHours != null)
		&& (!lccrv.prevDrawFromState || ecrv.prevStateTotalHours != null)
		&& (!lccrv.prevDrawFromPlo || ecrv.prevPloTotalHours != null);
}

function caseRequiredValuesChanged(lccrv: LeaveCaseCalendarRequiredValues): boolean {
	return lccrv.prevStartDate != lccrv.newStartDate
		|| lccrv.prevEndDate != lccrv.newEndDate
		|| lccrv.prevLeaveTypeId != lccrv.newLeaveTypeId;
}

export function showEmployeeCalendarWarning(ecrv: EmployeeCalendarRequiredValues, elhe: EmployeeLeaveHoursExtended[] = null): CalendarWarnMessages[] {
	const eeReqValsSupplied = employeeRequiredValuesSupplied(ecrv);
	const eeReqValsPrevSupplied = employeeRequiredValuesPreviouslySupplied(ecrv);
	const eeReqValsChanged = employeeRequiredValuesChanged(ecrv);

	let warns: CalendarWarnMessages[] = [];

	if (eeReqValsSupplied && (!eeReqValsPrevSupplied || eeReqValsChanged)) {
		warns.push(CalendarWarnMessages.EmployeeCasesImpacted);
	}
	
	if (!eeReqValsSupplied && eeReqValsPrevSupplied) {
		warns.push(CalendarWarnMessages.EmployeeCasesInaccessible);
	}

	let workdays: string = ecrv.newWorkdays?.toLowerCase();;

	if (elhe?.length > 0
		&& elhe.some(xhours => xhours.leaveCaseHoursID != null)
		&& (workdays == null
			|| workdays?.length == 0
			|| elhe.some(xhours => 
				xhours.leaveCaseHoursID != null
				&& !workdays.includes((new Date(xhours.calendarDate)).toLocaleDateString("en-US", { weekday: "long" }).toLowerCase()))))
		warns.push(CalendarWarnMessages.LeavesOnNonWorkdays);

	if (warns.length == 0)
		warns = null;

	return warns;
}

export function showLeaveCaseCalendarWarning(lccrv: LeaveCaseCalendarRequiredValues, ecrv: EmployeeCalendarRequiredValues, elhe: EmployeeLeaveHoursExtended[]): CalendarWarnMessages[] {
	const lcReqValsSupplied = caseRequiredValuesSupplied(lccrv, ecrv);
	const lcReqValsPrevSupplied = caseRequiredValuesPreviouslySupplied(lccrv, ecrv);
	const lcReqValsChanged = caseRequiredValuesChanged(lccrv);
	const eeReqValsSupplied = employeeRequiredValuesSupplied(ecrv);
	const eeReqValsPrevSupplied = employeeRequiredValuesPreviouslySupplied(ecrv);

	let warns: CalendarWarnMessages[] = [];

	if (lcReqValsSupplied && eeReqValsSupplied && (!eeReqValsPrevSupplied || lcReqValsChanged)) {
		warns.push(CalendarWarnMessages.ThisCaseImpacted);
	}
	
	if (!lcReqValsSupplied && (eeReqValsPrevSupplied && lcReqValsPrevSupplied)) {
		warns.push(CalendarWarnMessages.ThisCaseInaccessible);
	}

	if (elhe?.length > 0
		&& elhe.some(xhours => xhours.leaveCaseHoursID != null)
		&& (lccrv.newStartDate == null 
			|| lccrv.newEndDate == null 
			|| elhe.some(xhours => 
				xhours.leaveCaseHoursID != null
				&& (new Date(xhours.calendarDate) < new Date(lccrv.newStartDate) 
					|| new Date(xhours.calendarDate) > new Date(lccrv.newEndDate)))))
		warns.push(CalendarWarnMessages.LeavesOutsideCaseTimeframe);

	if (warns.length == 0)
		warns = null;

	return warns;
}