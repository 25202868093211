<div class="dialog--title">
    <h6 class="grey1">{{ data.sysText.employeeSettings_header }}</h6>
    <ng-container *ngIf="isHandheld">
        <span class="spacer"></span>
        <mat-icon role="button" (click)="cancel()" class="icon__button">close</mat-icon>
    </ng-container>
    <mat-divider class="section-header-underline"></mat-divider>
</div>

  
<mat-dialog-content class="mat-dialog-content">
<ng-container>
    <form [formGroup]="form">
        <div class="dialog--section">
            <mat-icon>check_circle</mat-icon><p class="grey1">{{ data.sysText.employeeSettings_allowAccess }}</p>
        </div>
        <div class="toggle--section">
            <app-slide-toggle
                [control]="allowAccess"
                [label]="data.sysText.employeeSettings_enableAccess"
                [checked]="this.data.eeRecord.allowAccess"
            ></app-slide-toggle>
        </div>
        <p class="bottom-margin"></p>
        <div class="dialog--section">
            <mat-icon>check_circle</mat-icon><p class="grey1">{{ data.sysText.employeeSettings_allowNewLeaves }}</p>
        </div>
        <div class="toggle--section">
            <app-slide-toggle
                [control]="allowNewLeaves"
                [label]="data.sysText.employeeSettings_enableLeaves"
                [checked]="this.data.eeRecord.allowNewLeaves"
            ></app-slide-toggle>
        </div>
    </form>
</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="!(isSaving$ | async); else submitting">
  <button mat-stroked-button class="button" color="accent" (click)="cancel()" [disabled]="isSaving$ | async" *ngIf="!isHandheld">
    {{ data.sysText.cancel }}
  </button>
    <button mat-stroked-button class="button" color="accent" (click)="save()">
        {{ data.sysText.save }}
    </button>
</mat-dialog-actions>
<ng-template #submitting>
    <mat-dialog-actions align="end">
        <button mat-button class="button" type="button" color="accent" disabled>
            <mat-spinner diameter="30" color="accent"></mat-spinner>
        </button>
    </mat-dialog-actions>
</ng-template>
  
